import {useEffect, useRef} from "react";

export const useIntervalTimeSelect = (callback, delay, time) => {
    const savedCallback = useRef();
    let multi = 1;


    if (time && time === 'sec') {
        multi = 1000
    } else {
        multi = 1000 * 60
    }
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay * multi);
            return () => clearInterval(id);
        }
    }, [delay, multi]);
}