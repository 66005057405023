import React, {useEffect} from 'react';
import {AppBar, Button, Dialog, DialogActions, DialogContent, makeStyles, Toolbar, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {setInvitationsDialogOpen, updateInvitations} from "../../auth/store/UserSlice";
import EnhancedTranslate from "../../common-components/EnhancedTranslate";
import {useForm} from "../../../@fuse/hooks";
import Invitations from "./Invitations";

const useStyles = makeStyles(() => ({
    dialogPaper: {
        borderRadius: "6px",
        height: "700px",
    },
}));

const defaultFormState = {
    accepted: [],
    declined: [],
};


export default function InvitationsDialog() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {invitations, invitationsDialog} = useSelector((state) => state.user)
    const {open} = invitationsDialog

    const {form, setForm, setInForm} = useForm(defaultFormState);

    useEffect(() => {
        setForm(defaultFormState)
    }, [open])

    function close() {
        dispatch(setInvitationsDialogOpen(false))
    }

    function canBeSubmitted() {
        return form.accepted.length > 0 || form.declined.length > 0
    }

    function submit() {
        dispatch(updateInvitations(form.accepted, form.declined))
    }

    return (
        <Dialog
            classes={{
                paper: classes.dialogPaper
            }}
            open={open}
            onClose={close}
            fullWidth
            maxWidth="xs"
        >
            <AppBar position="static" elevation={1}>
                <Toolbar className="flex w-full">
                    <Typography variant="subtitle1" color="inherit">
                        <EnhancedTranslate value={'Invitations'}/>
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent classes={{root: "p-24"}}>
                <Invitations
                    invitations={invitations}
                    onChangeAccepted={(newAccepted) => {
                        setInForm("accepted", newAccepted)
                    }}
                    onChangeDeclined={(newDeclined) => {
                        setInForm("declined", newDeclined)
                    }}
                />
            </DialogContent>

            <DialogActions className="justify-between pl-16">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={close}
                >
                    <EnhancedTranslate value="Close"/>
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={submit}
                    disabled={!canBeSubmitted()}
                >
                    <EnhancedTranslate value="Save"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}