import React from "react";
import EnhancedTranslate from "../app/common-components/EnhancedTranslate";

export const WEEK_DAY_NAMES = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const OBJECT_GROUP_CARREFOUR = "Carrefour"
export const OBJECT_GROUP_BAUHAUS = "Bauhaus"

export const PAGE_SIZE = 25;

export const ENDPOINT_REQUEST_DEFAULT_TIMEOUT_MILISECONDS = 10000;
export const LONG_ENDPOINT_TIMEOUT_60_SECONDS = 60000;

export const ROLE_OWNER = 'owner';
export const ROLE_ADMIN = 'admin';
export const ROLE_VIEW_ONLY = 'viewOnly';
export const ROLE_SERVICE = 'service';

export const infiniteCalendarTranslations = {
    weekdays: [
        <EnhancedTranslate key="sun" value={'Sunday short'}/>,
        <EnhancedTranslate key="mon" value={'Monday short'}/>,
        <EnhancedTranslate key="tue" value={'Tuesday short'}/>,
        <EnhancedTranslate key="wed" value={'Wednesday short'}/>,
        <EnhancedTranslate key="thu" value={'Thursday short'}/>,
        <EnhancedTranslate key="fri " value={'Friday short'}/>,
        <EnhancedTranslate key="sat" value={'Saturday short'}/>
    ],
    months: MONTH_NAMES.map(x => <EnhancedTranslate key={x} value={x}/>),
    blank: <EnhancedTranslate value={'No date selected'}/>,
    todayLabel: {
        long: <EnhancedTranslate value={'Today'}/>,
        short: <EnhancedTranslate value={'Today'}/>
    },
    headerFormat: 'MMM Do',
    weekStartsOn: 1,
}

export const inactiveModeOptions = [
    <EnhancedTranslate key={1} value={'Do nothing'}/>,
    <EnhancedTranslate key={2} value={"Notify and don't log out"}/>,
    <EnhancedTranslate key={3} value={'Notify and log out'}/>,
    <EnhancedTranslate key={4} value={'Change status to AFK'}/>
];

export const SETTING_TYPE_BOOLEAN = 'boolean';
export const SETTING_TYPE_STRING = 'string';
export const SETTING_TYPE_COMMA_SEPARATED_STRING = 'commaSeparatedString';
export const SETTING_TYPE_INT = 'int';

export const SETTING_TILT_TO_WAKE_ENABLED = 'tiltToWake';
export const SETTING_ADD_CHANNEL_FOR_JOB = 'createChannelAfterCreatingJobTitle';
export const SETTING_BREAKS_ENABLED = 'breaksEnabled';
export const SETTING_BREAKS_INFORM_ABOUT_END = 'breaksInformAboutEnd';
export const SETTING_BREAKS_INFORM_ABOUT_EXCEEDED = 'breaksInformAboutExceeded';
export const SETTING_BREAKS_COMMUNICATION = 'breaksCommunication';
export const SETTING_BREAKS_DURATION = 'breaksDurations';
export const SETTING_BREAKS_TIME_BEFORE_INFORMING_THAT_BREAK_END = 'breaksTimeBeforeInformingThatBreakEnds';
export const SETTING_TIME_TO_INACTIVE = 'timeToInactive';
export const SETTING_INACTIVE_MODE = 'areYouStillThereCheck';
export const SETTING_JOB_TITLES_IN_CONTACT_LIST = 'jobTitleInContactList';
export const SETTING_MIN_BAT_PCT_TO_START_WORK = 'minBatPctToStartWork';
export const SETTING_WIFI_ENABLED = 'wifiEnabled';
export const SETTING_WIFI_ONLY = 'wifiOnly';
export const SETTING_MIN_BAT_PCT_TO_BE_FULLY_READY = 'minBatPctToBeFullyReady';
export const SETTING_SCREEN_LIGHT_UP_ON_AC_AFTER_RECEIVING_BLE = 'screenLightUpOnAcAfterReceivingBLE';
export const SETTING_STREAMING_AUDIO = "numOfChunksNeededToStartPlaying";
export const SETTING_ALLOW_USER_BE_IN_MULTIPLE_JOB_TITLES = "allowUserBeInMultipleJobTitles";
export const SETTING_ALLOW_USER_TO_MUTE_SOUNDS = "allowUsersToMuteSounds";
export const SETTING_ALLOW_USER_TO_COMMUNICATE_WITH_EMOTICONS = 'emojiAsMessageEnabled';
export const SETTING_LANGUAGES = "languages"; // deprecated
export const SETTING_LANGUAGE = "language";
export const SETTING_AUTO_PLAY_POLICY = "autoPlayPolicy";
export const SETTING_PERSONNEL_SYNCHRONIZATION_URL = "personnelSynchronizationUrl";
export const SETTING_PERSONNEL_SYNCHRONIZATION_AUTH_USER = "personnelSynchronizationAuthUser";
export const SETTING_PERSONNEL_SYNCHRONIZATION_AUTH_PASSWORD = "personnelSynchronizationAuthPassword";
export const SETTING_ASK_FOR_PREFERRED_LANGUAGE_AT_FIRST_LOGIN = "askForPreferredLanguageAtFirstLogin";
export const SETTINGS_HIDE_EMPLOYEES_NAMES = "hideEmployeesNames";
export const SETTING_CAN_USER_REVEAL_WIFI_PASSWORD_IN_DASHBOARD = "canUserRevealWiFiPasswordInDashboard";
export const SETTING_SUGGEST_USER_TO_LOG_IN = "suggestUserToLogIn";
export const SETTING_TEMPORARY_USERS_ENABLED = "temporaryUsersEnabled";
export const SETTING_SHOW_LOGICALLY_REMOVED_USERS_IN_DASHBOARD = "showLogicallyRemovedUsersInDashboard";
export const SETTING_DAYS_TO_REMOVE_INACTIVE_STANDARD_USERS = "daysToRemoveInactiveStandardUsers";
export const SETTING_DAYS_TO_REMOVE_INACTIVE_TEMPORARY_USERS = "daysToRemoveInactiveTemporaryUsers";
export const SETTING_INACTIVE_USERS_REMOVER_ENABLED = "inactiveUsersRemoverEnabled";
export const SETTING_ALL_JOB_TITLES_CAN_BE_USED_BY_TEMPORARY_USERS = "allJobTitlesCanBeUsedByTemporaryUsers";
export const SETTING_OBJECT_NAME = "objectName";
export const SETTING_HIDE_BREAKS_IN_WORK_REGISTER_REPORTS_IN_DASHBOARD = "hideBreaksInWorkRegisterReportsInDashboard";
export const SETTING_HIDE_USERS_COMMUNICATION_REPORTS_IN_DASHBOARD = "hideUsersCommunicationReportsInDashboard";
export const SETTING_HIDE_JOB_TITLES_COMMUNICATION_REPORTS_IN_DASHBOARD = "hideJobTitlesCommunicationReportsInDashboard";
export const SETTING_PRIVACY_RESTRICTIONS = "privacyRestrictions";
export const SETTING_BUTTON_LANGUAGES = "buttonLanguages";
export const SETTING_BUTTON_AUDIO_ENABLED = "buttonAudioEnabled";
export const SETTING_AI_ASSISTANT_ENABLED = "aiAssistantEnabled";

export const INACTIVE_MODE_DO_NOTHING = 0;

export const FACILITY_TYPE_AUTOMOTIVE = 'automotive';
export const FACILITY_TYPE_RETAIL = 'retail';

export const LOCALSTORAGE_PACKAGES_LAST_USED_OBID = 'packagesLastUsedObid';

export const SIM_CARD_TYPE_1NCE = '1nce';
export const SIM_CARD_TYPE_TM = 'TM';

export const PERSONNEL_SYNCHRONIZATION_CHANGE_TYPE_NEW_JOB_TITLE = 'newJobTitle';
export const PERSONNEL_SYNCHRONIZATION_CHANGE_TYPE_NEW_USER = 'newUser';
export const PERSONNEL_EVENT_DUPLICATED_USER_ENTRY = 'duplicatedUser';
export const PERSONNEL_EVENT_USER_LANGUAGE_CHANGE = 'userLanguageChange';
export const PERSONNEL_EVENT_MULTIPLE_JOB_TITLES_NOT_ALLOWED = 'multipleJobTitlesNotAllowed';
export const PERSONNEL_EVENT_JOB_TITLE_CHANGED = 'jobTitlesChanged';
export const PERSONNEL_EVENT_INCORRECT_LANGUAGES = 'incorrectLanguage';
export const PERSONNEL_EVENT_INVALID_JOB_TITLE_NAME = 'invalidJobTitleName';
export const PERSONNEL_EVENT_INVALID_USER_NAME_SURNAME = 'invalidNameSurname';

export const CWATCH_COLOR_DEFAULT = "Black"
export const DEVICE_TYPE_CWATCH = "watch"
export const DEVICE_TYPE_BUTTON = "button"

export const STANDARIZED_DEVICE_TYPE_CWATCH = "cWATCH"
export const STANDARIZED_DEVICE_TYPE_CPANEL = "cPANEL"

export const DEVICE_TYPES_MAP = {
    [DEVICE_TYPE_CWATCH]: STANDARIZED_DEVICE_TYPE_CWATCH,
    [DEVICE_TYPE_BUTTON]: STANDARIZED_DEVICE_TYPE_CPANEL,
}

export const BUTTON_LABEL_KEY_MAIN_HEADER = "main_header"
export const BUTTON_LABEL_KEY_MAIN_CENTER = "main_center"
export const BUTTON_LABEL_KEY_MAIN_FOOTER = "main_footer"

export const BUTTON_LABEL_KEY_LOOKING_FOR_HEADER = "looking_for_header"
export const BUTTON_LABEL_KEY_LOOKING_FOR_CENTER = "looking_for_center"

export const BUTTON_LABEL_KEY_FOUND_HEADER = "found_header"
export const BUTTON_LABEL_KEY_FOUND_CENTER = "found_center"

export const BUTTON_LABEL_KEY_NO_EMPLOYEES_HEADER = "no_employees_header"
export const BUTTON_LABEL_KEY_NO_EMPLOYEES_CENTER = "no_employees_center"

export const BUTTON_LABEL_KEY_NO_CONNECTION_HEADER = "no_connection_header"
export const BUTTON_LABEL_KEY_NO_CONNECTION_CENTER = "no_connection_center"

export const COLOR_PURPLE_100 = "#5438DC"
export const COLOR_PINK_100 = "#B24C63"
export const COLOR_YELLOW_100 = "#C28100"
export const COLOR_BLACK = "#000000"

export const KEYCODE_BACKSPACE = 8
export const KEYCODE_DELETE = 46
export const KEYCODE_ARROW_LEFT = 37
export const KEYCODE_ARROW_RIGHT = 39

export const QR_CODE_SERIAL_URL = `https://a.c.watch/serial/`

export const INSTANCE_TYPE_DEV = "Dev"
export const INSTANCE_TYPE_DEV8 = "Dev8"
export const INSTANCE_TYPE_HOTELS_DEV = "HotelsDev"
export const INSTANCE_TYPE_TEST = "Test"
export const INSTANCE_TYPE_HOTELS = "Hotels"
export const INSTANCE_TYPE_ALPHA = "Alpha"
export const INSTANCE_TYPE_BETA = "Beta"
export const INSTANCE_TYPE_CLOUD = "Production"

export const DEV_INFRASTRUCTURES = [INSTANCE_TYPE_DEV, INSTANCE_TYPE_DEV8, INSTANCE_TYPE_HOTELS_DEV]
export const PROD_INFRASTRUCTURES = [INSTANCE_TYPE_TEST, INSTANCE_TYPE_HOTELS, INSTANCE_TYPE_ALPHA, INSTANCE_TYPE_BETA, INSTANCE_TYPE_CLOUD]


export const STATUS_INITIAL = "initial"
export const STATUS_LOADING = "loading"
export const STATUS_SUCCESS = "success"
export const STATUS_ERROR = "error"

export const UNRECOGNIZED_ERROR = "Unrecognized error"

export const SUPPORT_EMAIL = "support@c.watch"

export const LANDING_PAGE_URL = "https://c.watch"

export const MAX_PASSWORD_LENGTH = 63

export const CURRENT_PASSWORD_INCORRECT = "CURRENT_PASSWORD_INCORRECT"
