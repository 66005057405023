import {combineReducers} from 'redux';
import devices from './devices.reducer';
import archivedPings from './archivedPings.reducer';

const reducer = combineReducers({
    devices,
    archivedPings,
});

export default reducer;
