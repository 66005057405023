export const LANGUAGES = [
    {code: "ca-ES", country: "es", flag: "catalonia", name: "Catalan", originalName: "Català"},
    {code: "da-DK", country: "dk", flag: "dk", name: "Danish", originalName: "Dansk"},
    {code: "de-DE", country: "de", flag: "de", name: "German", originalName: "Deutsch"},
    {code: "et-EE", country: "ee", flag: "ee", name: "Estonian", originalName: "Eesti keel"},
    {code: "en-US", country: "gb", flag: "us", name: "English", originalName: "English"},
    {code: "es-ES", country: "es", flag: "es", name: "Spanish", originalName: "Español"},
    {code: "fr-FR", country: "fr", flag: "fr", name: "French", originalName: "Français"},
    {code: "gl-ES", country: "es", flag: "galicia", name: "Galician", originalName: "Galego"},
    {code: "hr-HR", country: "hr", flag: "hr", name: "Croatian", originalName: "Hrvatski"},
    {code: "ga-IE", country: "ie", flag: "ie", name: "Irish", originalName: "Gaeilge"},
    {code: "it-IT", country: "it", flag: "it", name: "Italian", originalName: "Italiano"},
    {code: "lv-LV", country: "lv", flag: "lv", name: "Latvian", originalName: "Latviski"},
    {code: "lt-LT", country: "lt", flag: "lt", name: "Lithuanian", originalName: "Lietuvių"},
    {code: "lb-LU", country: "lu", flag: "lu", name: "Luxembourgish", originalName: "Lëtzebuergesch"},
    {code: "hu-HU", country: "hu", flag: "hu", name: "Hungarian", originalName: "Magyar"},
    {code: "mt-MT", country: "mt", flag: "mt", name: "Maltese", originalName: "Malti"},
    {code: "nl-NL", country: "nl", flag: "nl", name: "Dutch", originalName: "Nederlands"},
    {code: "nb-NO", country: "no", flag: "no", name: "Norwegian", originalName: "Norsk"},
    {code: "pl-PL", country: "pl", flag: "pl", name: "Polish", originalName: "Polski"},
    {code: "pt-PT", country: "pt", flag: "pt", name: "Portuguese", originalName: "Português"},
    {code: "ro-RO", country: "ro", flag: "ro", name: "Romanian", originalName: "Română"},
    {code: "sk-SK", country: "sk", flag: "sk", name: "Slovak", originalName: "Slovenský"},
    {code: "sl-SI", country: "si", flag: "si", name: "Slovenian", originalName: "Slovenščina"},
    {code: "fi-FI", country: "fi", flag: "fi", name: "Finnish", originalName: "Suomalainen"},
    {code: "sv-SE", country: "se", flag: "se", name: "Swedish", originalName: "Svenska"},
    {code: "tr-TR", country: "tr", flag: "tr", name: "Turkish", originalName: "Türkçe"},
    {code: "cs-CZ", country: "cz", flag: "cz", name: "Czech", originalName: "Čeština"},
    {code: "el-GR", country: "gr", flag: "gr", name: "Greek", originalName: "Ελληνικά"},
    {code: "ru-RU", country: "ru", flag: "ru", name: "Russian", originalName: "Русский"},
    {code: "uk-UA", country: "ua", flag: "ua", name: "Ukrainian", originalName: "Українська"},
    {code: "bg-BG", country: "be", flag: "bg", name: "Bulgarian", originalName: "български"},
];

export const DEFAULT_LANG = LANGUAGES.find(x => x.code === "en-US")

export function getLang(l) {
    for (const language of LANGUAGES) {
        if (typeof l === 'object')
            return l
        else if (language.code === l)
            return language;
    }
    return DEFAULT_LANG;
}

export function getLangName(l) {
    return getLang(l).name
}

export function getFlag(l) {
    return getLang(l).flag
}

export function getCountry(l) {
    return getLang(l).country
}

export function getCurrentWebsiteLanguage() {
    const languageLocalStorage = localStorage.getItem('language');

    if (languageLocalStorage && languageLocalStorage !== 'undefined')
        return getLang(languageLocalStorage);
    else
        return DEFAULT_LANG;
}

export const mapSimpleLanguageToStandarizedFormat = {
    en: 'en-US',
    pl: 'pl-PL',
    fr: 'fr-FR',
    nl: 'nl-NL',
    sk: 'sk-SK',
    it: 'it-IT',
    de: 'de-DE',
    sl: 'sl-SI',
    ru: 'ru-RU',
    ee: 'et-EE',
    cs: 'cs-CZ',
    uk: 'uk-UA',
    es: 'es-ES',
    bg: 'bg-BG',
    da: 'da-Dk',
    el: 'el-GR',
    fi: 'fi-FI',
    hr: 'hr-HR',
    hu: 'hu-HU',
    lt: 'lt-LT',
    lv: 'lv-LV',
    no: 'nb-NO',
    pt: 'pt-PT',
    ro: 'ro-RO',
    sv: 'sv-SE',
    ca: 'ca-ES',
    tr: 'tr-TR',
    mt: 'mt-MT',
    lb: 'lb-LU',
    gl: 'gl-ES',
    ga: 'ga-IE',
}

export const mapDashboardLanguageToGoogleApiLanguage = {
    'en-US': 'en',
    'pl-PL': 'pl',
    'fr-FR': 'fr',
    'nl-NL': 'nl',
    'sk-SK': 'sk',
    'it-IT': 'it',
    'de-DE': 'de',
    'sl-SI': 'sl',
    'et-EE': 'et',
    'ru-RU': 'ru',
    'cs-CZ': 'cs',
    'uk-UA': 'uk',
    'es-ES': 'es',
    'bg-BG': 'bg',
    'da-Dk': 'da',
    'el-GR': 'el',
    'fi-FI': 'fi',
    'hr-HR': 'hr',
    'hu-HU': 'hu',
    'lt-LT': 'lt',
    'lv-LV': 'lv',
    'nb-NO': 'no',
    'pt-PT': 'pt',
    'ro-RO': 'ro',
    'sv-SE': 'sv',
    'ca-ES': 'ca',
    'tr-TR': 'tr',
    'gl-ES': 'gl',
    // these ones don't have support in google API, so it stays english
    'mt-MT': 'en',
    'lb-LU': 'en',
    'ga-IE': 'en',
}



