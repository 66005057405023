import React, {useEffect, useState} from 'react';
import {AppBar, CircularProgress, Dialog, DialogContent, makeStyles, Tab, Tabs, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import * as Actions from "app/main/apps/devices/store/actions";
import {setButtonSettings} from "app/main/apps/devices/store/actions";
import * as ForSelectActions from "../../../../../store/actions";
import EnhancedTranslate from "../../../../../common-components/EnhancedTranslate";
import {TransitionComponent} from "../../../../../../lib/commonFunctions";
import {Edit, Group, RadioButtonChecked, Settings} from "@material-ui/icons";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import {useSetting} from "../../../../../custom-hooks/useSetting";
import {SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING} from "../../../../../../lib/constants";
import {fetchDefaultButtonTranslations, setButtonSettingsForm, setDefaultButtonSettingsForm, setDefaultButtonTranslations} from "../../store/DevicesSlice";
import ButtonGeneralSettingsForm from "../ButtonGeneralSettingsForm";
import ButtonAssignmentsSettingsForm from "../ButtonAssignmentsSettingsForm";
import ButtonLabelsSettingsForm from "../ButtonLabelsSettingsForm";
import ButtonSettingsDialogActions from "../../ButtonSettingsDialogActions";

const useStyles = makeStyles(() => ({
    dialogPaper: {minWidth: 600, minHeight: 700, overflow: "hidden"},
    tab: {
        fontWeight: 500,
        color: "#3C4252",
        letterSpacing: 0.4,
        fontSize: 14,
        lineHeight: 1.3,
        "@media (max-width:600px)": {
            width: "33%"
        }
    }
}));

export default function ButtonSettingsDialog() {
    const TAB_GENERAL = 0
    const TAB_ASSIGNMENTS = 1
    const TAB_LABELS = 2

    const dispatch = useDispatch();
    const classes = useStyles();

    const buttonSettingsDialogOpen = useSelector(({devices}) => devices.devices.buttonSettingsDialogOpen);
    const buttonSettingsSerial = useSelector(({devices}) => devices.devices.buttonSettingsDialogSerial);
    const buttonSettingsLoading = useSelector(({devices}) => devices.devices.buttonSettingsLoading);
    const button = useSelector(({devices}) => devices.devices.buttonSettings);
    const {buttonSettingsForm, defaultButtonTranslationsLoading} = useSelector((state) => state.devicesSlice)

    const [currentlySelectedTab, setCurrentlySelectedTab] = useState(TAB_GENERAL);

    const [buttonLanguages] = useSetting(SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING, []);

    function closeComposeDialog() {
        dispatch(Actions.closeButtonSettingsDialog());
        dispatch(setDefaultButtonTranslations(null));
        dispatch(setDefaultButtonSettingsForm())
    }

    useEffect(() => {
        dispatch(ForSelectActions.getJobTitlesWithUsers())
    }, [dispatch]);

    useEffect(() => {
        if (buttonSettingsDialogOpen) {
            dispatch(Actions.getButtonSettings(buttonSettingsSerial));
            dispatch(fetchDefaultButtonTranslations());
        }
    }, [buttonSettingsDialogOpen, dispatch, buttonSettingsSerial]);

    useEffect(() => {
        if (buttonSettingsDialogOpen) {
            initForm()
            setCurrentlySelectedTab(TAB_GENERAL)
        }
    }, [buttonSettingsDialogOpen, button, buttonLanguages])

    function initForm() {
        if (button)
            dispatch(setButtonSettingsForm({
                ...buttonSettingsForm,
                ...button,
                jobTitleToInformAboutUnhandledTasks: button.jobTitleToInformAboutUnhandledTasks || -1,
                selectedLanguage: buttonLanguages[0]
            }))
        else {
            dispatch(setButtonSettingsForm({
                ...buttonSettingsForm,
                selectedLanguage: buttonLanguages[0]
            }))
        }
    }

    function ButtonSettingLabel({label, icon}) {
        return <div>
            {icon}
            <Box display={{xs: 'none', sm: 'inline'}} className={"ml-8"}>
                <EnhancedTranslate value={label}/>
            </Box>
        </div>
    }

    function submit() {
        let requestPayload = {
            name: buttonSettingsForm.name.trim(),
            jobTitleToInformAboutUnhandledTasks: buttonSettingsForm.jobTitleToInformAboutUnhandledTasks,
            primaryJobTitles: buttonSettingsForm.primaryJobTitles,
            secondaryJobTitles: buttonSettingsForm.secondaryJobTitles,
            primaryUids: buttonSettingsForm.primaryUids,
            secondaryUids: buttonSettingsForm.secondaryUids,
            informSecondaryJobTitlesImmediatelyWhen1PersonOnPrimaryJobTitle: buttonSettingsForm.informSecondaryJobTitlesImmediatelyWhen1PersonOnPrimaryJobTitle,
        }

        if (Object.keys(buttonSettingsForm.modifiedTranslationsByLanguage).length > 0) {
            requestPayload["translationsByLanguage"] = buttonSettingsForm.modifiedTranslationsByLanguage
        }

        dispatch(setDefaultButtonTranslations(null));
        dispatch(setDefaultButtonSettingsForm())
        dispatch(setButtonSettings(buttonSettingsSerial, requestPayload))
    }

    function canBeSubmitted() {
        return buttonSettingsForm.name?.length > 0
    }

    function isLoading() {
        return buttonSettingsLoading || defaultButtonTranslationsLoading
    }


    if (!buttonSettingsDialogOpen)
        return null

    return (
        <Dialog
            open={buttonSettingsDialogOpen}
            TransitionComponent={TransitionComponent}
            onClose={closeComposeDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={{paper: classes.dialogPaper}}
        >
            <AppBar position="static" elevation={1}>
                <div className="p-24">
                    <div className="flex">
                        <div className={"self-center"}>
                            <RadioButtonChecked/>
                        </div>
                        <Typography color="inherit" className="ml-10 typography-h6">
                            <EnhancedTranslate value={'button settings'}/>
                        </Typography>
                    </div>
                    <div className="flex mt-16">
                        <Typography className="typography-subtitle2">
                            <EnhancedTranslate value={'Location'}/>:
                        </Typography>
                        <Typography className="typography-subtitle2 ml-8">
                            {button &&
                                <>
                                    {button.name === "" ? <EnhancedTranslate value={"None"}/> : button.name}
                                </>
                            }
                        </Typography>
                        <Typography className={"typography-subtitle2"} style={{marginLeft: 32}}>
                            S-N:
                        </Typography>
                        <Typography className="typography-subtitle2 ml-8">
                            {buttonSettingsSerial}
                        </Typography>
                    </div>
                </div>
            </AppBar>

            <Tabs
                textColor="secondary"
                value={currentlySelectedTab}
                onChange={(e, tabValue) => {
                    setCurrentlySelectedTab(tabValue)
                }}
                classes={{root: "pl-24"}}
            >
                <Tab className={clsx(classes.tab)}
                     label={<ButtonSettingLabel label={"General"} icon={<Settings style={{fontSize: 24}}/>}/>}
                />
                <Tab disabled={buttonSettingsForm.name === "" || isLoading()}
                     className={clsx(classes.tab)}
                     label={<ButtonSettingLabel label={"Assignments"} icon={<Group style={{fontSize: 24}}/>}/>}
                />
                <Tab disabled={buttonSettingsForm.name === "" || isLoading()}
                     className={clsx(classes.tab)}
                     label={<ButtonSettingLabel label={"Customize"} icon={<Edit style={{fontSize: 24}}/>}/>}
                />
            </Tabs>
            <DialogContent style={{padding: "0 24px 24px 24px"}}>
                {isLoading() ?
                    <div className={"text-center"}>
                        <CircularProgress color={"secondary"}/>
                    </div>
                    :
                    <>
                        {currentlySelectedTab === TAB_GENERAL && <ButtonGeneralSettingsForm/>}
                        {currentlySelectedTab === TAB_ASSIGNMENTS && <ButtonAssignmentsSettingsForm/>}
                        {currentlySelectedTab === TAB_LABELS && <ButtonLabelsSettingsForm/>}
                        <ButtonSettingsDialogActions onSubmit={submit} canBeSubmitted={canBeSubmitted()} closeDialog={closeComposeDialog}/>
                    </>
                }
            </DialogContent>
        </Dialog>
    );
}
