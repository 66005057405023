import * as Actions from 'app/store/actions/forSelect'
import {RESET_STORE} from "../../actions/reduxActions";

const initialState = {
    users: [],
    jobTitles: [],
    jobTitlesLoading: true,
    jobTitlesWithUsers: [],
    groups: [],
    mobileNetworkOperators: [],
    managers: [],
    devices: [],
};

const dialog = function (state = initialState, action) {
    switch (action.type) {
        case Actions.USERS: {
            return {
                ...state,
                users: action.payload
            };
        }
        case Actions.JOB_TITLES: {
            return {
                ...state,
                jobTitles: action.payload
            };
        }
        case Actions.SET_JOB_TITLES_LOADING: {
            return {
                ...state,
                jobTitlesLoading: action.payload
            };
        }
        case Actions.MOBILE_NETWORK_OPERATORS: {
            return {
                ...state,
                mobileNetworkOperators: action.payload
            };
        }
        case Actions.JOB_TITLES_WITH_USERS: {
            return {
                ...state,
                jobTitlesWithUsers: action.payload
            };
        }
        case Actions.GROUPS: {
            return {
                ...state,
                groups: action.payload
            };
        }
        case Actions.MANAGERS: {
            return {
                ...state,
                managers: action.payload
            };
        }
        case Actions.DEVICES: {
            return {
                ...state,
                devices: action.payload
            };
        }
        case RESET_STORE:
            return initialState
        default:
            return state;
    }
};

export default dialog;
