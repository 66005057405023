import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {Checkbox, Collapse, ListItem} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import {ExpandLess, ExpandMore} from "@material-ui/icons";


export default function JobTitlesNestedMenuList({selectedJobTitles, selectedUsers, handleJobTitleCheck, handleUserCheck}) {
    const [currentlyOpenedJobTitle, setCurrentlyOpenedJobTitle] = useState(null)

    const jobTitlesWithUsers = useSelector(({forSelect}) => forSelect.forSelect.jobTitlesWithUsers);

    function openUsersListForJobTitle(jobTitleId) {
        if (currentlyOpenedJobTitle === jobTitleId)
            setCurrentlyOpenedJobTitle(null)
        else
            setCurrentlyOpenedJobTitle(jobTitleId)
    }

    function isJobTitleChecked(jobTitleId) {
        return selectedJobTitles.includes(jobTitleId)
    }

    function isUserChecked(userId, jobTitleId) {
        return selectedUsers.includes(userId) || isJobTitleChecked(jobTitleId)
    }

    function isIndeterminate(jobTitleId) {
        if (isJobTitleChecked(jobTitleId))
            return false
        const userIdsForThisJobTitle = jobTitlesWithUsers.find(x => x.id === jobTitleId).users.map(x => x.id)

        for (let selectedUser of selectedUsers) {
            if (userIdsForThisJobTitle.includes(selectedUser))
                return true
        }
        return false
    }

    return (
        <List>
            {jobTitlesWithUsers.map((jobTitle) =>
                <Fragment key={jobTitle.id}>
                    <ListItem button style={{paddingTop: 0, paddingBottom: 0, margin: 0}}>
                        <Checkbox
                            onClick={(e) => {
                                handleJobTitleCheck(jobTitle.id, e.target.checked)
                            }}
                            indeterminate={isIndeterminate(jobTitle.id)}
                            checked={isJobTitleChecked(jobTitle.id)}
                        />
                        <ListItemText primary={jobTitle.name} onClick={() => openUsersListForJobTitle(jobTitle.id)} style={{lineHeight: 1, margin: 0, wordBreak: "break-all"}}/>
                        {jobTitle.users.length > 0 &&
                            <Fragment>
                                {currentlyOpenedJobTitle === jobTitle.id ? <ExpandLess onClick={() => openUsersListForJobTitle(jobTitle.id)}/> :
                                    <ExpandMore onClick={() => openUsersListForJobTitle(jobTitle.id)}/>}
                            </Fragment>
                        }
                    </ListItem>
                    <Collapse
                        in={currentlyOpenedJobTitle === jobTitle.id}
                        timeout="auto" unmountOnExit>
                        <List component="div" style={{marginLeft: 16}}>
                            {jobTitle.users.map((user) =>
                                <ListItem button key={user.id} style={{paddingTop: 0, paddingBottom: 0, margin: 0}} onClick={(e) => {
                                    handleUserCheck(user.id, !isUserChecked(user.id, jobTitle.id), jobTitle.id)
                                }}>
                                    <Checkbox
                                        checked={isUserChecked(user.id, jobTitle.id)}/>
                                    <ListItemText style={{wordBreak: "break-all"}} primary={user.name + " " + user.surname}/>
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                </Fragment>
            )}
        </List>
    );
}
