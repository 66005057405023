import React, {Fragment} from 'react';
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/styles";
import {IconButton, Typography} from "@material-ui/core";
import {Cancel} from "@material-ui/icons";
import {COLOR_PINK_100, COLOR_PURPLE_100, COLOR_YELLOW_100, SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING} from "../../../../lib/constants";
import {I18n} from "react-redux-i18n";
import InlineInput from "./InlineInput";
import EnhancedTranslate from "../../../common-components/EnhancedTranslate";
import {useSelector} from "react-redux";
import useButtonText from "./hooks/useButtonText";
import clsx from "clsx";
import {useSetting} from "../../../custom-hooks/useSetting";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

const useStyles = makeStyles(() => ({
    DivAsMuiTextField: {
        color: "rgba(0, 0, 0, 0.87)",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        '&:hover': {
            borderColor: "#3C4252",
        },
        fontSize: "14px",
        paddingBottom: 18.5,
        paddingTop: 18.5,
        paddingLeft: 14.5,
        borderRadius: "4px 4px 4px 4px",
        height: "53.65px"
    },
    focusedTextField: {
        border: "2px solid #069BE5",
        '&:hover': {
            borderColor: "#069BE5",
        },
    },
    deleteIcon: {
        "&.MuiChip-deleteIcon": {
            color: "white"
        }
    },
    label: {
        position: "relative",
        top: -265,
        width: "fit-content",
        left: -4,
        paddingLeft: 5,
        paddingRight: 5,
        background: "white",
        color: "rgba(0, 0, 0, 0.44)",
        fontSize: "1.1rem",
        fontWeight: 400
    },
    focusedLabel: {
        color: "#039be5"
    },
    resetIcon: {
        position: "relative",
        top: -273,
        left: 457,
    },
    helperText: {
        margin: 0,
        fontSize: "1.2rem",
        marginTop: "3px",
        textAlign: "left",
        fontWeight: 400,
        lineHeight: 1.66,
        marginLeft: 14,
        marginRight: 14,
        color: "rgb(3, 155, 229)",
    }
}))

export const CHIP_TYPE_NAMES = "%names%"
export const CHIP_TYPE_NUMBER = "%number%"
export const CHIP_TYPE_NAME = "%name%"

export default function ChipInput({textId, name, chips}) {
    const classes = useStyles();

    const {buttonSettingsForm, defaultButtonTranslations} = useSelector((state) => state.devicesSlice)

    const {isCustom, getValue, setValue, isInformationAboutAutoTranslationVisible} = useButtonText(textId)

    const [buttonLanguages] = useSetting(SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING, []);

    function getChunks() {
        const value = getValue()

        if (value === "")
            return stringToChunks(defaultButtonTranslations[buttonSettingsForm.selectedLanguage][textId])
        return stringToChunks(value)
    }

    function handleChipDelete(chip) {
        handleChange(chunks.filter(x => x !== chip))
    }

    function addChipToInput(chip) {
        if (!chunks.includes(chip)) {
            handleChange([...chunks, chip])
        }
    }

    function stringToChunks(str) {
        return str.splitAndKeep(chips)
    }

    function chunksToString(chunks) {
        return chunks.join("")
    }

    function isElementChip(element) {
        return element === CHIP_TYPE_NAMES || element === CHIP_TYPE_NUMBER || element === CHIP_TYPE_NAME
    }

    function isElementInput(element) {
        return !isElementChip(element)
    }

    function isNextElementChip(idx) {
        if (idx < chunks.length) {
            return isElementChip(chunks[idx + 1])
        }
        return false
    }

    function isPreviousElementChip(idx) {
        if (idx > 0) {
            return isElementChip(chunks[idx - 1])
        }
        return false
    }

    function handleInputChange(idx, val) {
        const newChunks = chunks.map((oldVal, i) => {
            if (i === idx)
                return val;
            else
                return oldVal;
        })
        handleChange(newChunks)
    }

    function handleChange(newChunks) {
        setValue(chunksToString(newChunks))
    }

    function handleChangeDefault() {
        setValue(null)
    }

    function getLabelByChip(chip) {
        switch (chip) {
            case CHIP_TYPE_NAME:
                return I18n.t("name")
            case CHIP_TYPE_NAMES:
                return I18n.t("names")
            case CHIP_TYPE_NUMBER:
                return I18n.t("number")
        }
    }

    function getInfoTextForChip(chip) {
        switch (chip) {
            case CHIP_TYPE_NAME:
                return "chip-name-info"
            case CHIP_TYPE_NAMES:
                return "chip-names-info"
            case CHIP_TYPE_NUMBER:
                return "chip-number-info"
        }
    }

    function getColorForChip(chip) {
        switch (chip) {
            case CHIP_TYPE_NAME:
                return COLOR_YELLOW_100
            case CHIP_TYPE_NAMES:
                return COLOR_PURPLE_100
            case CHIP_TYPE_NUMBER:
                return COLOR_PINK_100
        }
    }

    function PlaceholderChip({element}) {
        return (
            <div>
                <Chip size={"small"}
                      style={{backgroundColor: isCustom() ? getColorForChip(element) : "rgba(0,0,0,0.37)", marginLeft: 4, marginRight: 4, color: "white"}}
                      label={I18n.t(element.replaceAll("%", ""))}
                      deleteIcon={<Cancel className={classes.deleteIcon}/>}
                      onDelete={() => {
                          handleChipDelete(element)
                      }}/>
            </div>
        )
    }

    function isLastInput(idx) {
        if (idx === chunks.length - 1)
            return true
        for (let i = idx + 1; i < chunks.length; i++) {
            if (isElementInput(chunks[i])) {
                return false
            }
        }
        return true
    }

    const chunks = getChunks()

    return (
        <Fragment>
            <div style={{marginTop: 32}}>
                <div
                    className={clsx(
                        classes.DivAsMuiTextField,
                        {
                            [classes.focusedTextField]: isInformationAboutAutoTranslationVisible()
                        }
                    )}
                >
                    <div style={{
                        height: "250px", display: "flex", width: "90%",
                        overflow: "hidden",
                        wordBreak: "keep-all", whiteSpace: "nowrap",
                        marginTop: -10, paddingTop: 5
                    }}
                    >
                        {chunks.map((element, idx) => {
                                return <div style={{display: "inline", width: idx === chunks.length - 1 ? "100%" : "min-content"}}
                                            key={idx}
                                >
                                    {isElementChip(element) && <PlaceholderChip element={element}/>}
                                    {isElementInput(element) && <InlineInput
                                        isCustom={isCustom()}
                                        id={textId}
                                        value={element}
                                        idx={idx}
                                        isLastInput={isLastInput(idx)}
                                        handleChange={handleInputChange}
                                        isPreviousElementChip={isPreviousElementChip}
                                        isNextElementChip={isNextElementChip}
                                    />}
                                    &nbsp;
                                </div>
                            }
                        )}
                    </div>
                    <div
                        className={clsx(
                            classes.label,
                            {
                                [classes.focusedLabel]: isInformationAboutAutoTranslationVisible()
                            }
                        )}
                    >
                        <EnhancedTranslate value={name}/>
                    </div>

                    <div>
                        <IconButton
                            className={classes.resetIcon}
                            onClick={handleChangeDefault}
                            disabled={!isCustom()}
                        >
                            <SettingsBackupRestoreIcon/>
                        </IconButton>
                    </div>
                </div>

                {isInformationAboutAutoTranslationVisible() &&
                    <div className={classes.helperText}>
                        {buttonLanguages[0] === buttonSettingsForm.selectedLanguage && <EnhancedTranslate value={"main_lang_button_text_modified_helper_text"}/>}
                        {buttonLanguages[0] !== buttonSettingsForm.selectedLanguage && <EnhancedTranslate value={"other_lang_button_text_modified_helper_text"}/>}
                    </div>
                }

            </div>

            {chips.map(chip =>
                <div key={chip} className={"flex"}>
                    <Chip size={"small"}
                          label={getLabelByChip(chip)}
                          style={{backgroundColor: getColorForChip(chip), color: "white", alignSelf: "center"}}
                          className={"mt-16"}
                          onClick={() => {
                              addChipToInput(chip)
                          }}
                    />
                    <Typography className={"ml-8 font-300"} style={{fontSize: 12, marginTop: 20}}>
                        <EnhancedTranslate value={getInfoTextForChip(chip)}/>
                    </Typography>
                </div>
            )}
        </Fragment>
    )
}
