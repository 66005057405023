import React from 'react';

function RightSideLayout1() {

    return (
        <React.Fragment>
            {/*{user.data.id !== -1 && <ChatPanel/>}*/}
        </React.Fragment>
    );
}

export default RightSideLayout1;
