import React from 'react';
import {Button, CardContent, InputAdornment, Typography} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import EnhancedTextField from "../../common-components/EnhancedTextField";
import {useDispatch, useSelector} from "react-redux";
import {login, setInLoginForm, setInNewPasswordRequestForm, setSelectedView} from "../../auth/store/LoginSlice";
import EnhancedTranslate from "../../common-components/EnhancedTranslate";
import fullCwatchLogo from "../../../assets/fullCwatchLogo.jpg";
import LinkText from "../../common-components/LinkText";
import {VIEW_NEW_PASSWORD_REQUEST} from "../constants";
import LoginFooter from "../LoginFooter";
import {LANDING_PAGE_URL} from "../../../lib/constants";


export default function LoginForm() {
    const dispatch = useDispatch()
    const {loginForm, loginError} = useSelector((state) => state.login)

    const {email, password} = loginForm

    function onSubmit(event) {
        event.preventDefault()
        dispatch(login(email, password));
    }

    return (
        <CardContent className="flex flex-col items-center justify-center p-32 h-full">
            <img className="w-256 m-32" src={fullCwatchLogo} alt="logo"/>

            <div className="flex flex-col justify-center w-full flex-grow">
                <div className={"flex justify-center"}>
                    <Typography variant="h6" className="mt-16 mb-32">
                        <EnhancedTranslate value={'Login header'}/>
                    </Typography>
                </div>
                <form onSubmit={onSubmit}>
                    <EnhancedTextField
                        name={"email"}
                        value={email}
                        fullWidth
                        error={loginError !== null}
                        label={"Email"}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <EmailIcon className="text-20" color="action"/>
                            </InputAdornment>
                        }}
                        onChange={(event) => {
                            dispatch(setInLoginForm({
                                key: "email",
                                value: event.target.value
                            }))
                        }}
                    />

                    <EnhancedTextField
                        className="mt-16"
                        name={"password"}
                        fullWidth
                        type={"password"}
                        error={loginError !== null}
                        helperText={loginError === null ? "" : <EnhancedTranslate value={loginError}/>}
                        value={password}
                        label={"Password"}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <VpnKeyIcon className="text-20" color="action"/>
                            </InputAdornment>
                        }}
                        onChange={(event) => {
                            dispatch(setInLoginForm({
                                key: "password",
                                value: event.target.value
                            }))
                        }}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className="w-full mx-auto mt-16 mb-24"
                        onClick={onSubmit}
                    >
                        <EnhancedTranslate value={'Login'}/>
                    </Button>
                </form>

                <div className="flex flex-col items-center justify-center pt-24 pb-8">
                    <LinkText
                        onClick={() => {
                            dispatch(setInNewPasswordRequestForm({
                                key: "email",
                                value: email ?? ""
                            }))
                            dispatch(setSelectedView(VIEW_NEW_PASSWORD_REQUEST))
                        }}
                        className="btn btn-primary"
                    >
                        <EnhancedTranslate value={'Forgot password?'}/>
                    </LinkText>
                </div>

                <div className="flex flex-col items-center justify-center pt-4 pb-24">
                        <span className="font-medium">
                            <EnhancedTranslate value={'Don\'t have an account?'}/>
                        </span>
                    <a href={LANDING_PAGE_URL} target={'_blank'} rel="noopener noreferrer">
                        <EnhancedTranslate value={'Contact us'}/>
                    </a>
                </div>
            </div>
            <LoginFooter/>
        </CardContent>
    );
}
