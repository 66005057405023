import axios from 'axios';
import {getJobTitlesData} from "../../../jobTitles/store/actions";
import {getCurrentSelectedObid} from "../../../../../../lib/getObid";

export const GET_ENTITIES = '[PERSONNEL] GET ENTITIES';
export const SET_SEARCH_TEXT = '[PERSONNEL] SET SEARCH TEXT';
export const OPEN_EDIT_ENTITY_DIALOG = '[PERSONNEL] OPEN EDIT ENTITY DIALOG';
export const CLOSE_EDIT_ENTITY_DIALOG = '[PERSONNEL] CLOSE EDIT ENTITY DIALOG';
export const ADD_ENTITY = '[PERSONNEL] ADD ENTITY';
export const UPDATE_ENTITY = '[PERSONNEL] UPDATE ENTITY';
export const CLOSE_USER_ALREADY_EXIST_DIALOG = '[PERSONNEL] CLOSE USER ALREADY EXIST DIALOG';
export const SET_LOADING_TRUE = '[PERSONNEL] SET LOADING TRUE';
export const MOVE_TO_ANOTHER_CHANGE_LIST = '[PERSONNEL] MOVE TO ANOTHER CHANGE LIST';
export const SET_LOADING_FALSE = '[PERSONNEL] SET LOADING FALSE';

export function getEntities(loading = false,) {
    const request = axios.get('/api/users/list', {
        params: {
            selectedObid: getCurrentSelectedObid()
        }
    });

    return (dispatch) => {
        if (loading)
            dispatch(setLoadingTrue());
        request.then((response) =>
            dispatch({
                type: GET_ENTITIES,
                payload: response.data
            })
        ).finally(() => {
            dispatch(setLoadingFalse())
        })
    }
}

export function addEntity(newEntity) {
    return (dispatch) => {
        const request = axios.post('/api/users/add', {
            ...newEntity,
            selectedObid: getCurrentSelectedObid()
        });

        return request.then((response) => {
            dispatch({
                type: ADD_ENTITY,
                newEntity: newEntity,
                id: parseInt(response.data),
            });
        })
    };
}

export function updateEntity(entity) {
    axios.post('/api/users/edit', {
        ...entity,
        selectedObid: getCurrentSelectedObid()
    });

    return {
        type: UPDATE_ENTITY,
        entity
    }
}

export function moveToAnotherChangeList(entity) {
    axios.post('/api/users/edit', {
        ...entity,
        selectedObid: getCurrentSelectedObid()
    });

    return {
        type: MOVE_TO_ANOTHER_CHANGE_LIST,
        entity
    }
}

export function removeEntity(userId) {
    return (dispatch) => {
        const request = axios.post('/api/users/delete', {
            selectedObid: getCurrentSelectedObid(),
            uids: [userId]
        });
        request.then(() => {
            dispatch(getEntities());
        })
    };
}

export function setLoadingTrue() {
    return {
        type: SET_LOADING_TRUE,
    }
}

export function setLoadingFalse() {
    return {
        type: SET_LOADING_FALSE,
    }
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function openEditEntityDialog(data) {
    return {
        type: OPEN_EDIT_ENTITY_DIALOG,
        data
    }
}

export function closeEditEntityDialog() {
    return {
        type: CLOSE_EDIT_ENTITY_DIALOG
    }
}

export function closeUserAlreadyExistDialog() {
    return {
        type: CLOSE_USER_ALREADY_EXIST_DIALOG
    }
}

export function addCSVData(data) {
    const request = axios.post('/api/users/addUsersWithJobTitlesFromCSV', {
        contents: data,
        selectedObid: getCurrentSelectedObid()
    });
    return (dispatch) => {
        request.then(() => {
            dispatch(getJobTitlesData());
            dispatch(getEntities());
        })
    }
}

export const resetPassword = (payload) => {
    return (dispatch) => {
        const request = axios.post('/api/users/resetPassword', {
            selectedObid: getCurrentSelectedObid(),
            uids: [payload.userId]
        });

        return request.then(() => {
            dispatch(getEntities())
            }
        )
    };
}

export const setPassword = payload => {
    return (dispatch) => {
        const request = axios.post('/api/users/setPassword', {
            selectedObid: getCurrentSelectedObid(),
            uid: payload.userId,
            password: payload.password
        });

        return request.then(() => {
            dispatch(getEntities())
            }
        )
    };
}
