import React, {Fragment} from "react";
import {useSelector} from "react-redux";

export function useTranslatorMode() {
    const translations = useSelector(({common}) => common.common.simpleLocalizeTranslations);
    const selectedLanguagesToDisplayInTranslatorMode = useSelector(({common}) => common.common.selectedLanguagesToDisplayInTranslatorMode);

    function getTooltipContent(value) {
        if (value === null || value === "")
            return ""

        if (!Array.isArray(value))
            value = [value]

        return (
            <Fragment>
                {value.map(key =>
                    <Fragment key={key}>
                        <p className="flex justify-between" key="key">
                            <span>key:</span>
                            <span className="ml-8">[{key}]</span>
                        </p>
                        <br/>
                        {Object.entries(translations).filter(x => selectedLanguagesToDisplayInTranslatorMode.includes(x[0])).map(lang =>
                            <p className="flex justify-between" key={lang[0]}>
                                <span>{lang[0]}:</span>
                                <span className="ml-8">{lang[1][key]}</span>
                            </p>
                        )}
                        <br/>
                    </Fragment>
                )}
            </Fragment>
        )
    }

    return [getTooltipContent];
}