import * as Actions from 'app/store/actions/shadowService';
import {RESET_STORE} from "../../actions/reduxActions";

const initialState = {
    userShadow: {},
    watchShadow: {}
};

const shadowReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_USER_SHADOW: {
            return {
                ...state,
                userShadow: {...action.payload},
            };
        }
        case Actions.SET_WATCH_SHADOW: {
            return {
                ...state,
                watchShadow: {...action.payload},
            };
        }
        case RESET_STORE:
            return initialState
        default:
            return state;
    }
};

export default shadowReducer;
