import React from 'react';

export const CwatchReportsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/reports/cwatches',
            component: React.lazy(() => import('./CwatchReports'))
        }
    ]
};
