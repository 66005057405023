import {useEffect} from "react";
import detectBrowserLanguage from "detect-browser-language";
import {DEFAULT_LANG, LANGUAGES, mapSimpleLanguageToStandarizedFormat} from "../../lib/languages";
import store from "../store";
import {setTranslationsForLanguage} from "../store/actions";
import {useSelector} from "react-redux";

export function useTranslations() {
    const userLanguage = useSelector(({auth}) => auth.user.language);

    useEffect(() => {
        if (userLanguage)
            localStorage.setItem("language", userLanguage);

        let lang = localStorage.getItem('language');
        if (lang) {
            store.dispatch(setTranslationsForLanguage(lang));
        } else {
            const detectedBrowserLang = detectBrowserLanguage();
            let langToSet

            if (LANGUAGES.map(x => x.code).includes(detectedBrowserLang))
                langToSet = detectedBrowserLang
            else if (mapSimpleLanguageToStandarizedFormat[detectedBrowserLang])
                langToSet = mapSimpleLanguageToStandarizedFormat[detectedBrowserLang]
            else
                langToSet = DEFAULT_LANG.code

            store.dispatch(setTranslationsForLanguage(langToSet));
            localStorage.setItem("language", langToSet);
        }
    }, [userLanguage]);

    return null;
}