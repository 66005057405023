import * as Actions from '../../actions/fuse/index';
import navigationConfig from 'app/fuse-configs/navigationConfig';
import {RESET_STORE} from "../../actions/reduxActions";
import _ from "lodash";

const initialState = navigationConfig;

const navigation = function (state = [], action) {
    switch (action.type) {
        case Actions.SET_NAVIGATION: {
            return [
                ...action.navigation
            ];
        }
        case Actions.RESET_NAVIGATION: {
            return [
                ...initialState
            ];
        }
        case Actions.APPLY_PERMISSIONS_TO_NAVIGATION: {
            const {role, obid, settings} = action
            let currentRole = null;

            if (Array.isArray(role)) {
                let currentObjectRole = role.find(x => String(x.obid) === String(obid));
                currentRole = currentObjectRole.role;
            } else {
                currentRole = role
            }
            let navigation = _.cloneDeep(initialState)
            navigation = navigation.filter(navigation => roleHasAccessToAuthNavigation(navigation, currentRole));

            if (settings && Object.keys(settings).length > 0) {
                navigation = navigation.filter(navigation => facilitySettingHasAccessToAuthNavigation(navigation, settings));

                for (let navigationKey in navigation) {
                    if (navigation[navigationKey].children) {
                        navigation[navigationKey].children = navigation[navigationKey].children.filter(navigationChild => facilitySettingHasAccessToAuthNavigation(navigationChild, settings));
                    }
                }
            }

            return [
                ...navigation
            ];
        }
        case RESET_STORE:
            return initialState
        default:
            return state;
    }
};

function roleHasAccessToAuthNavigation(navigation, role) {
    if (!navigation.roleAuth)
        return true
    else if (navigation.roleAuth.includes(role))
        return true
    return false
}

function facilitySettingHasAccessToAuthNavigation(navigation, settings) {
    if (!navigation.facilityAuth)
        return true
    else {
        for (let facilityAuth of navigation.facilityAuth) {
            if (settings[facilityAuth.name] !== facilityAuth.value)
                return false
        }
        return true
    }
}

export default navigation;
