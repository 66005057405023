import React from 'react';
import EnhancedTextField from "../EnhancedTextField";


export default function UserNameTextField(props) {
    const MAXIMUM_USER_NAME_LENGTH = 64

    return (
        <EnhancedTextField
            {...props}
            fullWidth
            variant="outlined"
            inputProps={{maxLength: MAXIMUM_USER_NAME_LENGTH}}
            onChange={(event) => {
                let newValue = event.target.value;
                if (newValue.trim() === '')
                    newValue = ""
                if (newValue.length <= MAXIMUM_USER_NAME_LENGTH)
                    props.onChange(newValue)
            }}
        />
    );
}