import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setInButtonSettingsForm} from "../store/DevicesSlice";
import {useSetting} from "../../../../custom-hooks/useSetting";
import {SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING} from "../../../../../lib/constants";

export default function useButtonText(textId) {
    const dispatch = useDispatch()
    const {buttonSettingsForm} = useSelector((state) => state.devicesSlice)

    const [newText, setNewText] = useState(null)
    const [buttonLanguages] = useSetting(SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING, []);


    useEffect(() => {
        setNewText(null)
    }, [buttonSettingsForm.translationsByLanguage, buttonSettingsForm.selectedLanguage])

    function getValue() {
        if (newText !== null) {
            return newText;
        }

        if (buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage]) {
            if (buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage][textId]) {
                return buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage][textId]
            } else if (buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage][textId] === null)
                return ""
        }

        return buttonSettingsForm.translationsByLanguage?.[buttonSettingsForm.selectedLanguage]?.[textId] || "";
    }

    function isCustom() {
        if (
            buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage] &&
            buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage][textId]
        ) {
            return buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage][textId] !== "" &&
                buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage][textId] !== null
        } else if (buttonSettingsForm.translationsByLanguage[buttonSettingsForm.selectedLanguage] &&
            buttonSettingsForm.translationsByLanguage[buttonSettingsForm.selectedLanguage][textId]) {
            return buttonSettingsForm.translationsByLanguage[buttonSettingsForm.selectedLanguage][textId] !== ""
        }
        return false
    }

    function setValue(newValue) {
        setNewText(newValue === null ? "" : newValue)
        const existingTexts = buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage] ? buttonSettingsForm.modifiedTranslationsByLanguage[buttonSettingsForm.selectedLanguage] : {}

        dispatch(setInButtonSettingsForm({
            key: "modifiedTranslationsByLanguage",
            value: {
                ...buttonSettingsForm.modifiedTranslationsByLanguage,
                [buttonSettingsForm.selectedLanguage]: {
                    ...existingTexts,
                    [textId]: newValue === "" ? null : newValue
                }
            }
        }))
    }

    function isInformationAboutAutoTranslationVisible() {
        if (buttonLanguages.length > 1)
            return buttonSettingsForm.modifiedTranslationsByLanguage[buttonLanguages[0]] &&
                textId in buttonSettingsForm.modifiedTranslationsByLanguage[buttonLanguages[0]]
        return false
    }

    return {isCustom, getValue, setValue, isInformationAboutAutoTranslationVisible}
}