import React from 'react';

export const AddDevicesFromPackageRedirectorAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/addDevicesFromPackage/:packageId',
            component: React.lazy(() => import('app/main/apps/addDevicesFromPackage/AddDevicesFromPackageRedirectorApp'))
        },
        {
            path: '/addSerial/:serialId',
            component: React.lazy(() => import('app/main/apps/addDevicesFromPackage/AddSerialRedirectorApp'))
        },
    ]
};
