import axios from 'axios';
import {getTimestampFromScreenshotFname} from "../../../../../../lib/commonFunctions";
import {getCurrentSelectedObid} from "../../../../../../lib/getObid";
import {I18n} from "react-redux-i18n";
import {UNRECOGNIZED_ERROR} from "../../../../../../lib/constants";
import {showMessage} from "../../../../../store/actions";

export const GET_DEVICES = '[DEVICES] GET DEVICES';
export const SET_PRODUCTS_SEARCH_TEXT = '[DEVICES] SET PRODUCTS SEARCH TEXT';
export const OPEN_DEVICE_SCREENSHOT_DIALOG = '[DEVICES] OPEN DEVICE SCREENSHOT DIALOG';
export const CLOSE_DEVICE_SCREENSHOT_DIALOG = '[DEVICES] CLOSE DEVICE SCREENSHOT DIALOG';
export const OPEN_DEVICE_ALARM_DIALOG = '[DEVICES] OPEN DEVICE ALARM DIALOG';
export const CLOSE_DEVICE_ALARM_DIALOG = '[DEVICES] CLOSE DEVICE ALARM DIALOG';
export const OPEN_RESTART_APP_DIALOG = '[DEVICES] OPEN RESTART APP DIALOG';
export const OPEN_RESTART_DEVICE_DIALOG = '[DEVICES] OPEN RESTART DEVICE DIALOG';
export const CLOSE_RESTART_APP_DIALOG = '[DEVICES] CLOSE RESTART APP DIALOG';
export const CLOSE_RESTART_DEVICE_DIALOG = '[DEVICES] CLOSE RESTART DEVICE DIALOG';
export const OPEN_ADD_NEW_DEVICE_TO_OBJECT_DIALOG = '[DEVICES] OPEN ADD NEW DEVICE DIALOG';
export const CLOSE_ADD_NEW_DEVICE_TO_OBJECT_DIALOG = '[DEVICES] CLOSE ADD NEW DEVICE DIALOG';
export const SET_SCREENSHOT_IMAGE_LOADING_TRUE = '[DEVICES] SET SCREENSHOT IMAGE LOADING TRUE';
export const SET_SCREENSHOT_IMAGE_LOADING_FALSE = '[DEVICES] SET SCREENSHOT IMAGE LOADING FALSE';
export const SET_SELECTED_DEVICE_SERIAL = '[DEVICES] SET SELECTED DEVICE SERIAL';
export const SET_SELECTED_DEVICE_TYPE = '[DEVICES] SET SELECTED DEVICE TYPE';
export const SEND_ALARM_TO_DEVICE = '[DEVICES] SEND ALARM TO DEVICE';
export const RESTART_APP = '[DEVICES] RESTART APP';
export const RESTART_DEVICE = '[DEVICES] RESTART DEVICE';
export const SET_DEVICE_PANEL_MODE = '[DEVICES] SET DEVICE PANEL MODE';
export const SET_LOADING_TRUE = '[DEVICES] SET LOADING TRUE';
export const SET_DEVICES_TO_SHOW_TYPE = '[DEVICES] SET DEVICES TO SHOW TYPE';
export const ADD_NEW_DEVICES_BY_SERIALS_REPORT = '[DEVICE] ADD NEW DEVICES BY SERIALS REPORT';
export const CLOSE_ADD_NEW_DEVICE_BY_SERIAL_REPORT_DIALOG = '[DEVICES] CLOSE_ADD_NEW_DEVICE_BY_SERIAL_FAILED_DIALOG';
export const REMOVE_DEVICE_BY_SERIAL = '[DEVICE] REMOVE DEVICE BY SERIAL';
export const OPEN_REMOVE_DEVICE_FROM_OBJECT_DIALOG = '[DEVICES] OPEN REMOVE DEVICE FROM OBJECT DIALOG';
export const CLOSE_REMOVE_DEVICE_FROM_OBJECT_DIALOG = '[DEVICES] CLOSE REMOVE DEVICE FROM OBJECT DIALOG';
export const GET_SCREENSHOT = '[DEVICES] GET SCREENSHOT';
export const GET_LATEST_VERSION = '[DEVICES] GET LATEST VERSION';
export const GET_BUTTON_LATEST_VERSION = '[DEVICES] GET BUTTON LATEST VERSION';
export const GET_LATEST_AVAILABLE_SCREENSHOT = '[DEVICES] GET LATEST AVAILABLE SCREENSHOT';
export const OPEN_BUTTON_SETTINGS_DIALOG = '[DEVICES] OPEN BUTTON SETTINGS DIALOG';
export const CLOSE_BUTTON_SETTINGS_DIALOG = '[DEVICES] CLOSE BUTTON SETTINGS DIALOG';
export const GET_BUTTON_SETTINGS = '[DEVICES] GET BUTTON SETTINGS';
export const SET_LOADING_FALSE = '[DEVICES] SET LOADING FALSE';
export const SIMULATE_BUTTON_CLICK = '[DEVICES] SIMULATE BUTTON CLICK';
export const GET_SHADOW_FOR_SERIAL = '[DEVICES] GET SHADOW FOR SERIAL';
export const SET_BUTTON_SETTINGS_LOADING = '[DEVICES] SET BUTTON SETTINGS LOADING';


export function getDevices(showLoading) {
    const request = axios.get('/api/devices/list', {
        params: {
            selectedObid: getCurrentSelectedObid()
        }
    });

    return (dispatch) => {
        if (showLoading)
            dispatch(setLoadingTrue());
        request.then((response) => {
                dispatch({
                    type: GET_DEVICES,
                    payload: response.data
                })
            }
        ).finally(() => {
            dispatch(setLoadingFalse())
        })
    }
}

export function setLoadingTrue() {
    return {
        type: SET_LOADING_TRUE,
    }
}

export function setLoadingFalse() {
    return {
        type: SET_LOADING_FALSE,
    }
}

export function setDevicePanelMode(devicePanelMode) {
    return {
        type: SET_DEVICE_PANEL_MODE,
        payload: devicePanelMode
    }
}

export function setDevicesToShowType(type) {
    return {
        type: SET_DEVICES_TO_SHOW_TYPE,
        payload: type
    }
}

export function sendAlarm(serial) {
    axios.post('/api/devices/ringAlarm', {
        selectedObid: getCurrentSelectedObid(),
        serial
    })

    return {
        type: SEND_ALARM_TO_DEVICE,
    }
}

export function restartApp(serial) {
    axios.post(`/api/devices/${serial}/restart-app`, {
        selectedObid: getCurrentSelectedObid()
    })

    return {
        type: RESTART_APP,
    }
}

export function restartDevice(serial) {
    axios.post(`/api/devices/${serial}/reboot`, {
        selectedObid: getCurrentSelectedObid()
    })

    return {
        type: RESTART_DEVICE,
    }
}

export function setScreenshotImageLoadingFalse() {
    return {
        type: SET_SCREENSHOT_IMAGE_LOADING_FALSE,
    }
}

export function setScreenshotImageLoadingTrue() {
    return {
        type: SET_SCREENSHOT_IMAGE_LOADING_TRUE,
    }
}

export function openScreenshotDialog() {
    return {
        type: OPEN_DEVICE_SCREENSHOT_DIALOG,
    }
}

export function closeDeviceScreenshotDialog() {
    return {
        type: CLOSE_DEVICE_SCREENSHOT_DIALOG,
    }
}

export function setButtonSettingsLoading(buttonSettingsLoading) {
    return {
        type: SET_BUTTON_SETTINGS_LOADING,
        payload: buttonSettingsLoading
    }
}

export function setSelectedDeviceSerial(serial) {
    return {
        type: SET_SELECTED_DEVICE_SERIAL,
        serial
    }
}

export function setSelectedDeviceType(deviceType) {
    return {
        type: SET_SELECTED_DEVICE_TYPE,
        deviceType
    }
}

export function openDeviceAlarmDialog() {
    return {
        type: OPEN_DEVICE_ALARM_DIALOG,
    }
}

export function closeDeviceAlarmDialog() {
    return {
        type: CLOSE_DEVICE_ALARM_DIALOG,
    }
}

export function openRestartAppDialog() {
    return {
        type: OPEN_RESTART_APP_DIALOG,
    }
}

export function openRestartDeviceDialog() {
    return {
        type: OPEN_RESTART_DEVICE_DIALOG,
    }
}

export function closeRestartAppDialog() {
    return {
        type: CLOSE_RESTART_APP_DIALOG,
    }
}

export function closeRestartDeviceDialog() {
    return {
        type: CLOSE_RESTART_DEVICE_DIALOG,
    }
}

export function openAddNewDeviceToObjectDialog() {
    return {
        type: OPEN_ADD_NEW_DEVICE_TO_OBJECT_DIALOG,
    }
}

export function closeAddNewDeviceToObjectDialog() {
    return {
        type: CLOSE_ADD_NEW_DEVICE_TO_OBJECT_DIALOG,
    }
}

export function openRemoveDeviceFromObjectDialog() {
    return {
        type: OPEN_REMOVE_DEVICE_FROM_OBJECT_DIALOG,
    }
}

export function closeRemoveDeviceFromObjectDialog() {
    return {
        type: CLOSE_REMOVE_DEVICE_FROM_OBJECT_DIALOG,
    }
}

export function addNewDevicesBySerials(serials, partnerSelectedObid = null) {
    return (dispatch) => {
        axios.post('/api/serials/assignSerialsToObjectAndGetReport', {
            selectedObid: getCurrentSelectedObid(),
            targetObid: partnerSelectedObid ? partnerSelectedObid : getCurrentSelectedObid(),
            serials
        }).then((response) => {
            dispatch({
                type: ADD_NEW_DEVICES_BY_SERIALS_REPORT,
                payload: response.data
            });
        })
    }
}

export function removeDeviceBySerial(serial) {
    axios.post('/api/serials/assignSerialToObject', {
        selectedObid: getCurrentSelectedObid(),
        targetObid: "",
        serial
    })
    return {
        type: REMOVE_DEVICE_BY_SERIAL,
        serial
    }
}

export function getScreenshot(serial) {
    axios.post('/api/devices/sendCommand', {
        selectedObid: getCurrentSelectedObid(),
        serial,
        cmnd: "getScreenshot"
    })

    return {
        type: GET_SCREENSHOT,
    }
}

export function getLatestAvailableScreenshot(serial, newerThan) {
    return (dispatch) => {
        axios.post('/api/devices/getLatestAvailableScreenshot', {
            selectedObid: getCurrentSelectedObid(),
            serial
        }).then((response) => {
                if (null == newerThan || (newerThan && getTimestampFromScreenshotFname(response.data) > newerThan)) {
                    dispatch({
                        type: GET_LATEST_AVAILABLE_SCREENSHOT,
                        payload: response.data
                    })
                }
            }
        )
    }
}

export function clearLatestAvailableScreenshot() {
    return {
        type: GET_LATEST_AVAILABLE_SCREENSHOT,
        payload: ''
    }
}

export function setProductsSearchText(event) {
    return {
        type: SET_PRODUCTS_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function getLatestVersion() {
    return (dispatch) => {
        axios.get('/api/get_apk_latest_version').then((response) => {
                dispatch({
                    type: GET_LATEST_VERSION,
                    payload: response.data
                })
            }
        )
    }
}

export function getLatestButtonVersion() {
    return (dispatch) => {
        axios.get('/api/get_button_apk_latest_version').then((response) => {
                dispatch({
                    type: GET_BUTTON_LATEST_VERSION,
                    payload: response.data
                })
            }
        )
    }
}

export function getShadowForSerial(serial) {
    return (dispatch) => {
        axios.get(`/api/devices/${serial}/shadow`, {
            params: {
                selectedObid: getCurrentSelectedObid()
            }
        }).then((response) => {
                dispatch({
                    type: GET_SHADOW_FOR_SERIAL,
                    payload: response.data
                })
            }
        )
    }
}

export function simulateButtonClick(serial) {
    axios.post('/api/buttons/simulate-click', {
        serial,
        selectedObid: getCurrentSelectedObid()
    })

    return {
        type: SIMULATE_BUTTON_CLICK,
    }
}

export function closeAddingNewDeviceBySerialRaportDialog() {
    return {
        type: CLOSE_ADD_NEW_DEVICE_BY_SERIAL_REPORT_DIALOG,
    }
}

export function openButtonSettingsDialog(serial) {
    return {
        type: OPEN_BUTTON_SETTINGS_DIALOG,
        payload: serial
    }
}

export function closeButtonSettingsDialog() {
    return {
        type: CLOSE_BUTTON_SETTINGS_DIALOG,
    }
}

export function getButtonSettings(serial) {
    const request = axios.get(`/api/facilities/${getCurrentSelectedObid()}/buttons-settings/${serial}`)

    return (dispatch) => {
        dispatch(setButtonSettingsLoading(true))
        request.then((response) =>
            dispatch({
                type: GET_BUTTON_SETTINGS,
                payload: response.data,
            })
        ).catch(() => {
            dispatch(closeButtonSettingsDialog())
        }).finally(() => {
            dispatch(setButtonSettingsLoading(false))
        })
    }
}

export function setButtonSettings(serial, form) {
    return (dispatch) =>
        axios.patch(`/api/facilities/${getCurrentSelectedObid()}/buttons-settings/${serial}`, {
            ...form
        }).then(() => {
            dispatch(getDevices())

            dispatch(showMessage({
                message: I18n.t("Settings saved"),
                variant: "success",
                autoHideDuration: 3000,
            }));
        }).catch(() => {
            dispatch(showMessage({
                message: `${I18n.t(UNRECOGNIZED_ERROR)}`,
                variant: "error"
            }));
        })
}
