import React from 'react';

export const ButtonsReportsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/reports/buttons',
            component: React.lazy(() => import('./ButtonsReportsApp'))
        }
    ]
};