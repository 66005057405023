import React from 'react';
import {Dialog, DialogActions, DialogContent, makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import * as Actions from "app/main/apps/devices/store/actions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import EnhancedTranslate from "../../../../../common-components/EnhancedTranslate";
import {ELEVATED_DIALOG_Z_INDEX} from "../../../rma/constants";
import {TransitionComponent} from "../../../../../../lib/commonFunctions";


export const useStyles = makeStyles(() => ({
    dialog: {
        zIndex: ELEVATED_DIALOG_Z_INDEX,
    },
}));

function DeviceAlarmDialog() {
    const dispatch = useDispatch();
    const deviceAlarmDialogOpen = useSelector(({devices}) => devices.devices.deviceAlarmDialogOpen);
    const selectedDeviceSerial = useSelector(({devices}) => devices.devices.selectedDeviceSerial);

    function closeComposeDialog() {
        dispatch(Actions.closeDeviceAlarmDialog());
    }

    function sendAlarm() {
        dispatch(Actions.sendAlarm(selectedDeviceSerial));
        dispatch(Actions.closeDeviceAlarmDialog());
    }

    return (
        <Dialog
            style={{zIndex: ELEVATED_DIALOG_Z_INDEX}}
            open={deviceAlarmDialogOpen}
            TransitionComponent={TransitionComponent}
            keepMounted
            onClose={closeComposeDialog}
        >
            <DialogTitle>
                <EnhancedTranslate
                    value={'Send alarm dialog title'}
                />
                {selectedDeviceSerial}?
            </DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeComposeDialog} color="primary">
                    <EnhancedTranslate value={'No'}/>
                </Button>
                <Button onClick={sendAlarm} color="primary">
                    <EnhancedTranslate value={'Yes'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeviceAlarmDialog;
