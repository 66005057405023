import {useEffect} from 'react';
import {getSelectedObjectName} from "../../lib/commonFunctions";
import {useSelector} from "react-redux";

export const useDocumentTitle = title => {
    const obid = useSelector(({common}) => common.common.obid);

    useEffect(() => {
        const objectName = getSelectedObjectName();
        if (objectName)
            document.title = `${title} | ${objectName} | cDashboard`;
        else
            document.title = `${title} | cDashboard`;
    }, [title, obid])
};
