import React from 'react';

export const RmaAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: '/rma',
            component: React.lazy(() => import('./RmaApp'))
        },
    ]
};
