import React, {useState} from 'react';
import EnhancedTextField from "../EnhancedTextField";
import {InputAdornment} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {MAX_PASSWORD_LENGTH} from "../../../lib/constants";


export default function PasswordTextField(props) {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    return (
        <EnhancedTextField
            {...props}
            fullWidth
            type={isPasswordVisible ? 'text' : 'password'}
            variant="outlined"
            inputProps={{
                autoComplete: 'new-password',
                form: {
                    autoComplete: 'off',
                },
                maxLength: MAX_PASSWORD_LENGTH
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        edge="end"
                    >
                        {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                </InputAdornment>
            }}
            onChange={(event) => {
                let newValue = event.target.value
                if (newValue.length <= MAX_PASSWORD_LENGTH)
                    props.onChange(newValue)
            }}
        />
    );
}