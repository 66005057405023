import axios from 'axios';
import {getCurrentSelectedObid} from "../../../../../../lib/getObid";
import {I18n} from "react-redux-i18n";
import * as MessageActions from "../../../../../store/actions";
import {getSettings} from "../../../settings/store/actions";

export const SET_SEARCH_TEXT = '[JOB TITLES] SET SEARCH TEXT';
export const OPEN_NEW_ENTITY_DIALOG = '[JOB TITLES] OPEN NEW ENTITY DIALOG';
export const CLOSE_NEW_ENTITY_DIALOG = '[JOB TITLES] CLOSE NEW ENTITY DIALOG';
export const ADD_ENTITY = '[JOB TITLES] ADD ENTITY';
export const UPDATE_ENTITY = '[JOB TITLES] UPDATE ENTITY';
export const REMOVE_JOB_TITLES = '[JOB TITLES] REMOVE JOB TITLES';
export const SET_LOADING_TRUE = '[JOB TITLES] SET LOADING TRUE';
export const GET_JOB_TITLES_DATA = '[JOB TITLES] GET JOB TITLES DATA';
export const SET_JOB_TITLES_LOADING = '[JOB TITLES] SET JOB TITLES LOADING';
export const JOBTITLE_NAME_COLLISION = '[JOB TITLES] JOBTITLE_NAME_COLLISION';
export const RESET_JOBTITLE_NAME_COLLISION = '[JOB TITLES] RESET JOBTITLE NAME_COLLISION';
export const SAVE_TEMPORARY_USERS_SETTINGS = '[JOB TITLES] SAVE TEMPORARY USERS SETTINGS';

export const getJobTitlesData = () => {
    return (dispatch) => {
        dispatch(setJobTitlesLoading(true));
        const request = axios.get('/api/jobTitles/list', {
            params: {
                selectedObid: getCurrentSelectedObid()
            }
        });

        request.then((response) =>
            dispatch({
                type: GET_JOB_TITLES_DATA,
                payload: response.data
            })
        ).finally(() => dispatch(setJobTitlesLoading(false)))
    }
};

export function addEntity(newEntity, createCommunicationGroup) {
    return (dispatch) => {
        newEntity['createCommunicationGroup'] = createCommunicationGroup;
        newEntity['usersCount'] = 0;
        newEntity['teamsIds'] = [];

        const request = axios.post('/api/jobTitles/add', {
            ...newEntity,
            selectedObid: getCurrentSelectedObid()
        });

        return request.then((response) => {
                newEntity['id'] = response.data;
                dispatch({
                    type: ADD_ENTITY,
                    newEntity
                });
            }
        )
    };
}

export function updateEntity(jobTitle) {
    const request = axios.post('/api/jobTitles/edit', {
        ...jobTitle,
        selectedObid: getCurrentSelectedObid()
    });
    return (dispatch) => {
        request.then((response) => {
            if (response.data.status === "ok") {
                dispatch({
                    type: UPDATE_ENTITY,
                    payload: jobTitle
                })
            }
            if (response.data.error === "Duplicate entry") {
                dispatch({
                    type: JOBTITLE_NAME_COLLISION
                })
            }
        })
    };
}

export function saveTemporaryUsersSettings(temporaryUsersEnabled, allJobTitles, jobTitles) {
    return (dispatch) => {
        const request = axios.post('/api/jobTitles/saveTemporaryUsersSettings', {
            temporaryUsersEnabled: temporaryUsersEnabled ? 1 : 0,
            allJobTitles: allJobTitles ? 1 : 0,
            jobTitles: jobTitles,
            selectedObid: getCurrentSelectedObid()
        });

        request.then(() => {
            dispatch(getSettings(getCurrentSelectedObid()))
            dispatch(MessageActions.showMessage({
                message: I18n.t("Settings saved"),
                variant: 'success'
            }));
        })

        return {
            type: SAVE_TEMPORARY_USERS_SETTINGS
        }
    }
}

export function setJobTitlesLoading(jobTitlesLoading) {
    return {
        type: SET_JOB_TITLES_LOADING,
        payload: jobTitlesLoading
    }
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function openNewEntityDialog() {
    return {
        type: OPEN_NEW_ENTITY_DIALOG
    }
}

export function closeNewEntityDialog() {
    return {
        type: CLOSE_NEW_ENTITY_DIALOG
    }
}

export function removeJobTitles(ids) {
    return (dispatch) => {

        axios.post('/api/jobTitles/delete', {
            selectedObid: getCurrentSelectedObid(),
            jobTitlesToDelete: ids
        })


        dispatch({
            type: REMOVE_JOB_TITLES,
            payload: ids,
        });
    };
}
