import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import EnhancedTranslate from "./EnhancedTranslate";
import {useTranslatorMode} from "../custom-hooks/useTranslatorMode";


function TranslationTooltip({title, children}) {
    const translatorModeEnabled = useSelector(({common}) => common.common.translatorModeEnabled);
    const [getTooltipContent] = useTranslatorMode()

    return (
        <Fragment>
            {translatorModeEnabled &&
                <Tooltip title={getTooltipContent(title)}>
                    {children}
                </Tooltip>
            }
            {!translatorModeEnabled &&
                <Tooltip title={<EnhancedTranslate value={title}/>}>
                    {children}
                </Tooltip>
            }
        </Fragment>
    );
}

export default TranslationTooltip;
