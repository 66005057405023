import {createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import {getCurrentSelectedObid} from "../../../../../lib/getObid";
import {ERROR_LOCATION_NOT_FOUND, ERROR_MISSING_CELLULAR_PINGS, ERROR_UNKNOWN_DEVICE_LOCATION_PROBLEM} from "../constants";
import {getSettings} from "../../settings/store/actions";
import {showMessage} from "../../../../store/actions";
import {I18n} from "react-redux-i18n";
import {UNRECOGNIZED_ERROR} from "../../../../../lib/constants";
import {downloadFileFromBlobResponse} from "../../../../../lib/commonFunctions";
import * as Actions from "./actions/devices.actions";

export const initialState = {
    devicesLocationDialog: {
        open: false,
        error: null,
        device: {},
        loading: true,
        location: null,
        lastPingTimestamp: null,
        lastLoggedUser: null,
        lastLoggedUserLoading: false
    },
    deviceLogsDialog: {
        open: false,
        downloadLoading: false,
        serial: null,
    },
    buttonSettingsForm: {
        name: "",
        informSecondaryJobTitlesImmediatelyWhen1PersonOnPrimaryJobTitle: false,
        jobTitleToInformAboutUnhandledTasks: -1,
        primaryJobTitles: [],
        primaryUids: [],
        secondaryJobTitles: [],
        secondaryUids: [],
        translationsByLanguage: {},
        modifiedTranslationsByLanguage: {},
        selectedLanguage: null
    },
    defaultButtonTranslations: null,
    defaultButtonTranslationsLoading: false
};

export const fetchDeviceLocation = (serial) => async (dispatch) => {
    dispatch(setLocationLoading(true))
    axios.get(`/api/devices/${serial}/location`, {
        params: {
            facilityId: getCurrentSelectedObid(),
        }
    }).then((response) => {
        dispatch(setLocation(response.data))
    }).catch((error) => {
        if (error.response && error.response.status === 404)
            dispatch(setLocationError(ERROR_LOCATION_NOT_FOUND))
        else if (error.response && error.response.status === 422)
            dispatch(setLocationError(ERROR_MISSING_CELLULAR_PINGS))
        else
            dispatch(setLocationError(ERROR_UNKNOWN_DEVICE_LOCATION_PROBLEM))
    }).finally(() => {
        dispatch(setLocationLoading(false))
    })
};

export const downloadDeviceLogs = (serial, date) => async (dispatch) => {
    dispatch(setDownloadLogsLoading(true))
    axios.post(`/api/devices/${serial}/download-logs`, {
        date: date
    }).then(() => {
    })
};

export const fetchDeviceLogs = (serial, date) => async (dispatch) => {
    axios.get(`/api/devices/${serial}/logs`, {
        responseType: 'blob',
        params: {
            date: date
        }
    }).then((response) => {
        downloadFileFromBlobResponse(response.data, `device-${serial}-logs.zip`)
        dispatch(setDownloadLogsLoading(false))
    }).catch((err) => {
        if (err?.response?.status !== 422) {
            dispatch(showMessage({
                message: `${I18n.t(UNRECOGNIZED_ERROR)}`,
                variant: "error"
            }));
        }
    })
};

export const fetchLastLoggedUserOnDevice = (serial) => async (dispatch) => {
    dispatch(setLastLoggedUserLoading(true))
    axios.get(`/api/devices/${serial}/last-logged-user`, {
        params: {
            facilityId: getCurrentSelectedObid(),
        }
    }).then((response) => {
        dispatch(setLastLoggedUser(response.data))
    }).catch(() => {
        dispatch(setLastLoggedUser(null))
    }).finally(() => {
        dispatch(setLastLoggedUserLoading(false))
    })
};

export const fetchDefaultButtonTranslations = () => async (dispatch) => {
    dispatch(setDefaultButtonTranslationsLoading(true))
    axios.get(`/api/facilities/${getCurrentSelectedObid()}/button-translations`)
        .then((response) => {
            dispatch(setDefaultButtonTranslations(response.data))
        })
        .finally(() => {
            dispatch(setDefaultButtonTranslationsLoading(false))
        })
};

export const setButtonsLanguages = (buttonLanguages) => async (dispatch) => {
    axios.patch(`/api/facilities/${getCurrentSelectedObid()}`, {
        buttonLanguages,
    }).then(() => {
        dispatch(getSettings(getCurrentSelectedObid()))
    })
};

export const changeWatchUserAssignment = (userId, serial) => async (dispatch) => {
    axios.patch(`/api/facilities/${getCurrentSelectedObid()}/watches/${serial}`, {
        assignedUser: userId,
    }).then(() => {
        dispatch(Actions.getDevices(false));
    })
};

const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        openDeviceLocationDialog: (state, action) => {
            state.devicesLocationDialog = {
                open: true,
                device: action.payload
            }
        },
        openDeviceLogsDialog: (state, action) => {
            state.deviceLogsDialog = {
                open: true,
                serial: action.payload
            }
        },
        closeDeviceLogsDialog: (state, action) => {
            state.deviceLogsDialog = initialState.deviceLogsDialog
        },
        closeDeviceLocationDialog: (state, action) => {
            state.devicesLocationDialog = initialState.devicesLocationDialog
        },
        setLocationLoading: (state, action) => {
            state.devicesLocationDialog.loading = action.payload
        },
        setLastLoggedUserLoading: (state, action) => {
            state.devicesLocationDialog.lastLoggedUserLoading = action.payload
        },
        setDownloadLogsLoading: (state, action) => {
            state.deviceLogsDialog.downloadLoading = action.payload
        },
        setLocation: (state, action) => {
            const {location, lastPingTimestamp} = action.payload

            state.devicesLocationDialog.location = location
            state.devicesLocationDialog.lastPingTimestamp = lastPingTimestamp
            state.devicesLocationDialog.error = initialState.devicesLocationDialog.error
        },
        setLastLoggedUser: (state, action) => {
            state.devicesLocationDialog.lastLoggedUser = action.payload
        },
        setLocationError: (state, action) => {
            state.devicesLocationDialog.error = action.payload
        },
        setInButtonSettingsForm: (state, action) => {
            state.buttonSettingsForm[action.payload.key] = action.payload.value
        },
        setButtonSettingsForm: (state, action) => {
            state.buttonSettingsForm = action.payload
        },
        setDefaultButtonSettingsForm: (state, action) => {
            state.buttonSettingsForm = initialState.buttonSettingsForm
        },
        setDefaultButtonTranslations: (state, action) => {
            state.defaultButtonTranslations = action.payload
        },
        setDefaultButtonTranslationsLoading: (state, action) => {
            state.defaultButtonTranslationsLoading = action.payload
        },
    }
});
export const {
    openDeviceLocationDialog,
    openDeviceLogsDialog,
    closeDeviceLocationDialog,
    closeDeviceLogsDialog,
    setLocationLoading,
    setDownloadLogsLoading,
    setLocation,
    setLastLoggedUser,
    setLastLoggedUserLoading,
    setLocationError,
    setDefaultButtonTranslations,
    setDefaultButtonTranslationsLoading,
    setInButtonSettingsForm,
    setButtonSettingsForm,
    setDefaultButtonSettingsForm
} = devicesSlice.actions;
export default devicesSlice.reducer;