import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {setInformationAboutTermsOfServiceDialogOpen} from "../../auth/store/LoginSlice";
import {TransitionComponent} from "../../../lib/commonFunctions";
import EnhancedTranslate from "../../common-components/EnhancedTranslate";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    textContainer: {
        textAlign: 'justify'
    },
}));

export default function InformationAboutTermsOfServiceDialog() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const open = useSelector((state) => state.login.informationAboutTermsOfServiceDialogOpen);

    function closeDialog() {
        dispatch(setInformationAboutTermsOfServiceDialogOpen(false));
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={TransitionComponent}
            onClose={closeDialog}
            fullWidth
            maxWidth="xs"
        >
            <div className={"p-16"}>
                <div className={classes.textContainer}>
                    <EnhancedTranslate value={'tos-info-for-old-users'}/>
                </div>
                <div className={"flex justify-center mt-16"}>
                    <div>
                        <Button
                            onClick={closeDialog}
                            color="primary"
                            variant={"outlined"}
                        >
                            <EnhancedTranslate value={'OK'}/>
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
