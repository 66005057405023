import * as Actions from '../actions';
import _ from 'lodash';
import {RESET_STORE} from "../../../../../store/actions/reduxActions";

const initialState = {
    data: [],
    searchText: '',
    deviceAlarmDialogOpen: false,
    deviceScreenshotImageLoading: false,
    selectedDeviceSerial: '',
    selectedDeviceType: '',
    devicesPanelMode: 0, //0=all, 1=online, 2=offline
    restartAppDialogOpen: false,
    restartDeviceDialogOpen: false,
    addNewDeviceToObjectDialogOpen: false,
    removeDeviceFromObjectDialogOpen: false,
    deviceScreenshotDialogOpen: false,
    loading: false,
    devicesTypeToShow: "all",
    latestVersion: '',
    buttonLatestVersion: '',
    latestAvailableScreenshot: '',
    lastGetScreenshotRequestTimestamp: 0,
    addingNewDevicesAddedSerials: [],
    addingNewDevicesFailedSerials: [],
    buttonSettingsDialogOpen: false,
    buttonSettingsDialogSerial: null,
    deviceShadow: null,
    buttonsSettings: null,
    buttonSettingsLoading: false,
    assignedWatchUsers: [],
};

// eslint-disable-next-line complexity
const devicesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_DEVICES: {
            const devices = _.values(action.payload)
            return {
                ...state,
                data: action.payload && devices,
                loading: false,
                assignedWatchUsers: devices.map(device => device.assignedUser)
            };
        }
        case Actions.SET_PRODUCTS_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.SET_BUTTON_SETTINGS_LOADING: {
            return {
                ...state,
                buttonSettingsLoading: action.payload
            };
        }
        case Actions.SET_LOADING_TRUE: {
            return {
                ...state,
                loading: true
            };
        }
        case Actions.SET_LOADING_FALSE: {
            return {
                ...state,
                loading: false
            };
        }
        case Actions.SET_DEVICE_PANEL_MODE: {
            return {
                ...state,
                devicesPanelMode: action.payload
            };
        }
        case Actions.SET_SELECTED_DEVICE_SERIAL: {
            return {
                ...state,
                selectedDeviceSerial: action.serial
            };
        }
        case Actions.SET_SELECTED_DEVICE_TYPE: {
            return {
                ...state,
                selectedDeviceType: action.deviceType
            };
        }
        case Actions.OPEN_DEVICE_SCREENSHOT_DIALOG: {
            return {
                ...state,
                deviceScreenshotDialogOpen: true
            };
        }
        case Actions.SET_SCREENSHOT_IMAGE_LOADING_TRUE: {
            return {
                ...state,
                deviceScreenshotImageLoading: true
            };
        }
        case Actions.SET_SCREENSHOT_IMAGE_LOADING_FALSE: {
            return {
                ...state,
                deviceScreenshotImageLoading: false
            };
        }
        case Actions.GET_SHADOW_FOR_SERIAL: {
            return {
                ...state,
                deviceShadow: action.payload
            };
        }
        case Actions.CLOSE_DEVICE_SCREENSHOT_DIALOG: {
            return {
                ...state,
                deviceScreenshotDialogOpen: false
            };
        }
        case Actions.OPEN_DEVICE_ALARM_DIALOG: {
            return {
                ...state,
                deviceAlarmDialogOpen: true
            };
        }
        case Actions.CLOSE_DEVICE_ALARM_DIALOG: {
            return {
                ...state,
                deviceAlarmDialogOpen: false
            };
        }
        case Actions.OPEN_RESTART_APP_DIALOG: {
            return {
                ...state,
                restartAppDialogOpen: true
            };
        }
        case Actions.CLOSE_RESTART_APP_DIALOG: {
            return {
                ...state,
                restartAppDialogOpen: false
            };
        }
        case Actions.OPEN_RESTART_DEVICE_DIALOG: {
            return {
                ...state,
                restartDeviceDialogOpen: true
            };
        }
        case Actions.CLOSE_RESTART_DEVICE_DIALOG: {
            return {
                ...state,
                restartDeviceDialogOpen: false
            };
        }
        case Actions.OPEN_ADD_NEW_DEVICE_TO_OBJECT_DIALOG: {
            return {
                ...state,
                addNewDeviceDialogOpen: true
            };
        }
        case Actions.OPEN_REMOVE_DEVICE_FROM_OBJECT_DIALOG: {
            return {
                ...state,
                removeDeviceFromObjectDialogOpen: true
            };
        }
        case Actions.CLOSE_REMOVE_DEVICE_FROM_OBJECT_DIALOG: {
            return {
                ...state,
                removeDeviceFromObjectDialogOpen: false
            };
        }
        case Actions.SET_DEVICES_TO_SHOW_TYPE: {
            return {
                ...state,
                devicesTypeToShow: action.payload
            }
        }
        case Actions.GET_LATEST_VERSION: {
            return {
                ...state,
                latestVersion: String(action.payload)
            }
        }
        case Actions.GET_BUTTON_LATEST_VERSION: {
            return {
                ...state,
                buttonLatestVersion: String(action.payload)
            }
        }
        case Actions.GET_LATEST_AVAILABLE_SCREENSHOT: {
            return {
                ...state,
                latestAvailableScreenshot: action.payload
            }
        }
        case Actions.GET_SCREENSHOT: {
            return {
                ...state,
                lastGetScreenshotRequestTimestamp: Math.floor(Date.now() / 1000),
            }
        }
        case Actions.CLOSE_ADD_NEW_DEVICE_TO_OBJECT_DIALOG: {
            return {
                ...state,
                addNewDeviceDialogOpen: false,
            };
        }
        case Actions.ADD_NEW_DEVICES_BY_SERIALS_REPORT: {
            return {
                ...state,
                addNewDeviceDialogOpen: false,
                addingNewDevicesAddedSerials: action.payload?.addedSerials,
                addingNewDevicesFailedSerials: action.payload?.failedSerials
            }
        }
        case Actions.CLOSE_ADD_NEW_DEVICE_BY_SERIAL_REPORT_DIALOG: {
            return {
                ...state,
                addingNewDevicesAddedSerials: [],
                addingNewDevicesFailedSerials: []
            };
        }
        case Actions.OPEN_BUTTON_SETTINGS_DIALOG: {
            return {
                ...state,
                buttonSettingsDialogOpen: true,
                buttonSettingsDialogSerial: action.payload
            };
        }
        case Actions.CLOSE_BUTTON_SETTINGS_DIALOG: {
            return {
                ...state,
                buttonSettings: null,
                buttonSettingsDialogOpen: false
            };
        }
        case Actions.GET_BUTTON_SETTINGS: {
            return {
                ...state,
                buttonSettings: action.payload
            };
        }
        case RESET_STORE:
            return initialState
        default: {
            return state;
        }
    }
};

export default devicesReducer;
