import React from 'react';
import {Typography} from '@material-ui/core';
import {FuseAnimate} from '@fuse';
import {Link} from 'react-router-dom';
import {useDispatch} from "react-redux";
import * as Actions from 'app/store/actions';
import EnhancedTranslate from "../../../../common-components/EnhancedTranslate";

function ErrorPage() {
    const dispatch = useDispatch();

    function cleanError() {
        dispatch(Actions.setErrorFalse());
    }

    return (
        <div className="flex flex-col flex-1 items-center justify-center p-16">

            <div className="max-w-512 text-center">

                <FuseAnimate animation="transition.expandIn" delay={100}>
                    <Typography variant="h1" color="inherit" className="font-medium mb-16">
                        <EnhancedTranslate value={'Error'}/>
                    </Typography>
                </FuseAnimate>

                <FuseAnimate delay={500}>
                    <Typography variant="h5" color="textSecondary" className="mb-16">
                        <EnhancedTranslate value={'Sorry unexpected error'}/>
                    </Typography>
                </FuseAnimate>

                <Link onClick={cleanError} className="font-medium" to="/summary"><EnhancedTranslate value={'Back to the summary'}/></Link>
            </div>
        </div>
    );
}

export default ErrorPage;
