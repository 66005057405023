import React from 'react';
import {SETTING_PRIVACY_RESTRICTIONS} from "../../../../../lib/constants";

export const WorkRegisterReportsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    facilityAuth: [
        {
            name: SETTING_PRIVACY_RESTRICTIONS,
            value: "0"
        }
    ],
    routes: [
        {
            path: '/reports/work-register',
            component: React.lazy(() => import('./WorkRegisterReports'))
        }
    ]
};
