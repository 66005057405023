import React, {useEffect, useRef, useState} from 'react';
import {AppBar, Button, Dialog, DialogActions, DialogContent, Fade, makeStyles, Paper, Toolbar, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {setUserDetailsDialogOpen, submitUserDetails} from "../../auth/store/UserSlice";
import EnhancedTranslate from "../../common-components/EnhancedTranslate";
import {getCountry} from "../../../lib/languages";
import PhoneInputWithFlagsAndCountryCodes from "../../common-components/PhoneInputWithFlagsAndCountryCodes";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {useForm} from "../../../@fuse/hooks";
import Popper from '@material-ui/core/Popper';
import PasswordsValidationRules from "./PasswordsValidationRules";
import useResize from "../../custom-hooks/useResize";
import UserNameTextField from "../../common-components/TextFields/UserNameTextField";
import LanguagesSelect from "../../common-components/Selects/LanguagesSelect";
import PasswordTextField from "../../common-components/TextFields/PasswordTextField";


const useStyles = makeStyles(() => ({
    dialogPaper: {
        overflow: "hidden",
        borderRadius: "6px",
        height: "700px",
    },
}));

const defaultFormState = {
    name: '',
    surname: '',
    phoneNum: '',
    language: '',
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
};


export default function EditUserDialog() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {open, currentPasswordIncorrect} = useSelector((state) => state.user.editUserDialog)
    const {name, surname, phoneNum, language} = useSelector(({auth}) => auth.user);

    const [showChangePassword, setShowChangePassword] = useState(false)
    const [arePasswordsValid, setArePasswordsValid] = useState(false)

    const {form, setForm, setInForm} = useForm(defaultFormState);

    const passwordRulesPopperAnchor = useRef(null);
    const isPasswordRulesPopperOpen = useResize(1200);

    const i18n = useSelector(({i18n}) => i18n);

    useEffect(() => {
        if (open)
            setForm({
                ...form,
                name, surname, phoneNum, language
            })
        if (!open) {
            setShowChangePassword(false)
            setForm(defaultFormState)
        }
    }, [open])

    function close() {
        dispatch(setUserDetailsDialogOpen(false))
    }

    function canBeSubmitted() {
        if (!showChangePassword)
            return !!form.name && !!form.surname && !!form.phoneNum
        else
            return !!form.name &&
                !!form.surname &&
                !!form.phoneNum &&
                !!form.currentPassword &&
                arePasswordsValid

    }

    function submit() {
        let payload = {
            name: form.name,
            surname: form.surname,
            phoneNum: form.phoneNum,
            language: form.language
        }
        if (showChangePassword) {
            payload = {
                ...payload,
                currentPassword: form.currentPassword,
                newPassword: form.newPassword,
                newPasswordConfirmation: form.newPasswordConfirmation
            }
        }

        dispatch(submitUserDetails(payload))
    }

    return (
        <Dialog
            classes={{
                paper: classes.dialogPaper
            }}
            open={open}
            onClose={close}
            fullWidth
            maxWidth="xs"
        >
            <AppBar position="static" elevation={1}>
                <Toolbar className="flex w-full">
                    <Typography variant="subtitle1" color="inherit">
                        <EnhancedTranslate value={'Your account'}/>
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent classes={{root: "p-24"}}>

                <UserNameTextField
                    value={form.name}
                    label={"First name"}
                    onChange={(newValue) => {
                        setInForm("name", newValue)
                    }}
                />

                <UserNameTextField
                    className="mt-16"
                    value={form.surname}
                    label={"Surname"}
                    onChange={(newValue) => {
                        setInForm("surname", newValue)
                    }}
                />

                <LanguagesSelect
                    className="mt-16"
                    value={form.language}
                    onChange={(event) => {
                        setInForm("language", event.target.value)
                    }}
                />

                <PhoneInputWithFlagsAndCountryCodes
                    className={"mt-16"}
                    specialLabel={<EnhancedTranslate value={"Phone"}/>}
                    country={getCountry(i18n.locale)}
                    inputProps={{
                        style: {width: "100%"},
                    }}
                    value={form.phoneNum}
                    onChange={(value, country, e, formattedValue) => {
                        setInForm("phoneNum", formattedValue)
                    }}
                />

                <div
                    className={"mt-16"}
                    onClick={() => setShowChangePassword(!showChangePassword)}
                >
                    <Button
                        color="primary"
                        endIcon={showChangePassword ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    >
                        <EnhancedTranslate value={"Change password"}/>
                    </Button>
                </div>

                {showChangePassword &&
                    <>
                        {!isPasswordRulesPopperOpen &&
                            <div className={"mt-16"}>
                                <PasswordsValidationRules
                                    password={form.newPassword}
                                    passwordConfirmation={form.newPasswordConfirmation}
                                    setArePasswordsValid={setArePasswordsValid}
                                />
                            </div>
                        }

                        <PasswordTextField
                            className="mt-16"
                            label={"Current password"}
                            value={form.currentPassword}
                            error={currentPasswordIncorrect}
                            helperText={currentPasswordIncorrect ? <EnhancedTranslate value={"Wrong password"}/> : ""}
                            onChange={(newValue) => {
                                setInForm("currentPassword", newValue)
                            }}
                        />

                        <PasswordTextField
                            className="mt-16"
                            label={"Repeat password"}
                            value={form.newPassword}
                            onChange={(newValue) => {
                                setInForm("newPassword", newValue)
                            }}
                        />

                        <PasswordTextField
                            className="mt-16"
                            label={"Repeat new password"}
                            value={form.newPasswordConfirmation}
                            onChange={(newValue) => {
                                setInForm("newPasswordConfirmation", newValue)
                            }}
                        />

                        <Popper
                            open={isPasswordRulesPopperOpen}
                            placement="right"
                            disablePortal={false}
                            anchorEl={passwordRulesPopperAnchor.current}
                            transition
                            modifiers={{
                                flip: {
                                    enabled: false,
                                },
                                offset: {
                                    enabled: true,
                                    offset: '-350, 50',
                                },
                            }}
                        >
                            {({TransitionProps}) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper>
                                        <div className="p-16">
                                            <PasswordsValidationRules
                                                password={form.newPassword}
                                                passwordConfirmation={form.newPasswordConfirmation}
                                                setArePasswordsValid={setArePasswordsValid}
                                            />
                                        </div>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </>
                }
            </DialogContent>

            <DialogActions className="justify-between pl-16">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={close}
                >
                    <EnhancedTranslate value="Close"/>
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    ref={passwordRulesPopperAnchor}
                    onClick={submit}
                    disabled={!canBeSubmitted()}
                >
                    <EnhancedTranslate value="Save"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}