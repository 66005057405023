import React from 'react';
import {Button} from "@material-ui/core";

export default function ApiDownPage() {
    return (
        <div>
            API is down. Please try again in a moment.
            <Button
                variant={"contained"}
                className="flex"
                type={"button"}
                onClick={() => {
                    window.location.reload()
                }}
            >
                Refresh
            </Button>
        </div>
    );
}
