import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseUtils} from '@fuse';
import {LoginConfig} from 'app/login/LoginConfig';
import {appsConfigs} from 'app/main/apps/appsConfigs';
import {pagesConfigs} from 'app/main/pages/pagesConfigs';

const routeConfigs = [
    ...appsConfigs,
    ...pagesConfigs,
    LoginConfig
];

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        component: () => <Redirect to="/login"/>
    },
    {
        path: '/login',
        component: () => <Redirect to="/login"/>
    },
    {
        path: '/loginAddDevicesFromPackage',
        component: () => <Redirect to="/login"/>
    }
];

export default routes;
