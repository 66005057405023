import React from 'react';

export const SettingsAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: '/settings',
            component: React.lazy(() => import('app/main/apps/settings/settings/Settings'))
        }
    ]
};
