import React, {useEffect, useState} from 'react';
import {Checkbox, Typography} from "@material-ui/core";
import TranslationTooltip from "../../common-components/TranslationTooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EnhancedTranslate from "../../common-components/EnhancedTranslate";
import {FuseScrollbars} from "../../../@fuse";


export default function Invitations({invitations, onChangeAccepted, onChangeDeclined}) {
    const [accepted, setAccepted] = useState([])
    const [declined, setDeclined] = useState([])

    // its dirty hack for scrollbar, for some reason it appears on first load, even when there is no need to scroll
    const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsScrollbarVisible(true)
        }, 500)
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        let acceptedInvitations = invitations.map(invitation => invitation.facilityId)
        setAccepted(acceptedInvitations)
        onChangeAccepted(acceptedInvitations)
    }, [invitations])

    function handleCheckboxChange(facilityId, isChecked) {
        let checkedCheckboxes = accepted
        if (isChecked)
            checkedCheckboxes = [...checkedCheckboxes, facilityId]
        else
            checkedCheckboxes = checkedCheckboxes.filter(id => id !== facilityId)
        setAccepted(checkedCheckboxes)
        onChangeAccepted(checkedCheckboxes)
    }

    function decline(facilityId) {
        if (accepted.includes(facilityId)) {
            let newAccepted = accepted.filter(id => id !== facilityId)
            setAccepted(newAccepted)
            onChangeAccepted(newAccepted)
        }
        let newDeclined = [...declined, facilityId]
        setDeclined(newDeclined)
        onChangeDeclined(newDeclined)
    }

    const invitationsToDisplay = invitations.filter(invitation => !declined.includes(invitation.facilityId))

    if (invitationsToDisplay.length === 0)
        return (
            <div>
                <Typography className={"font-bold"}>
                    <EnhancedTranslate value={"All invitations declined"}/>
                </Typography>
            </div>
        )

    return (
        <div className={"flex flex-col h-full"}>
            <Typography className={"font-bold"} color={"textSecondary"}>
                <EnhancedTranslate value={"you_got_invited_to"}/>
            </Typography>

            <FuseScrollbars
                enable={isScrollbarVisible}
                option={{
                    suppressScrollX: true
                }}
            >
                <div className={"mt-16"}>
                    {invitationsToDisplay.map((invitation) =>
                        <div key={invitation.facilityId} className="flex justify-between items-center">
                            <div className={"flex items-center"}>
                                <Checkbox
                                    checked={accepted.includes(invitation.facilityId)}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleCheckboxChange(invitation.facilityId, event.target.checked);
                                    }}
                                />
                                <div className={"break-all"}>
                                    <b>
                                        {invitation.facilityName}
                                    </b>
                                </div>
                            </div>
                            <TranslationTooltip title={"Decline invitation"}>
                                <IconButton
                                    onClick={() => {
                                        decline(invitation.facilityId)
                                    }}
                                >
                                    <DeleteIcon color={"primary"}/>
                                </IconButton>
                            </TranslationTooltip>
                        </div>
                    )}
                </div>
            </FuseScrollbars>
            <Typography className={"font-bold mt-16"} color={"textSecondary"}>
                <EnhancedTranslate value={"check_facilities"}/>
            </Typography>
        </div>
    );
}