export const SET_WATCH_SHADOW = '[SHADOW SERVICE] SET WATCH SHADOW';
export const SET_USER_SHADOW = '[SHADOW SERVICE] SET USER SHADOW';

export function setWatchShadow(shadow) {
    return {
        type: SET_WATCH_SHADOW,
        payload: shadow
    }
}

export function setUserShadow(shadow) {
    return {
        type: SET_USER_SHADOW,
        payload: shadow
    }
}