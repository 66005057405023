import axios from 'axios';
import * as MessageActions from "../../../../../store/actions";
import {setObjects, showMessage} from "../../../../../store/actions";
import {I18n} from "react-redux-i18n";
import {store} from "../../../../../store";
import {getCurrentSelectedObid} from "../../../../../../lib/getObid";
import {INSTANCE_TYPE_ALPHA, INSTANCE_TYPE_CLOUD, LONG_ENDPOINT_TIMEOUT_60_SECONDS, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS, UNRECOGNIZED_ERROR} from "../../../../../../lib/constants";
import {fetchFacilityManagers, fetchFacilityManagersInvitations, setFacilityCreationStatus, setInNewFacilityForm} from "../PartnerSlice";
import {getSettings} from "../../../settings/store/actions";
import {ERROR_USER_ALREADY_HAS_PERMISSION, ERROR_USER_ALREADY_INVITED} from "../../../dashboardAccess/constants";

export const GET_ENTITIES = '[PARTNER] GET ENTITIES';
export const SET_SEARCH_TEXT = '[PARTNER] SET SEARCH TEXT';
export const OPEN_EDIT_FACILITY_DIALOG = '[PARTNER] OPEN EDIT FACILITY DIALOG';
export const CLOSE_EDIT_FACILITY_DIALOG = '[PARTNER] CLOSE EDIT FACILITY DIALOG';
export const ADD_ENTITY = '[PARTNER] ADD ENTITY';
export const UPDATE_ENTITY = '[PARTNER] UPDATE ENTITY';
export const IS_CREATED = '[PARTNER] IS CREATED';
export const PAIR_DEVICES_OPEN_DIALOG = '[PARTNER] PAIR DEVICES OPEN DIALOG';
export const PAIR_DEVICES_CLOSE_DIALOG = '[PARTNER] PAIR DEVICES CLOSE DIALOG';
export const PAIR_DEVICES_BY_SERIALS_REPORT = '[PARTNER] PAIR DEVICES BY SERIALS REPORT';
export const CLOSE_PAIR_DEVICE_BY_SERIAL_REPORT_DIALOG = '[PARTNER] CLOSE PAIR DEVICE BY SERIAL REPORT DIALOG';
export const OPEN_ADD_MANAGERS_DIALOG = '[PARTNER] OPEN ADD MANAGERS DIALOG';
export const CLOSE_ADD_MANAGERS_DIALOG = '[PARTNER] CLOSE ADD MANAGERS DIALOG';
export const CLOSE_OBJECT_DETAILS_DRAWER = '[PARTNER] CLOSE OBJECT DETAILS DRAWER';
export const OPEN_MOBILE_NETWORK_OPERATORS_DIALOG = '[PARTNER] OPEN MOBILE NETWORK OPERATORS DIALOG';
export const CLOSE_MOBILE_NETWORK_OPERATORS_DIALOG = '[PARTNER] CLOSE MOBILE NETWORK OPERATORS DIALOG';
export const EDIT_PLMN_OF_OBJECT = '[PARTNER] EDIT PLMN OF OBJECT';
export const GET_CURRENTLY_USED_OPERATORS = '[PARTNER] GET CURRENTLY USED OPERATORS';
export const OPEN_OBJECT_DETAILS_DRAWER = '[PARTNER] OPEN OBJECT DETAILS DRAWER';
export const SET_GOOGLE_API_LOADING = '[PARTNER] SET GOOGLE API LOADING';
export const SET_SELECTED_PARTNER_ID = '[PARTNER] SET SELECTED PARTNER ID';

export function getEntities(checkForCreatingEntities = false) {
    const request = axios.get('/api/facilities-statistics', {timeout: LONG_ENDPOINT_TIMEOUT_60_SECONDS});

    return (dispatch) => {
        request.then((response) => {
                if (checkForCreatingEntities) {
                    for (let object in response.data) {
                        if (response.data[object]['status'] !== 'createdAndCached') {
                            response.data[object]['intervalId'] = setInterval(() => {
                                dispatch(isCreated(response.data[object]['id']))
                            }, 1000);
                        }
                    }
                }
                return dispatch({
                    type: GET_ENTITIES,
                    payload: response.data
                })
            }
        );
    }
}

export function getCurrentlyUsedOperators(obid) {
    const request = axios.get('/api/getCurrentlyUsedOperators', {
        params: {
            selectedObid: obid
        }
    });

    return (dispatch) => {
        request.then((response) => {
                return dispatch({
                    type: GET_CURRENTLY_USED_OPERATORS,
                    payload: response.data
                })
            }
        );
    }
}

export function addEntity(facility) {
    const request = axios.post('/api/facilities', {
        ...facility
    }, {
        timeout: LONG_ENDPOINT_TIMEOUT_60_SECONDS
    });

    return (dispatch) => {
        dispatch(setFacilityCreationStatus(STATUS_LOADING))
        request.then((response) => {
            let intervalId = setInterval(() => {
                dispatch(isCreated(response.data.facilityId))
            }, 1000);

            dispatch(setInNewFacilityForm({
                key: "facilityCreationId",
                value: String(response.data.facilityId)
            }))

            const userId = store.getState().auth.user.userId;
            dispatch(setObjects(userId))

            return dispatch({
                type: ADD_ENTITY,
                facility,
                object_name: facility.name,
                id: response.data.facilityId,
                intervalId
            })
            }
        ).catch(() => {
            dispatch(setFacilityCreationStatus(STATUS_ERROR))
        })
    }
}


export function setGoogleApiLoading(value) {
    return {
        type: SET_GOOGLE_API_LOADING,
        payload: value
    }
}

export function openObjectDetailsDrawer(obid) {
    return {
        type: OPEN_OBJECT_DETAILS_DRAWER,
        obid
    }
}

export function closeObjectDetailsDrawer() {
    return {
        type: CLOSE_OBJECT_DETAILS_DRAWER,
    }
}

export function updateFacility(facility) {
    return (dispatch) => {
        let requestsPromises = []

        let migrationRequest
        if (facility.instance === INSTANCE_TYPE_ALPHA && !facility.earlyAdopter) {
            migrationRequest = axios.post('/api/objects/moveObjectToInfrastructure', {
                obid: facility.id,
                to_instance_type: INSTANCE_TYPE_CLOUD
            });
            facility.instance = INSTANCE_TYPE_CLOUD
        } else if (!(facility.instance === INSTANCE_TYPE_ALPHA) && facility.earlyAdopter) {
            migrationRequest = axios.post('/api/objects/moveObjectToInfrastructure', {
                obid: facility.id,
                to_instance_type: INSTANCE_TYPE_ALPHA
            });
            facility.instance = INSTANCE_TYPE_ALPHA
        }
        if (migrationRequest)
            requestsPromises.push(migrationRequest)


        const updateRequest = axios.patch(`/api/facilities/${facility.id}`, {
            ...facility,
        })

        requestsPromises.push(updateRequest)

        Promise.all(requestsPromises).then(() => {
            if (String(facility.id) === String(getCurrentSelectedObid()))
                dispatch(getSettings(facility.id))
            const userId = store.getState().auth.user.userId;
            dispatch(setObjects(userId))
        }).catch((error) => {
            console.error('Error during facility update: ', error);
        });

        return dispatch({
            type: UPDATE_ENTITY,
            entity: facility
        })
    }
}

export function editPlmnOfObject(obid, plmn) {
    return (dispatch) => {
        axios.patch(`/api/facilities/${obid}`, {
            plmn
        }).then(() => {
            dispatch(MessageActions.showMessage({
                message: I18n.t("The operator selection has been changed"),
                variant: 'success'
            }));
        })

        return dispatch({
            type: EDIT_PLMN_OF_OBJECT,
            obid, plmn
        })
    }
}

export function isCreated(obid) {
    const request = axios.get(`/api/facilities/${obid}/is-created`);

    return (dispatch) => {
        request.then((response) => {
                if (response.data !== null) {
                    dispatch(MessageActions.showMessage({
                        message: I18n.t("obidCreated", {obid: response.data})
                    }));
                    const userId = store.getState().auth.user.userId;
                    if (store.getState().partner.newFacilityForm.facilityCreationId === String(response.data))
                        dispatch(setFacilityCreationStatus(STATUS_SUCCESS))
                    dispatch(setObjects(userId))
                    return dispatch({
                        type: IS_CREATED,
                        payload: response.data
                    })
                }
            }
        );
    }
}

export function addManager(newEntity, facilityId) {
    return (dispatch) => {
        axios.post('/api/dashboard-users', {
            ...newEntity,
            facilityId: facilityId,
            selectedObid: getCurrentSelectedObid(),
        }).then((response) => {
                dispatch(showMessage({
                    message: I18n.t("Invitation sent"),
                    variant: "success",
                    autoHideDuration: 3000,
                }))
                dispatch(fetchFacilityManagersInvitations(facilityId, false))
            }
        ).catch((error) => {
            if ([
                ERROR_USER_ALREADY_HAS_PERMISSION,
                ERROR_USER_ALREADY_INVITED,
            ].includes(error?.response?.data?.error))
                dispatch(showMessage({
                    message: I18n.t(error.response.data.error),
                    variant: "warning",
                    autoHideDuration: 3000,
                }))
            else
                dispatch(showMessage({
                    message: I18n.t(UNRECOGNIZED_ERROR),
                    variant: "error",
                    autoHideDuration: 3000,
                }))
        })
    }
}

export function pairDeviceOpenDialog(obid) {
    return {
        type: PAIR_DEVICES_OPEN_DIALOG,
        obid
    }
}

export function openMobileNetworkOperatorsDialog(obid, country, simCards, plmn) {
    return {
        type: OPEN_MOBILE_NETWORK_OPERATORS_DIALOG,
        obid, country, simCards, plmn
    }
}

export function closeMobileNetworkOperatorsDialog() {
    return {
        type: CLOSE_MOBILE_NETWORK_OPERATORS_DIALOG,
    }
}

export function pairDeviceCloseDialog() {
    return {
        type: PAIR_DEVICES_CLOSE_DIALOG,
    }
}

export function openAddManagersDialog() {
    return {
        type: OPEN_ADD_MANAGERS_DIALOG,
    }
}

export function closeAddManagersDialog() {
    return {
        type: CLOSE_ADD_MANAGERS_DIALOG,
    }
}

export function setSearchText(value) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: value
    }
}

export function openEditFacilityDialog(data) {
    return {
        type: OPEN_EDIT_FACILITY_DIALOG,
        data
    }
}

export function closeEditFacilityDialog() {
    return {
        type: CLOSE_EDIT_FACILITY_DIALOG
    }
}

export function removeManagers(entityIds, facilityId) {
    const request = axios.post('/api/dashboardUsers/remove', {
        selectedObid: facilityId,
        uids: entityIds
    });

    return (dispatch) => {
        request.then(() => {
                dispatch(fetchFacilityManagers(facilityId, false))
                dispatch(fetchFacilityManagersInvitations(facilityId, false))
            }
        )
    };
}

export function closePairDeviceBySerialReportDialog() {
    return {
        type: CLOSE_PAIR_DEVICE_BY_SERIAL_REPORT_DIALOG
    }
}

export function setSelectedPartnerId(partnerId) {
    return {
        type: SET_SELECTED_PARTNER_ID,
        payload: partnerId
    }
}

export function pairDevicesBySerials(serials, partnerSelectedObid) {
    return (dispatch) => {
        axios.post('/api/serials/assignSerialsToObjectAndGetReport', {
            selectedObid: getCurrentSelectedObid(),
            targetObid: partnerSelectedObid,
            serials
        }).then((response) => {
            dispatch({
                type: PAIR_DEVICES_BY_SERIALS_REPORT,
                payload: response.data
            });
        })
    }
}
