export const PARTNER_TAB_FACILITIES = 'facilities'
export const PARTNER_TAB_RMA = 'rma'
export const PARTNER_FACILITIES_TAB_TABLE = 'table'
export const PARTNER_FACILITIES_TAB_MAP = 'map'

export const FACILITY_TYPE_STORE = "store"
export const FACILITY_TYPE_HOTEL = "hotel"
export const FACILITY_TYPE_OTHER = "other"

export const RETAIL_FACILITY_TYPES = [FACILITY_TYPE_STORE, FACILITY_TYPE_OTHER]
export const HOTELS_FACILITY_TYPES = [FACILITY_TYPE_HOTEL, FACILITY_TYPE_OTHER]

export const STREET = 'street'
export const STREET_NUMBER = 'streetNumber'
export const ZIP_CODE = 'zipCode'
export const CITY = 'locality'
export const COUNTRY = 'country'

export const ADDRESS_COMPONENTS = [STREET, STREET_NUMBER, CITY, ZIP_CODE, COUNTRY]
export const ADDRESS_COMPONENTS_POSSIBLE_GOOGLE_NAMES = {
    [STREET]: ["route"],
    [STREET_NUMBER]: ["street_number"],
    [CITY]: ["locality", "postal_town"],
    [ZIP_CODE]: ["postal_code"],
    [COUNTRY]: ["country"],
}