export const DEVICE_TYPE_WATCH = 'watch';
export const DEVICE_TYPE_WEBCHAT = 'webchat';
export const DEVICE_TYPE_UNRECOGNIZED = 'unrecognized';
export const DEVICE_TYPE_TABLET = 'tablet';
export const DEVICE_TYPE_BUTTON = 'button';

export function isNumber(serial) {
    return /^\d+$/.test(serial);
}

function isMacAddress(serial) {
    const regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    return regex.test(serial);
}

export function getDeviceTypeFromSerial(serial) {
    if (serial.length === 15 && isNumber(serial))
        return DEVICE_TYPE_WATCH
    if (serial.length < 3)
        return DEVICE_TYPE_WEBCHAT
    if (serial.length === 5 && serial.charAt(0) === 'C')
        return DEVICE_TYPE_TABLET
    if (isMacAddress(serial))
        return DEVICE_TYPE_TABLET
    if (serial.substring(0, 2) === "btn")
        return DEVICE_TYPE_BUTTON
    return DEVICE_TYPE_UNRECOGNIZED
}

export function getDeviceType(device) {
    if (!device)
        return null

    if (device.deviceType)
        return device.deviceType

    return device?.deviceShadowReported?.deviceType ?? getDeviceTypeFromSerial(device.serial);
}

export function filterByType(devices, typeToShow) {
    if (typeToShow === 'all')
        return devices;
    if (typeToShow === DEVICE_TYPE_WATCH)
        return devices && devices.filter(isWatch)
    if (typeToShow === DEVICE_TYPE_TABLET)
        return devices && devices.filter(isTablet)
    if (typeToShow === DEVICE_TYPE_WEBCHAT)
        return devices && devices.filter(isWebChat)
    if (typeToShow === DEVICE_TYPE_BUTTON)
        return devices && devices.filter(isButton)
    console.error("filterByType(), unhandled typeToShow:", typeToShow);
    return devices;
}

export function isWatch(device) {
    const deviceType = device?.deviceShadowReported?.deviceType ?? getDeviceType(device)
    return DEVICE_TYPE_WATCH === deviceType;
}

function isTablet(device) {
    const deviceType = device?.deviceShadowReported?.deviceType ?? getDeviceType(device)
    return DEVICE_TYPE_TABLET === deviceType;
}

export function isWebChat(device) {
    const deviceType = device?.deviceShadowReported?.deviceType ?? getDeviceType(device)
    return DEVICE_TYPE_WEBCHAT === deviceType;
}

export function isButton(device) {
    const deviceType = device?.deviceShadowReported?.deviceType ?? getDeviceType(device)
    return DEVICE_TYPE_BUTTON === deviceType;
}
