import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppContext from 'app/AppContext';
import FuseUtils from '@fuse/utils/FuseUtils';
import {matchRoutes} from "react-router-config";
import {bindActionCreators} from "redux";
import * as commonActions from "../../../app/store/actions";

class FuseAuthorization extends Component {
    constructor(props, context) {
        super(props);
        const {routes} = context;
        this.state = {
            accessGranted: true,
            routes
        };
    }

    componentDidMount() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    componentDidUpdate() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {location, userRole, userData, settings} = props;
        const {pathname} = location;

        const matched = matchRoutes(state.routes, pathname)[0];

        if (!(pathname === '/login' || pathname === '/forgotPassword' || pathname === '/newPassword' || pathname === '/invitation') && !userData.userId)
            return {accessGranted: false}

        return {
            accessGranted: matched ? FuseUtils.hasPermission(matched.route, userRole, settings) : true
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.accessGranted !== this.state.accessGranted;
    }

    getRoute(userRoles) {
        if (userRoles && userRoles.length > 0)
            return '/summary';
        else
            return false;
    }

    redirectRoute() {
        const {location, userRole, history} = this.props;
        const {pathname, state} = location;

        let redirectUrl;
        if (pathname && pathname === '/login')
            redirectUrl = this.getRoute(userRole);
        else
            redirectUrl = state && state.redirectUrl ? state.redirectUrl : this.getRoute(userRole);

        /*
        User is guest
        Redirect to Login Page
        */
        if (!userRole || userRole.length === 0 || redirectUrl === false) {
            if (pathname.includes("/addDevicesFromPackage")) {
                this.props.setPackageId(pathname.slice(pathname.length - 8))
            }
            if (pathname.includes("/addSerial")) {
                const pathnameSplitted = pathname.split('/')
                this.props.setSerialToAdd(pathnameSplitted[pathnameSplitted.length - 1])
            }
            history.push({
                pathname: '/login',
                state: {redirectUrl: pathname}
            });
        }
        /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
        else {
            history.push({
                pathname: redirectUrl
            });
        }
    }

    render() {
        return this.state.accessGranted ? <React.Fragment>{this.props.children}</React.Fragment> : null;
    }
}

function mapStateToProps({auth, settings}) {
    return {
        userRole: auth.user.currentRole,
        settings: settings?.settings,
        userData: auth.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            setPackageId: commonActions.setPackageId,
            setSerialToAdd: commonActions.setSerialToAdd,
        },
        dispatch);
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseAuthorization));
