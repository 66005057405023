import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import EnhancedTranslate from "../../../../../common-components/EnhancedTranslate";
import {Menu, MenuItem} from "@material-ui/core";
import {changeWatchUserAssignment} from "../../store/DevicesSlice";

export default function AssignedUserChip({serial, userId}) {
    const dispatch = useDispatch();

    const {users} = useSelector(({forSelect}) => forSelect.forSelect);
    const {assignedWatchUsers} = useSelector(({devices}) => devices.devices);

    const [anchorEl, setAnchorEl] = useState(null);

    function handleChangeAssignment(userId) {
        dispatch(changeWatchUserAssignment(userId, serial));
        handleClose();
    }

    function handleOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const user = useMemo(() => {
        return users.find((user) => user.id === userId);
    }, [users, userId]);

    const isAssigned = !(userId === null || userId === -1);
    const userName = `${user?.surname || ""} ${user?.name || ""}`.trim();

    return (
        <>
            <Chip
                label={isAssigned ? userName : <EnhancedTranslate value={"Any"}/>}
                deleteIcon={!isAssigned && <AddIcon/>}
                color={isAssigned ? 'secondary' : 'default'}
                onClick={handleOpen}
                onDelete={(event) =>
                    isAssigned ? handleChangeAssignment(-1) : handleOpen(event)
                }
            />


            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {users.length === 0 ? (
                    <div className={"px-8"}>
                        <EnhancedTranslate value={"Add at least one employee"}/>
                    </div>
                ) : (
                    users.map((user) => (
                        <MenuItem
                            key={user.id}
                            disabled={assignedWatchUsers.includes(user.id)}
                            onClick={() => handleChangeAssignment(user.id)}
                        >
                            {user.surname} {user.name}
                        </MenuItem>
                    ))
                )
                }
            </Menu>
        </>
    );
}
