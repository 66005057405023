import axios from "axios";
import {getCurrentSelectedObid} from "../../../../lib/getObid";

export const USERS = '[FOR SELECT] USERS';
export const JOB_TITLES = '[FOR SELECT] JOB TITLES';
export const SET_JOB_TITLES_LOADING = '[FOR SELECT] SET JOB TITLES LOADING';
export const JOB_TITLES_WITH_USERS = '[FOR SELECT] JOB TITLES WITH USERS';
export const GROUPS = '[FOR SELECT] GROUPS';
export const MOBILE_NETWORK_OPERATORS = '[FOR SELECT] MOBILE NETWORK OPERATORS';
export const MANAGERS = '[FOR SELECT] MANAGERS';
export const DEVICES = '[FOR SELECT] DEVICES';

export function getUsers() {
    const request = axios.get('/api/users/getUsersForSelect', {
        params: {
            selectedObid: getCurrentSelectedObid()
        }
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: USERS,
                payload: response.data
            })
        );
}

export function getMobileNetworkOperators() {
    const request = axios.get('/api/getMobileNetworkOperators');
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: MOBILE_NETWORK_OPERATORS,
                payload: response.data
            })
        );
}

export function getJobTitles() {
    return (dispatch) => {
        dispatch(setJobTitlesLoading(true));
        const request = axios.get('/api/jobTitles/getForSelect', {
            params: {
                selectedObid: getCurrentSelectedObid()
            }
        });

        request.then((response) =>
            dispatch({
                type: JOB_TITLES,
                payload: response.data
            })
        ).finally(() => {
            dispatch(setJobTitlesLoading(false));
        })
    }

}

export function setJobTitlesLoading(jobTitlesLoading) {
    return {
        type: SET_JOB_TITLES_LOADING,
        payload: jobTitlesLoading
    }
}

export function getJobTitlesWithUsers() {
    const request = axios.get('/api/jobTitles/withUsers', {
        params: {
            selectedObid: getCurrentSelectedObid()
        }
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: JOB_TITLES_WITH_USERS,
                payload: response.data
            })
        );
}

export function getGroups() {
    const request = axios.get('/api/objects/getGroups', {
        params: {
            selectedObid: getCurrentSelectedObid()
        }
    });
    return (dispatch) =>
        request.then((response) => {
                dispatch({
                    type: GROUPS,
                    payload: response.data
                })
            }
        );
}

export function getManagers(obid) {
    const request = axios.get('/api/dashboardUsers/list', {
        params: {
            selectedObid: obid
        }
    });
    return (dispatch) =>
        request.then((response) => {
                dispatch({
                    type: MANAGERS,
                    payload: response.data
                })
            }
        );
}

export function getDevices() {
    const request = axios.get(`/api/facilities/${getCurrentSelectedObid()}/devices`);
    return (dispatch) =>
        request.then((response) => {
                dispatch({
                    type: DEVICES,
                    payload: response.data
                })
            }
        );
}