import React, {useEffect} from 'react';
import {Button, CircularProgress} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {fetchInvitationLinkDetails, fetchIsAccountCompleted, setSelectedView, updateInvitations} from "../../auth/store/LoginSlice";
import EnhancedTranslate from "../../common-components/EnhancedTranslate";
import fullCwatchLogo from "../../../assets/fullCwatchLogo.jpg";
import {Alert} from "@material-ui/lab";
import LoginFooter from "../LoginFooter";
import GoBackToLoginButton from "../GoBackToLoginButton";
import {VIEW_COMPLETE_REGISTRATION_NEW_USER} from "../constants";
import Invitations from "../../fuse-layouts/shared-components/Invitations";
import {useForm} from "../../../@fuse/hooks";

const defaultFormState = {
    accepted: [],
    declined: [],
};

export default function InvitationView() {
    const dispatch = useDispatch()

    const {invitationLinkDetailsError, invitationLinkDetailsLoading, token, isAccountCompleted, isAccountCompletedLoading, invitationLinkDetails} = useSelector((state) => state.login)

    const {form, setInForm} = useForm(defaultFormState);

    useEffect(() => {
        dispatch(fetchIsAccountCompleted(token))
    }, [])

    useEffect(() => {
        if (isAccountCompleted !== null) {
            if (isAccountCompleted)
                dispatch(fetchInvitationLinkDetails(token))
            else
                dispatch(setSelectedView(VIEW_COMPLETE_REGISTRATION_NEW_USER));
        }
    }, [isAccountCompleted])

    function isLoading() {
        return invitationLinkDetailsLoading || isAccountCompletedLoading
    }

    function submit() {
        dispatch(updateInvitations(token, form.accepted, form.declined))
    }

    return (
        <div className="flex flex-col p-32 h-full">
            <GoBackToLoginButton/>
            <img className="w-256 m-32" src={fullCwatchLogo} alt="logo"/>

            {isLoading() ?
                <div className={"flex justify-center mt-32"}>
                    <CircularProgress color={"secondary"}/>
                </div>
                :
                <>
                    {invitationLinkDetailsError !== null ?
                        <Alert severity={"error"}>
                            <EnhancedTranslate value={invitationLinkDetailsError}/>
                        </Alert>
                        :
                        <>
                            <div className="flex flex-col h-full overflow-hidden">
                                <Invitations
                                    invitations={invitationLinkDetails?.invitations}
                                    onChangeAccepted={(newAccepted) => {
                                        setInForm("accepted", newAccepted)
                                    }}
                                    onChangeDeclined={(newDeclined) => {
                                        setInForm("declined", newDeclined)
                                    }}
                                />
                            </div>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={form.accepted.length === 0 && form.declined.length === 0}
                                onClick={submit}
                                className="w-full mx-auto mt-16"
                            >
                                <EnhancedTranslate value={'Confirm'}/>
                            </Button>
                        </>
                    }
                </>
            }
            <div className={"mt-8 flex-1"}/>
            <LoginFooter/>
        </div>
    );
}
