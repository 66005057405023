export const countries = [
    {
        "countryCode": "af",
        "dialCode": "93"
    },
    {
        "countryCode": "al",
        "dialCode": "355"
    },
    {
        "countryCode": "dz",
        "dialCode": "213"
    },
    {
        "countryCode": "ad",
        "dialCode": "376"
    },
    {
        "countryCode": "ao",
        "dialCode": "244"
    },
    {
        "countryCode": "ai",
        "dialCode": "1264"
    },
    {
        "countryCode": "ag",
        "dialCode": "1268"
    },
    {
        "countryCode": "ar",
        "dialCode": "54"
    },
    {
        "countryCode": "am",
        "dialCode": "374"
    },
    {
        "countryCode": "aw",
        "dialCode": "297"
    },
    {
        "countryCode": "au",
        "dialCode": "61"
    },
    {
        "countryCode": "at",
        "dialCode": "43"
    },
    {
        "countryCode": "az",
        "dialCode": "994"
    },
    {
        "countryCode": "bs",
        "dialCode": "1242"
    },
    {
        "countryCode": "bh",
        "dialCode": "973"
    },
    {
        "countryCode": "bd",
        "dialCode": "880"
    },
    {
        "countryCode": "bb",
        "dialCode": "1246"
    },
    {
        "countryCode": "by",
        "dialCode": "375"
    },
    {
        "countryCode": "be",
        "dialCode": "32"
    },
    {
        "countryCode": "bz",
        "dialCode": "501"
    },
    {
        "countryCode": "bj",
        "dialCode": "229"
    },
    {
        "countryCode": "bm",
        "dialCode": "1441"
    },
    {
        "countryCode": "bt",
        "dialCode": "975"
    },
    {
        "countryCode": "bo",
        "dialCode": "591"
    },
    {
        "countryCode": "ba",
        "dialCode": "387"
    },
    {
        "countryCode": "bw",
        "dialCode": "267"
    },
    {
        "countryCode": "br",
        "dialCode": "55"
    },
    {
        "countryCode": "bn",
        "dialCode": "673"
    },
    {
        "countryCode": "bg",
        "dialCode": "359"
    },
    {
        "countryCode": "bf",
        "dialCode": "226"
    },
    {
        "countryCode": "bi",
        "dialCode": "257"
    },
    {
        "countryCode": "kh",
        "dialCode": "855"
    },
    {
        "countryCode": "cm",
        "dialCode": "237"
    },
    {
        "countryCode": "ca",
        "dialCode": "1"
    },
    {
        "countryCode": "cv",
        "dialCode": "238"
    },
    {
        "countryCode": "ky",
        "dialCode": " 345"
    },
    {
        "countryCode": "cf",
        "dialCode": "236"
    },
    {
        "countryCode": "td",
        "dialCode": "235"
    },
    {
        "countryCode": "cl",
        "dialCode": "56"
    },
    {
        "countryCode": "cn",
        "dialCode": "86"
    },
    {
        "countryCode": "co",
        "dialCode": "57"
    },
    {
        "countryCode": "km",
        "dialCode": "269"
    },
    {
        "countryCode": "cg",
        "dialCode": "242"
    },
    {
        "countryCode": "cd",
        "dialCode": "243"
    },
    {
        "countryCode": "ck",
        "dialCode": "682"
    },
    {
        "countryCode": "cr",
        "dialCode": "506"
    },
    {
        "countryCode": "hr",
        "dialCode": "385"
    },
    {
        "countryCode": "cu",
        "dialCode": "53"
    },
    {
        "countryCode": "cy",
        "dialCode": "357"
    },
    {
        "countryCode": "cz",
        "dialCode": "420"
    },
    {
        "countryCode": "dk",
        "dialCode": "45"
    },
    {
        "countryCode": "dj",
        "dialCode": "253"
    },
    {
        "countryCode": "dm",
        "dialCode": "1767"
    },
    {
        "countryCode": "do",
        "dialCode": "1849"
    },
    {
        "countryCode": "ec",
        "dialCode": "593"
    },
    {
        "countryCode": "eg",
        "dialCode": "20"
    },
    {
        "countryCode": "sv",
        "dialCode": "503"
    },
    {
        "countryCode": "gq",
        "dialCode": "240"
    },
    {
        "countryCode": "er",
        "dialCode": "291"
    },
    {
        "countryCode": "ee",
        "dialCode": "372"
    },
    {
        "countryCode": "et",
        "dialCode": "251"
    },
    {
        "countryCode": "fo",
        "dialCode": "298"
    },
    {
        "countryCode": "fj",
        "dialCode": "679"
    },
    {
        "countryCode": "fi",
        "dialCode": "358"
    },
    {
        "countryCode": "fr",
        "dialCode": "33"
    },
    {
        "countryCode": "gf",
        "dialCode": "594"
    },
    {
        "countryCode": "pf",
        "dialCode": "689"
    },
    {
        "countryCode": "ga",
        "dialCode": "241"
    },
    {
        "countryCode": "gm",
        "dialCode": "220"
    },
    {
        "countryCode": "ge",
        "dialCode": "995"
    },
    {
        "countryCode": "de",
        "dialCode": "49"
    },
    {
        "countryCode": "gh",
        "dialCode": "233"
    },
    {
        "countryCode": "gi",
        "dialCode": "350"
    },
    {
        "countryCode": "gr",
        "dialCode": "30"
    },
    {
        "countryCode": "gl",
        "dialCode": "299"
    },
    {
        "countryCode": "gd",
        "dialCode": "1473"
    },
    {
        "countryCode": "gp",
        "dialCode": "590"
    },
    {
        "countryCode": "gu",
        "dialCode": "1671"
    },
    {
        "countryCode": "gt",
        "dialCode": "502"
    },
    {
        "countryCode": "gg",
        "dialCode": "44"
    },
    {
        "countryCode": "gn",
        "dialCode": "224"
    },
    {
        "countryCode": "gy",
        "dialCode": "595"
    },
    {
        "countryCode": "ht",
        "dialCode": "509"
    },
    {
        "countryCode": "va",
        "dialCode": "379"
    },
    {
        "countryCode": "hn",
        "dialCode": "504"
    },
    {
        "countryCode": "hk",
        "dialCode": "852"
    },
    {
        "countryCode": "hu",
        "dialCode": "36"
    },
    {
        "countryCode": "is",
        "dialCode": "354"
    },
    {
        "countryCode": "in",
        "dialCode": "91"
    },
    {
        "countryCode": "id",
        "dialCode": "62"
    },
    {
        "countryCode": "ir",
        "dialCode": "98"
    },
    {
        "countryCode": "iq",
        "dialCode": "964"
    },
    {
        "countryCode": "ie",
        "dialCode": "353"
    },
    {
        "countryCode": "im",
        "dialCode": "44"
    },
    {
        "countryCode": "il",
        "dialCode": "972"
    },
    {
        "countryCode": "it",
        "dialCode": "39"
    },
    {
        "countryCode": "jm",
        "dialCode": "1876"
    },
    {
        "countryCode": "jp",
        "dialCode": "81"
    },
    {
        "countryCode": "jo",
        "dialCode": "962"
    },
    {
        "countryCode": "kz",
        "dialCode": "77"
    },
    {
        "countryCode": "ke",
        "dialCode": "254"
    },
    {
        "countryCode": "ki",
        "dialCode": "686"
    },
    {
        "countryCode": "kp",
        "dialCode": "850"
    },
    {
        "countryCode": "kr",
        "dialCode": "82"
    },
    {
        "countryCode": "kw",
        "dialCode": "965"
    },
    {
        "countryCode": "kg",
        "dialCode": "996"
    },
    {
        "countryCode": "la",
        "dialCode": "856"
    },
    {
        "countryCode": "lv",
        "dialCode": "371"
    },
    {
        "countryCode": "lb",
        "dialCode": "961"
    },
    {
        "countryCode": "ls",
        "dialCode": "266"
    },
    {
        "countryCode": "lr",
        "dialCode": "231"
    },
    {
        "countryCode": "ly",
        "dialCode": "218"
    },
    {
        "countryCode": "li",
        "dialCode": "423"
    },
    {
        "countryCode": "lt",
        "dialCode": "370"
    },
    {
        "countryCode": "lu",
        "dialCode": "352"
    },
    {
        "countryCode": "mo",
        "dialCode": "853"
    },
    {
        "countryCode": "mk",
        "dialCode": "389"
    },
    {
        "countryCode": "mg",
        "dialCode": "261"
    },
    {
        "countryCode": "mw",
        "dialCode": "265"
    },
    {
        "countryCode": "my",
        "dialCode": "60"
    },
    {
        "countryCode": "mv",
        "dialCode": "960"
    },
    {
        "countryCode": "ml",
        "dialCode": "223"
    },
    {
        "countryCode": "mt",
        "dialCode": "356"
    },
    {
        "countryCode": "mh",
        "dialCode": "692"
    },
    {
        "countryCode": "mq",
        "dialCode": "596"
    },
    {
        "countryCode": "mr",
        "dialCode": "222"
    },
    {
        "countryCode": "mu",
        "dialCode": "230"
    },
    {
        "countryCode": "mx",
        "dialCode": "52"
    },
    {
        "countryCode": "fm",
        "dialCode": "691"
    },
    {
        "countryCode": "md",
        "dialCode": "373"
    },
    {
        "countryCode": "mc",
        "dialCode": "377"
    },
    {
        "countryCode": "mn",
        "dialCode": "976"
    },
    {
        "countryCode": "me",
        "dialCode": "382"
    },
    {
        "countryCode": "ms",
        "dialCode": "1664"
    },
    {
        "countryCode": "ma",
        "dialCode": "212"
    },
    {
        "countryCode": "mz",
        "dialCode": "258"
    },
    {
        "countryCode": "mm",
        "dialCode": "95"
    },
    {
        "countryCode": "na",
        "dialCode": "264"
    },
    {
        "countryCode": "nr",
        "dialCode": "674"
    },
    {
        "countryCode": "np",
        "dialCode": "977"
    },
    {
        "countryCode": "nl",
        "dialCode": "31"
    },
    {
        "countryCode": "nc",
        "dialCode": "687"
    },
    {
        "countryCode": "nz",
        "dialCode": "64"
    },
    {
        "countryCode": "ni",
        "dialCode": "505"
    },
    {
        "countryCode": "ne",
        "dialCode": "227"
    },
    {
        "countryCode": "ng",
        "dialCode": "234"
    },
    {
        "countryCode": "nf",
        "dialCode": "672"
    },
    {
        "countryCode": "mp",
        "dialCode": "1670"
    },
    {
        "countryCode": "no",
        "dialCode": "47"
    },
    {
        "countryCode": "om",
        "dialCode": "968"
    },
    {
        "countryCode": "pk",
        "dialCode": "92"
    },
    {
        "countryCode": "pw",
        "dialCode": "680"
    },
    {
        "countryCode": "ps",
        "dialCode": "970"
    },
    {
        "countryCode": "pa",
        "dialCode": "507"
    },
    {
        "countryCode": "pg",
        "dialCode": "675"
    },
    {
        "countryCode": "py",
        "dialCode": "595"
    },
    {
        "countryCode": "pe",
        "dialCode": "51"
    },
    {
        "countryCode": "ph",
        "dialCode": "63"
    },
    {
        "countryCode": "pl",
        "dialCode": "48"
    },
    {
        "countryCode": "pt",
        "dialCode": "351"
    },
    {
        "countryCode": "pr",
        "dialCode": "1939"
    },
    {
        "countryCode": "qa",
        "dialCode": "974"
    },
    {
        "countryCode": "ro",
        "dialCode": "40"
    },
    {
        "countryCode": "ru",
        "dialCode": "7"
    },
    {
        "countryCode": "rw",
        "dialCode": "250"
    },
    {
        "countryCode": "re",
        "dialCode": "262"
    },
    {
        "countryCode": "kn",
        "dialCode": "1869"
    },
    {
        "countryCode": "lc",
        "dialCode": "1758"
    },
    {
        "countryCode": "vc",
        "dialCode": "1784"
    },
    {
        "countryCode": "ws",
        "dialCode": "685"
    },
    {
        "countryCode": "sm",
        "dialCode": "378"
    },
    {
        "countryCode": "st",
        "dialCode": "239"
    },
    {
        "countryCode": "sa",
        "dialCode": "966"
    },
    {
        "countryCode": "sn",
        "dialCode": "221"
    },
    {
        "countryCode": "rs",
        "dialCode": "381"
    },
    {
        "countryCode": "sc",
        "dialCode": "248"
    },
    {
        "countryCode": "sl",
        "dialCode": "232"
    },
    {
        "countryCode": "sg",
        "dialCode": "65"
    },
    {
        "countryCode": "sk",
        "dialCode": "421"
    },
    {
        "countryCode": "si",
        "dialCode": "386"
    },
    {
        "countryCode": "sb",
        "dialCode": "677"
    },
    {
        "countryCode": "so",
        "dialCode": "252"
    },
    {
        "countryCode": "za",
        "dialCode": "27"
    },
    {
        "countryCode": "ss",
        "dialCode": "211"
    },
    {
        "countryCode": "es",
        "dialCode": "34"
    },
    {
        "countryCode": "lk",
        "dialCode": "94"
    },
    {
        "countryCode": "sd",
        "dialCode": "249"
    },
    {
        "countryCode": "sr",
        "dialCode": "597"
    },
    {
        "countryCode": "sj",
        "dialCode": "47"
    },
    {
        "countryCode": "sz",
        "dialCode": "268"
    },
    {
        "countryCode": "se",
        "dialCode": "46"
    },
    {
        "countryCode": "ch",
        "dialCode": "41"
    },
    {
        "countryCode": "sy",
        "dialCode": "963"
    },
    {
        "countryCode": "tw",
        "dialCode": "886"
    },
    {
        "countryCode": "tj",
        "dialCode": "992"
    },
    {
        "countryCode": "tz",
        "dialCode": "255"
    },
    {
        "countryCode": "th",
        "dialCode": "66"
    },
    {
        "countryCode": "tl",
        "dialCode": "670"
    },
    {
        "countryCode": "tg",
        "dialCode": "228"
    },
    {
        "countryCode": "tk",
        "dialCode": "690"
    },
    {
        "countryCode": "to",
        "dialCode": "676"
    },
    {
        "countryCode": "tt",
        "dialCode": "1868"
    },
    {
        "countryCode": "tn",
        "dialCode": "216"
    },
    {
        "countryCode": "tr",
        "dialCode": "90"
    },
    {
        "countryCode": "tm",
        "dialCode": "993"
    },
    {
        "countryCode": "tc",
        "dialCode": "1649"
    },
    {
        "countryCode": "tv",
        "dialCode": "688"
    },
    {
        "countryCode": "ug",
        "dialCode": "256"
    },
    {
        "countryCode": "ua",
        "dialCode": "380"
    },
    {
        "countryCode": "ae",
        "dialCode": "971"
    },
    {
        "countryCode": "gb",
        "dialCode": "44"
    },
    {
        "countryCode": "us",
        "dialCode": "1"
    },
    {
        "countryCode": "uy",
        "dialCode": "598"
    },
    {
        "countryCode": "uz",
        "dialCode": "998"
    },
    {
        "countryCode": "vu",
        "dialCode": "678"
    },
    {
        "countryCode": "ve",
        "dialCode": "58"
    },
    {
        "countryCode": "vn",
        "dialCode": "84"
    },
    {
        "countryCode": "ye",
        "dialCode": "967"
    },
    {
        "countryCode": "zm",
        "dialCode": "260"
    },
    {
        "countryCode": "zw",
        "dialCode": "263"
    }
]