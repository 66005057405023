import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {useSelector} from 'react-redux';
import withReducer from "../../../app/store/withReducer";
import reducer from "app/main/apps/settings/store/reducers/settings.reducer.js";


function FuseTheme(props) {
    const mainTheme = useSelector(({fuse}) => fuse.settings.mainTheme);

    return (
        <ThemeProvider theme={mainTheme}>
            {props.children}
        </ThemeProvider>
    )
}

export default withReducer('settings', reducer)(React.memo(FuseTheme));
