import React from 'react';
import {useSelector} from 'react-redux';
import LoginForm from "./views/LoginForm";
import CompleteRegistrationForm from "./views/CompleteRegistrationForm";
import {VIEW_COMPLETE_REGISTRATION_NEW_USER, VIEW_COMPLETE_REGISTRATION_OLD_USER, VIEW_INVITATION, VIEW_LOGIN, VIEW_NEW_PASSWORD_REQUEST, VIEW_SET_NEW_PASSWORD} from "./constants";
import NewPasswordRequestForm from "./views/NewPasswordRequestForm";
import NewPasswordForm from "./views/NewPasswordForm";
import InvitationView from "./views/InvitationView";


export default function LoginCard() {
    const {selectedView} = useSelector((state) => state.login)

    switch (selectedView) {
        case VIEW_LOGIN:
            return <LoginForm/>
        case VIEW_NEW_PASSWORD_REQUEST:
            return <NewPasswordRequestForm/>
        case VIEW_COMPLETE_REGISTRATION_NEW_USER:
        case VIEW_COMPLETE_REGISTRATION_OLD_USER:
            return <CompleteRegistrationForm/>
        case VIEW_SET_NEW_PASSWORD:
            return <NewPasswordForm/>
        case VIEW_INVITATION:
            return <InvitationView/>
        default:
            return <LoginForm/>
    }
}