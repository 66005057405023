import {useSelector} from "react-redux";
import {useEffect} from "react";

export default function useChangeFacility(effect, dependencies = []) {
    const {settings} = useSelector(({settings}) => settings);

    useEffect(() => {
        if (settings && Object.keys(settings).length > 0)
            effect()
    }, [settings, ...dependencies]);
}