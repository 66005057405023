import React from 'react';
import {IconButton, InputAdornment} from "@material-ui/core";
import EnhancedTextField from "../../../../common-components/EnhancedTextField";
import {useSelector} from "react-redux";
import useButtonText from "../hooks/useButtonText";
import {COLOR_BLUE_100} from "../../../../../lib/colors";
import {useSetting} from "../../../../custom-hooks/useSetting";
import {SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING} from "../../../../../lib/constants";
import {I18n} from "react-redux-i18n";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

export default function ButtonLabelTextField({textId, name}) {
    const {defaultButtonTranslations, buttonSettingsForm} = useSelector((state) => state.devicesSlice)

    const {isCustom, getValue, setValue, isInformationAboutAutoTranslationVisible} = useButtonText(textId)

    const [buttonLanguages] = useSetting(SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING, []);

    function getHelperText() {
        if (isInformationAboutAutoTranslationVisible()) {
            return buttonLanguages[0] === buttonSettingsForm.selectedLanguage ? I18n.t("main_lang_button_text_modified_helper_text") : I18n.t("other_lang_button_text_modified_helper_text")
        }
        return ""

    }

    return (
        <>
            <EnhancedTextField
                key={textId}
                label={name}
                placeholder={defaultButtonTranslations[buttonSettingsForm.selectedLanguage][textId]}
                focused={isInformationAboutAutoTranslationVisible()}
                color={isInformationAboutAutoTranslationVisible() ? "secondary" : "primary"}
                helperText={getHelperText()}
                FormHelperTextProps={{
                    style: {color: COLOR_BLUE_100}
                }}
                value={getValue()}
                InputLabelProps={{shrink: true}}
                className={"mt-32"}
                variant="outlined"
                onChange={(event) => {
                    setValue(event.target.value)
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <div>
                                <IconButton
                                    disabled={!isCustom()}
                                    onClick={() => {
                                        setValue(null)
                                    }}
                                    edge="end"
                                >
                                    <SettingsBackupRestoreIcon/>
                                </IconButton>
                            </div>
                        </InputAdornment>
                    ),
                }}
                fullWidth
            />
        </>
    )
}
