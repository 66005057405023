import _ from 'lodash';
import React, {useEffect, useRef} from "react";
import moment from "moment";
import {DATE_TIME_FORMAT, FACILITY_TYPE_AUTOMOTIVE, FACILITY_TYPE_RETAIL,} from "./constants";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import qs from "qs";
import * as Sentry from "@sentry/react";
import {getCurrentSelectedObid} from "./getObid";
import {I18n} from "react-redux-i18n";
import {FACILITY_TYPE_HOTEL, FACILITY_TYPE_OTHER, FACILITY_TYPE_STORE, HOTELS_FACILITY_TYPES, RETAIL_FACILITY_TYPES} from "../app/main/apps/partner/constants";
import HotelIcon from "@material-ui/icons/Hotel";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import StoreIcon from '@material-ui/icons/Store';


export function secondsToHoursString(seconds) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds - hours * 3600) / 60)
    return hours + I18n.t('hour short') + ' ' + minutes + I18n.t('minutes - short');
}

export function secondsToMinutesString(seconds) {
    const minutes = Math.floor(seconds / 60)
    if (minutes === 0)
        return '-'
    return minutes + I18n.t('minutes - short');
}

export function isObjectInGroup(objects, group) {
    try {
        return objects.find(x => String(x.id) === String(getCurrentSelectedObid())).group === group
    } catch (_) {
        return false
    }
}

export function HumanizedTimeElapsedFromEpoch(previous, current = Math.floor(new Date() / 1000)) {
    const msPerMinute = 60;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return I18n.t("lessThanMinute")
    } else if (elapsed < msPerHour) {
        let minutes = Math.round(elapsed / msPerMinute);
        return `${minutes} ${I18n.t("minute_" + polishPlural(minutes))} ${I18n.t("ago")}`
    } else if (elapsed < msPerDay) {
        let hours = Math.round(elapsed / msPerHour);
        return `${hours} ${I18n.t("hour_" + polishPlural(hours))} ${I18n.t("ago")}`
    } else if (elapsed < msPerMonth) {
        let days = Math.round(elapsed / msPerDay);
        return `${days} ${I18n.t("day_" + polishPlural(days))} ${I18n.t("ago")}`
    } else if (elapsed < msPerYear) {
        let months = Math.round(elapsed / msPerMonth);
        return `${months} ${I18n.t("month_" + polishPlural(months))} ${I18n.t("ago")}`
    } else {
        let years = Math.round(elapsed / msPerYear);
        return `${years} ${I18n.t("year_" + polishPlural(years))} ${I18n.t("ago")}`
    }
}

export function formatTimestamp(timestamp, milliseconds = false, format = 'YYYY-MM-DD HH:mm:ss') {
    return milliseconds ? moment.unix(Math.round(timestamp / 10000)).format(format) : moment.unix(timestamp).format(format);
}

export function printTimestampAsHour(timestamp, milliseconds = false) {
    return milliseconds ? moment.unix(Math.round(timestamp / 10000)).format('HH:mm') : moment.unix(timestamp).format('HH:mm');
}

export function containsOnlyWhitespace(string) {
    return !string.replace(/\s/g, '').length;

}

export function isTimestampMidnight(timestamp) {
    const date = new Date(timestamp * 1000);

    return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
}

export function isIpad() {
    const ua = navigator.userAgent;
    return /iPad/i.test(ua) || /iPhone OS 3_1_2/i.test(ua) || /Macintosh/i.test(ua)
}

export function getDayMondayFirst(date) {
    const day = date.getDay()
    return day === 0 ? 6 : day - 1;
}

// eslint-disable-next-line complexity
export function getDate(day, partOfDay) {
    let date = new Date();
    if (day === 'today') {
        date.setDate(date.getDate());
    } else if (day === 'yesterday') {
        date.setDate(date.getDate() - 1);
    } else if (day === 'thisMonday') {
        let day = date.getDay();
        let diff = date.getDate() - day + (day === 0 ? -6 : 1);
        date.setDate(diff);
    } else if (day === 'thisSunday') {
        let day = date.getDay();
        let diff = date.getDate() - day + (day === 0 ? -6 : 1) + 6;
        date.setDate(diff);
    } else if (day === 'lastMonday') {
        let day = date.getDay();
        let diff = date.getDate() - day + (day === 0 ? -6 : 1) - 7;
        date.setDate(diff);
    } else if (day === 'lastSunday') {
        let day = date.getDay();
        let diff = date.getDate() - day + (day === 0 ? -6 : 1) - 1;
        date.setDate(diff);
    } else if (day === 'startOfThisMonth') {
        date = new Date(date.getFullYear(), date.getMonth(), 1);
    } else if (day === 'endOfThisMonth') {
        date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else if (day === 'startOfLastMonth') {
        date = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    } else if (day === 'endOfLastMonth') {
        date = new Date(date.getFullYear(), date.getMonth(), 0);
    } else if (day === 'startOfThisYear') {
        date = new Date(date.getFullYear(), 0, 1);
    } else if (day === 'endOfThisYear') {
        date = new Date(date.getFullYear() + 1, 0, 0);
    } else if (day === 'startOfLastYear') {
        date = new Date(date.getFullYear() - 1, 0, 1);
    } else if (day === 'endOfLastYear') {
        date = new Date(date.getFullYear(), 0, 0);
    } else if (day === 'monthAgo') {
        date.setMonth(date.getMonth() - 1);
    } else if (day === 'aWeekAgo') {
        date.setDate(date.getDate() - 7);
    }

    if (partOfDay === 'start') {
        date.setHours(0, 0, 0, 0);
    } else if (partOfDay === 'end')
        date.setHours(23, 59, 59, 999);

    return date;
}

export function daysBetween(StartDate, EndDate, included = false, isUnixTimestamp = false) {
    const oneDay = 1000 * 60 * 60 * 24;

    if (isUnixTimestamp) {
        StartDate = new Date(StartDate * 1000);
        EndDate = new Date(EndDate * 1000);
    }

    const start = Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate());
    const end = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());

    let result = (start - end) / oneDay;

    if (included) {
        result += 1;
    }

    return result;
}

export function polishPlural(value) {
    if (value === 1) {
        return 1;
    } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
        return 2;
    } else {
        return 3;
    }
}

export function toggleValueInArray(array, value) {
    const index = array.indexOf(value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }

    return array;
}

export function getNowTimestamp() {
    return Math.floor((new Date()) / 1000);
}

export function percentage(partialValue, totalValue, decimalsCount) {
    if (totalValue === 0)
        return 0;
    return ((100 * partialValue) / totalValue).toFixed(decimalsCount);
}

export function getRandomColor(colorsArr = null) {
    if (colorsArr === null)
        colorsArr = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B'];

    return _.sample(colorsArr);
}

export function setSelectedOjectName(objectName) {
    localStorage.setItem("selectedObjectName", objectName);
}

export function getSelectedObjectName() {
    let selectedObjectName = localStorage.getItem("selectedObjectName")
    if (!selectedObjectName || selectedObjectName === 'undefined' || selectedObjectName === 'NaN')
        return null;
    return selectedObjectName;
}

export const TransitionComponent = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export function getQueryData() {
    return qs.parse(window.location.search, {ignoreQueryPrefix: true});
}

export function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function timestampToDate(timestamp) {
    const date = new Date(timestamp / 10);

    return moment(date).format(DATE_TIME_FORMAT);
}

export function sameDay(d1, d2, isUnixTimestamp = false, isTimeStampInMilliseconds = false) {
    if (isUnixTimestamp) {
        let multiplier = 1;
        if (!isTimeStampInMilliseconds) {
            multiplier = 1000;
        }
        d1 = new Date(d1 * multiplier);
        d2 = new Date(d2 * multiplier);
    }
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}

export function isOnline(d) {
    try {
        return parseInt(d.lastSeenTime) / 10000 + parseInt(d.maxSecsBetweenPings) > getNowTimestamp();
    } catch (e) {
        console.error("commonFunctions.isOnline(), exc:", e);
    }
}

export function isValidUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function isUrlHttps(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "https:";
}

export function getTimestampFromScreenshotFname(fname) {
    return fname.substr(0, fname.indexOf('.'));
}


export function errorLog(error, extraMessage) {
    let msg = `-----------------
    obid :  ${getCurrentSelectedObid()}
    name :  ${localStorage.getItem('fullname')}
    uid  :  ${localStorage.getItem('userId')}
    error:  ${error} ${extraMessage}
    `

    Sentry.captureException(error);
    axios.post('/api/dashboard_logger', {
        msg
    }).then(() => {
        console.error(msg);
    }).catch((err) => {
        console.error("couldn't send error to server")
        console.error(err);
    })
}

export function shortSerial(serial) {
    if (serial) {
        const short = serial.slice(-6);
        return short.substring(0, 3) + '-' + short.substring(3);
    }
    return '-'
}

export function isEnvDev() {
    return ['dev', 'dev8', 'hotels-dev'].includes(process.env.REACT_APP_INSTANCE_TYPE)
}

export function isInstanceHotels() {
    return ['hotels', 'hotels-dev'].includes(process.env.REACT_APP_INSTANCE_TYPE)
}

export function getFacilityTypes() {
    return isInstanceHotels() ? HOTELS_FACILITY_TYPES : RETAIL_FACILITY_TYPES;
}

export function downloadFileFromBlobResponse(responseData, fileName) {
    const url = window.URL.createObjectURL(new Blob([responseData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

export function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
}

export function containsLowercase(str) {
    return (/[a-z]/.test(str));
}

export function containsUppercase(str) {
    return (/[A-Z]/.test(str));
}

export function containsNumber(str) {
    return (/[0-9]/.test(str));
}

export function containsSpecialCharacter(str) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
}

export function getIconForFacilityType(type, color = "secondary", extraClasses = null) {
    switch (type) {
        case FACILITY_TYPE_STORE:
            return <StoreIcon color={color} className={extraClasses}/>
        case FACILITY_TYPE_HOTEL:
            return <HotelIcon color={color} className={extraClasses}/>
        case FACILITY_TYPE_AUTOMOTIVE:
            return <DriveEtaIcon color={color} className={extraClasses}/>
        case FACILITY_TYPE_RETAIL: // soon all retail types will be changed to 'store', so let them have same icon
            return <StoreIcon color={color} className={extraClasses}/>
        case FACILITY_TYPE_OTHER:
            return <BusinessCenterIcon color={color} className={extraClasses}/>
        default:
            return ""
    }
}