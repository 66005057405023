import React from 'react';
import {AppBar, Hidden} from '@material-ui/core';
import {FuseScrollbars} from '@fuse';
import clsx from 'clsx';
import UserNavbarHeader from 'app/fuse-layouts/shared-components/UserNavbarHeader';
import NavbarFoldedToggleButton from 'app/fuse-layouts/shared-components/NavbarFoldedToggleButton';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import {makeStyles} from '@material-ui/styles';
import newLogo from "../../../../assets/logocWatch2.png";
import EnhancedTranslate from "../../../common-components/EnhancedTranslate";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
    content: {
        overflowX: 'hidden',
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch',
        background: 'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 40px, 100% 10px',
        backgroundAttachment: 'local, scroll'
    },
    logoImg: {
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    }
}));

function NavbarLayout1(props) {
    const classes = useStyles();
    return (
        <div className={clsx("flex flex-col h-full", props.className)}>
            <AppBar
                color="primary"
                position="static"
                elevation={0}
                className="flex flex-row items-center flex-shrink h-64 min-h-64 pl-20 pr-12 app-bar"
            >

                <div className="flex flex-1 pr-8">
                    <div className={clsx("flex flex-col h-full", props.className)}>

                        <div className={'navbar'}>
                            <img alt={<EnhancedTranslate value={'Logo'}/>} className={clsx(classes.logoImg, 'logo')}
                                 style={{width: '49px', marginLeft: '100px', marginTop: '50px'}}
                                 src={newLogo}
                            />
                        </div>

                        <Hidden mdDown>
                                <span className="w-40 h-40 p-0">
                                </span>
                        </Hidden>

                        <Hidden lgUp>
                                <span className="w-40 h-40 p-0">
                                </span>
                        </Hidden>
                    </div>
                </div>

                <Hidden mdDown>
                    <NavbarFoldedToggleButton className="w-40 h-40 p-0"/>
                </Hidden>

                <Hidden lgUp>
                    <NavbarMobileToggleButton className="w-40 h-40 p-0">
                        <ArrowBackIcon/>
                    </NavbarMobileToggleButton>
                </Hidden>
            </AppBar>

            <FuseScrollbars
                className={clsx(classes.content)}
                option={{
                    suppressScrollX: true
                }}
            >
                <UserNavbarHeader/>

                <Navigation layout="vertical"/>

            </FuseScrollbars>
        </div>
    );
}

export default NavbarLayout1;


