import React, {useEffect, useRef, useState} from 'react';
import {Button, CardContent, CircularProgress, Fade, InputAdornment, Paper, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import EnhancedTranslate from "../../common-components/EnhancedTranslate";
import EmailIcon from "@material-ui/icons/Email";
import EnhancedTextField from "../../common-components/EnhancedTextField";
import fullCwatchLogo from "../../../assets/fullCwatchLogo.jpg";
import GoBackToLoginButton from "../GoBackToLoginButton";
import {fetchNewPasswordLinkDetails, setInNewPasswordForm, setNewPassword} from "../../auth/store/LoginSlice";
import LoginFooter from "../LoginFooter";
import {Alert} from "@material-ui/lab";
import useResize from "../../custom-hooks/useResize";
import PasswordsValidationRules from "../../fuse-layouts/shared-components/PasswordsValidationRules";
import Popper from "@material-ui/core/Popper";
import PasswordTextField from "../../common-components/TextFields/PasswordTextField";

export default function NewPasswordForm() {
    const dispatch = useDispatch();

    const {newPassword, token} = useSelector((state) => state.login)

    const {form, linkDetailsLoading, linkDetails, linkDetailsError} = newPassword
    const {email, password, passwordConfirmation} = form

    const passwordRulesPopperAnchor = useRef(null);
    const isPasswordRulesPopperOpen = useResize(1200);

    const [arePasswordsValid, setArePasswordsValid] = useState(false)

    useEffect(() => {
        dispatch(fetchNewPasswordLinkDetails(token))
    }, [])

    useEffect(() => {
        dispatch(setInNewPasswordForm({
            key: "email",
            value: linkDetails.userEmail,
        }));
    }, [linkDetails])

    function submit() {
        dispatch(setNewPassword(email, password, token));
    }

    function canBeSubmitted() {
        return !!email &&
            !!password &&
            !!passwordConfirmation &&
            arePasswordsValid
    }

    return (
        <CardContent className="flex flex-col items-center justify-center p-32 h-full">
            <GoBackToLoginButton/>
            <img className="w-256 mx-32" src={fullCwatchLogo} alt="logo"/>
            {linkDetailsLoading ?
                <div className={"flex justify-center mt-16"}>
                    <CircularProgress color={"secondary"}/>
                </div>
                :
                <div className={"mt-16"}>
                    {linkDetailsError !== null ?
                        <Alert severity={"error"}>
                            <EnhancedTranslate value={linkDetailsError}/>
                        </Alert>
                        :
                        <>
                            <Typography variant="h6" className="mt-16 mb-16 text-center">
                                <EnhancedTranslate value={'New Password'}/>
                            </Typography>

                            <div className="w-full">
                                <EnhancedTextField
                                    name={"email"}
                                    value={email}
                                    label={"Email"}
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                        form: {
                                            autoComplete: 'off',
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <EmailIcon className="text-20" color="action"/>
                                        </InputAdornment>
                                    }}
                                    onChange={(event) => {
                                        dispatch(setInNewPasswordForm({
                                            key: "email",
                                            value: event.target.value,
                                        }));
                                    }}
                                />

                                {!isPasswordRulesPopperOpen &&
                                    <div className={"mt-16"}>
                                        <PasswordsValidationRules
                                            password={password}
                                            passwordConfirmation={passwordConfirmation}
                                            setArePasswordsValid={setArePasswordsValid}
                                        />
                                    </div>
                                }

                                <PasswordTextField
                                    className="mt-16"
                                    label={"Password"}
                                    value={password}
                                    onChange={(newValue) => {
                                        dispatch(setInNewPasswordForm({
                                            key: "password",
                                            value: newValue,
                                        }));
                                    }}
                                />

                                <PasswordTextField
                                    className="mt-16"
                                    label={"Repeat password"}
                                    value={passwordConfirmation}
                                    onChange={(newValue) => {
                                        dispatch(setInNewPasswordForm({
                                            key: "passwordConfirmation",
                                            value: newValue,
                                        }));
                                    }}
                                />

                                <Popper
                                    open={isPasswordRulesPopperOpen}
                                    placement="right"
                                    disablePortal={false}
                                    anchorEl={passwordRulesPopperAnchor.current}
                                    transition
                                    modifiers={{
                                        flip: {
                                            enabled: false,
                                        },
                                        offset: {
                                            enabled: true,
                                            offset: '-300, 220',
                                        },
                                    }}
                                >
                                    {({TransitionProps}) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper>
                                                <div className="p-16">
                                                    <PasswordsValidationRules
                                                        password={password}
                                                        passwordConfirmation={passwordConfirmation}
                                                        setArePasswordsValid={setArePasswordsValid}
                                                    />
                                                </div>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="w-full mx-auto mt-16 mb-24"
                                    disabled={!canBeSubmitted()}
                                    onClick={submit}
                                >
                                    <EnhancedTranslate value={'Set'}/>
                                </Button>
                            </div>
                        </>
                    }

                </div>
            }

            <div
                ref={passwordRulesPopperAnchor}
            />
            <ToastContainer/>

            <div className={"flex-1"}/>
            <LoginFooter/>
        </CardContent>
    );
}