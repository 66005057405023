import React from 'react';
import {useTranslations} from "../custom-hooks/useTranslations";


export default function TranslationsWrapper({children}) {
    useTranslations()

    return (
        <>
            {children}
        </>
    );
}
