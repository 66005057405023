export const ERROR_INVITATION_EXPIRED = "INVITATION_EXPIRED"
export const ERROR_PERMISSION_ALREADY_GRANTED = "PERMISSION_ALREADY_GRANTED"
export const ERROR_NOT_AUTHORIZED = "NOT_AUTHORIZED"
export const ERROR_TERMS_OF_SERVICE_NOT_ACCEPTED = "TERMS_OF_SERVICE_NOT_ACCEPTED"
export const ERROR_ACCOUNT_NOT_COMPLETED = "ACCOUNT_NOT_COMPLETED"
export const ERROR_INVALID_TOKEN = "INVALID_TOKEN"
export const ERROR_LINK_ALREADY_USED = "LINK_ALREADY_USED"
export const ERROR_INVALID_EMAIL = "INVALID_EMAIL"

export const VIEW_LOGIN = "login"
export const VIEW_NEW_PASSWORD_REQUEST = "newPasswordRequest"
export const VIEW_COMPLETE_REGISTRATION_NEW_USER = "completeRegistrationNewUser"
export const VIEW_COMPLETE_REGISTRATION_OLD_USER = "completeRegistrationOldUser"
export const VIEW_SET_NEW_PASSWORD = "setNewPassword"
export const VIEW_INVITATION = "invitation"