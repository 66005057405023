import React from 'react';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import * as Actions from "app/main/apps/devices/store/actions";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import EnhancedTranslate from "../../../../../common-components/EnhancedTranslate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RemoveDeviceFromObjectDialog() {
    const dispatch = useDispatch();
    const removeDeviceFromObjectDialogOpen = useSelector(({devices}) => devices.devices.removeDeviceFromObjectDialogOpen);
    const selectedDeviceSerial = useSelector(({devices}) => devices.devices.selectedDeviceSerial);

    function closeComposeDialog() {
        dispatch(Actions.closeRemoveDeviceFromObjectDialog());
    }

    function removeDeviceFromObject() {
        dispatch(Actions.removeDeviceBySerial(selectedDeviceSerial));
        dispatch(Actions.closeRemoveDeviceFromObjectDialog());
        setTimeout(() => {
            dispatch(Actions.getDevices(false));
        }, 1000)
    }

    return (
        <Dialog
            open={removeDeviceFromObjectDialogOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeComposeDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title"><EnhancedTranslate
                value={'Do you want to remove device from object?'}/>
                <br/>
                <b>{selectedDeviceSerial}</b>
            </DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeComposeDialog} color="primary">
                    <EnhancedTranslate value={'No'}/>
                </Button>
                <Button onClick={removeDeviceFromObject} color="primary">
                    <EnhancedTranslate value={'Yes'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RemoveDeviceFromObjectDialog;
