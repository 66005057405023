import * as Actions from '../actions';
import _ from '@lodash';
import {RESET_STORE} from "../../../../../store/actions/reduxActions";

const initialState = {
    entities: null,
    searchText: '',
    routeParams: {},
    last30minActivity: null,
    loading: true,
    entityDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    removeJobTitleDialog: {
        open: false
    }
};

// eslint-disable-next-line complexity
const personnelReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ENTITIES: {
            for (let entity in action.payload) {//workaround for search bar
                action.payload[entity]['nameSurname'] = action.payload[entity]['name'] + " " + action.payload[entity]['surname']
                action.payload[entity]['surnameName'] = action.payload[entity]['surname'] + " " + action.payload[entity]['name']
            }
            return {
                ...state,
                entities: _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams,
                loading: false,
            };
        }
        case Actions.SET_LOADING_TRUE: {
            return {
                ...state,
                loading: true
            };
        }
        case Actions.SET_LOADING_FALSE: {
            return {
                ...state,
                loading: false
            };
        }
        case Actions.SET_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.ADD_ENTITY: {
            let newState = {...state.entities};

            newState[action.id] = {
                ...action.newEntity,
                id: action.id,
                disabled: null,
                isPasswordSet: "unset",
                lastActivity: null
            };

            return {
                ...state,
                entities: newState
            };
        }
        case Actions.UPDATE_ENTITY: {
            let newState = {...state.entities};

            newState[action.entity.id] = {
                ...action.entity,
            };

            return {
                ...state,
                entities: newState
            };
        }
        case Actions.OPEN_EDIT_ENTITY_DIALOG: {
            return {
                ...state,
                entityDialog: {
                    type: 'edit',
                    props: {
                        open: true
                    },
                    data: action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_ENTITY_DIALOG: {
            return {
                ...state,
                entityDialog: {
                    type: 'edit',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case RESET_STORE:
            return initialState
        default: {
            return state;
        }
    }
};

export default personnelReducer;
