import React from "react";
import PhoneInput from "react-phone-input-2";
import {countries} from "../../lib/countries";
import {I18n} from "react-redux-i18n";
import 'react-phone-input-2/lib/material.css'

export default function PhoneInputWithFlagsAndCountryCodes(props) {
    function getCountryPhoneLocalization() {
        let localizationObject = {}
        for (let country of countries) {
            localizationObject[country.countryCode] = I18n.t(country.countryCode.toUpperCase())
        }
        return localizationObject
    }

    return (
        <PhoneInput
            {...props}
            localization={getCountryPhoneLocalization()}
        />
    )
}
