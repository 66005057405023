import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import {Translate} from "react-redux-i18n";
import {useTranslatorMode} from "../custom-hooks/useTranslatorMode";


function EnhancedTranslate({value}) {
    const translatorModeEnabled = useSelector(({common}) => common.common.translatorModeEnabled);
    const [getTooltipContent] = useTranslatorMode()

    return (
        <Fragment>
            {translatorModeEnabled &&
                <Tooltip title={getTooltipContent(value)}>
                    <span>
                        <Translate value={value}/>
                    </span>
                </Tooltip>
            }
            {!translatorModeEnabled &&
                <Translate value={value}/>
            }
        </Fragment>
    );
}

export default EnhancedTranslate;
