import React from 'react';
import LanguagesSelect from "../common-components/Selects/TranslationsLanguagesSelect";
import PrivacyPolicyLink from "./PrivacyPolicyLink";


export default function LoginFooter() {

    return (
        <div className="flex justify-between w-full">
            <LanguagesSelect/>
            <PrivacyPolicyLink/>
        </div>
    );
}
