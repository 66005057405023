import axios from 'axios';
import jwtDecode from 'jwt-decode';
import FuseUtils from '@fuse/FuseUtils';

import {store} from "../../store/index";
import * as Actions from "../../auth/store/actions";
import * as MessageActions from "../../store/actions";
import * as Sentry from "@sentry/react";
import {I18n} from "react-redux-i18n";
import {ERROR_ACCOUNT_NOT_COMPLETED, ERROR_NOT_AUTHORIZED, ERROR_TERMS_OF_SERVICE_NOT_ACCEPTED} from "../../login/constants";
import {UNRECOGNIZED_ERROR} from "../../../lib/constants";


class jwtService extends FuseUtils.getEventEmitter() {

    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors() {
        axios.interceptors.response.use(response => {
            return response;
        }, err => {
            return new Promise(() => {
                if (err.response !== undefined) {
                    if ((err.response.status === 401 && err.config && !err.config.__isRetryRequest) || err.response.status === 403) {
                        // if you ever get an unauthorized response, logout the user
                        this.setObjectSession(null);
                        Actions.logoutUser()(store.dispatch);

                        if (localStorage.getItem('jwt_access_token_object') == null) {
                            this.emit('onAutoLogout', I18n.t("Logged out - session expired"));
                            localStorage.removeItem('jwt_access_token_object');
                        }
                        return
                    }
                } else {
                    console.error(err);
                }
                throw err;
            });
        });
    }

    handleAuthentication() {
        if (window.location.search.includes("token")) {
            this.emit('onAutoLogoutFromMailLink', I18n.t('Logged out - mail redirection detected'));
        }
        let access_token = this.getAccessToken();

        if (!access_token) {
            this.emit('onNoAccessToken');
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setObjectSession(access_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setObjectSession(null);
            this.emit('onAutoLogout', I18n.t('Logged out - session expired'));
        }
    }

    signInWithToken() {
        return new Promise((resolve, reject) => {
            axios.get('/api/dashboard-users/jwt-login', {
                users: {
                    token: this.getAccessToken()
                }
            }).then(response => {
                if (response.data && response.data.lastUsedFacility) {
                    const {access_token, name, surname, userId, email} = response.data
                    this.setObjectSession(access_token);
                    localStorage.setItem('fullname', name + " " + surname)
                    localStorage.setItem("userId", userId);

                    //Logout users if they have old jwt token without new properties
                    //New login will create fresh jwt token with new properties
                    //This can be removed if all users will have new tokens (around week after deploying this to prod)
                    if (!response.data.lastUsedFacility) {
                        Actions.logoutUser()(store.dispatch);
                        reject("Logout reason: new version update, please login again");
                    }

                    Sentry.setUser({id: userId, username: name + " " + surname, email: email});
                    resolve(response.data);
                } else {
                    Actions.logoutUser()(store.dispatch);
                    reject(response.data.error);
                }
            }).catch((err) => {
                console.error(err)
                reject(`unknown error: ${err}`);
            })
        });
    }

    login(email, password) {
        return new Promise((resolve, reject) => {
            axios.post('/api/dashboard-users/login', {
                email,
                password,
            }).then(response => {
                if (Object.prototype.hasOwnProperty.call(response.data, 'error')) {
                    reject(response.data.error);
                    return;
                }
                if (Object.prototype.hasOwnProperty.call(response.data, 'token')) {
                    const {token, name, surname, userId, email} = response.data
                    this.setObjectSession(token);
                    localStorage.setItem("fullname", name + " " + surname);
                    localStorage.setItem("userId", userId);

                    Sentry.setUser({id: userId, username: name + " " + surname, email: email});
                    store.dispatch(MessageActions.showMessage({message: `${I18n.t('loginSuccessObject')}`}));

                    resolve(response.data);
                } else {
                    reject(response.data.error);
                }
            }).catch((error) => {
                if ([
                    ERROR_NOT_AUTHORIZED,
                    ERROR_TERMS_OF_SERVICE_NOT_ACCEPTED,
                    ERROR_ACCOUNT_NOT_COMPLETED,
                ].includes(error?.response?.data?.error))
                    reject(error.response.data.error)
                else
                    reject(UNRECOGNIZED_ERROR)
            });
        });
    }

    setObjectSession(access_token) {
        if (access_token) {
            localStorage.setItem('jwt_access_token_object', access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        } else {
            localStorage.removeItem('jwt_access_token_object');
            delete axios.defaults.headers.common['Authorization'];
        }
    }

    logout() {
        this.setObjectSession(null);
    }

    isAuthTokenValid(access_token) {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        return decoded.exp >= currentTime;
    }

    getAccessToken() {
        return window.localStorage.getItem('jwt_access_token_object');
    }
}

const instance = new jwtService();

export default instance;
