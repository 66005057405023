import {isEnvDev} from "../../../lib/commonFunctions";

class multidashboardService {
    constructor() {
        this.currentEnv = window.location.pathname.split('/')[1];
        this.INDEPENDENT_PATHS = ["partner"]
    }

    handleChange(newEnv, force = false) {
        if (!force && this.INDEPENDENT_PATHS.some(path => window.location.pathname.includes(path)))
            return false

        if (this.currentEnv !== newEnv) {
            const url = new URL(window.location.href);
            const pathSegments = url.pathname.split('/')
            pathSegments[1] = newEnv;
            const newPathname = pathSegments.join('/')
            window.location.href = `${url.protocol}//${url.host}${newPathname}${url.search}`
            return true
        }
        return false
    }

    goToMainDashboard() {
        return this.handleChange(isEnvDev() ? "dev" : "c", true);
    }
}

const instance = new multidashboardService();

export default instance;
