import {combineReducers} from 'redux';
import fuse from './fuse';
import common from './common';
import forSelect from './forSelect';
import shadowService from './shadowService';
import auth from 'app/auth/store/reducers';
import {i18nReducer} from 'react-redux-i18n';
import predefinedMsg from "../../main/apps/communication/predefinedMessages/store/reducers/predefinedMessages.reducer";
import personnelReducer from "../../main/apps/personnel/store/reducers/personnel.reducer"
import jobs from "../../main/apps/jobTitles/store/reducers/jobtitle.reducer"
import channelsNew from "../../main/apps/channels/store/reducers/channels.reducer";
import partnerReducer from "../../main/apps/partner/store/reducers/index";
import personnelOnline from "../../main/apps/personnelOnline/store/reducers/personnelOnline.reducer";
import devices from "../../main/apps/devices/store/reducers/index";
import accessReducer from "../../main/apps/dashboardAccess/store/reducers/index";
import rmaSlice from "../../main/apps/rma/store/rmaSlice";
import objectsSlice from "./common/objectsSlice";
import partnerSlice from "../../main/apps/partner/store/PartnerSlice";
import devicesSlice from "../../main/apps/devices/store/DevicesSlice";
import loginSlice from "../../auth/store/LoginSlice";
import userSlice from "../../auth/store/UserSlice";

const createReducer = (asyncReducers) =>
    combineReducers({
            auth,
            fuse,
            common,
            forSelect,
            shadowService,
            ...asyncReducers,
            i18n: i18nReducer,
            predefinedMsg,
            personnelReducer,
            jobs,
            channelsNew,
            personnelOnline,
            access: accessReducer,
            partnerReducer,
            devices,
            rma: rmaSlice,
            objects: objectsSlice,
            partner: partnerSlice,
            login: loginSlice,
            user: userSlice,
            devicesSlice: devicesSlice
    });

export default createReducer;
