import {FuseUtils} from '@fuse';
import navigationConfig from 'app/fuse-configs/navigationConfig';

export const SET_NAVIGATION = '[NAVIGATION] SET NAVIGATION';
export const RESET_NAVIGATION = '[NAVIGATION] RESET NAVIGATION';
export const APPLY_PERMISSIONS_TO_NAVIGATION = '[NAVIGATION] APPLY PERMISSIONS TO NAVIGATION';


export function applyPermissionsToNavigation(settings = null) {
    return (dispatch, getState) => {
        const state = getState()

        return dispatch({
            type: APPLY_PERMISSIONS_TO_NAVIGATION,
            role: state.auth.user.currentRole,
            obid: state.common.common.obid,
            settings: settings || state.settings.settings
        });
    }
}

export function setNavigation(navigation) {
    return {
        type: SET_NAVIGATION,
        navigation
    }
}

export function resetNavigation() {
    return {
        type: RESET_NAVIGATION
    }
}

export function appendNavigationItem(item, parentId) {
    return (dispatch, getState) => {
        const {navigation} = getState().fuse;
        return dispatch({
            type: SET_NAVIGATION,
            navigation: FuseUtils.appendNavItem(navigation, item, parentId)
        });
    }
}

export function prependNavigationItem(item, parentId) {
    return (dispatch, getState) => {
        const {navigation} = getState().fuse;
        return dispatch({
            type: SET_NAVIGATION,
            navigation: FuseUtils.prependNavItem(navigation, item, parentId)
        });
    }
}

export function updateNavigationItem(id, item) {
    return (dispatch, getState) => {
        const {navigation} = getState().fuse;
        return dispatch({
            type: SET_NAVIGATION,
            navigation: FuseUtils.updateNavItem(navigation, id, item)
        });
    }
}

export function removeNavigationItem(id) {
    return (dispatch, getState) => {
        const {navigation} = getState().fuse;
        return dispatch({
            type: SET_NAVIGATION,
            navigation: FuseUtils.removeNavItem(navigation, id)
        });
    }
}

function findNavigation(initialNavigation, id) {
    for (let navigationItem of initialNavigation) {
        if (navigationItem.id === id) {
            return navigationItem
        } else {
            if (navigationItem.children) {
                for (let navigationItemChild of navigationItem.children) {
                    if (navigationItemChild.id === id)
                        return navigationItemChild;
                }
            }

        }
    }
}

export function toggleNavigationItem(id, parentId, toggle) {
    return (dispatch, getState) => {
        const {navigation} = getState().fuse;
        let initialNavigation = [...navigationConfig];
        let navigationItem = findNavigation(initialNavigation, id);

        if (toggle) {
            for (let item in navigation) {
                if (navigation[item].id === parentId) {
                    navigation[item].children.push(navigationItem);
                }
            }
            return dispatch({
                type: SET_NAVIGATION,
                navigation: navigation
            });
        } else {
            if (navigationItem)
                return dispatch({
                    type: SET_NAVIGATION,
                    navigation: FuseUtils.removeNavItem(navigation, id)
                });
        }
    }
}
