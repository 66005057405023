import * as Actions from 'app/store/actions/common';
import {RESET_STORE} from "../../actions/reduxActions";

const initialState = {
    obid: null,
    objectName: null,
    error: false,
    objects: [],
    translatorModeEnabled: false,
    simpleLocalizeTranslations: null,
    packageId: null,
    serialToAdd: null,
    package: null,
    serialWithAssignments: null,
    isApiDown: false,
    selectedLanguagesToDisplayInTranslatorMode: []
};

const common = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_OBID: {
            return {
                ...state,
                obid: action.payload,
            };
        }
        case Actions.SET_IS_API_DOWN: {
            return {
                ...state,
                isApiDown: action.payload,
            };
        }
        case Actions.SET_OBJECT_NAME: {
            return {
                ...state,
                objectName: action.payload,
            };
        }
        case Actions.SET_PACKAGE_ID: {
            return {
                ...state,
                packageId: action.payload,
            };
        }
        case Actions.SET_SERIAL_TO_ADD: {
            return {
                ...state,
                serialToAdd: action.payload,
            };
        }
        case Actions.GET_PACKAGE_BY_ID: {
            return {
                ...state,
                package: action.payload,
            };
        }
        case Actions.GET_SERIAL_WITH_ASSIGNMENTS: {
            return {
                ...state,
                serialWithAssignments: action.payload,
            };
        }
        case Actions.SET_ERROR_TRUE: {
            return {
                ...state,
                error: true,
            };
        }
        case Actions.SET_SELECTED_LANGUAGES_TO_DISPLAY_IN_TRANSLATOR_MODE: {
            return {
                ...state,
                selectedLanguagesToDisplayInTranslatorMode: action.payload,
            };
        }
        case Actions.SET_ERROR_FALSE: {
            return {
                ...state,
                error: false,
            };
        }
        case Actions.SET_TRANSLATOR_MODE_ON: {
            return {
                ...state,
                translatorModeEnabled: true,
                simpleLocalizeTranslations: action.payload
            };
        }
        case Actions.SET_TRANSLATOR_MODE_OFF: {
            return {
                ...state,
                translatorModeEnabled: false,
                simpleLocalizeTranslations: null,
            };
        }
        case Actions.SET_OBJECTS: {
            return {
                ...state,
                objects: action.payload,
            };
        }
        case RESET_STORE:
            return initialState
        default:
            return state;
    }
};

export default common;