import {useSelector} from "react-redux";
import {ROLE_SERVICE, ROLE_VIEW_ONLY} from "../../lib/constants";


export function useRoles() {
    const {currentRole, partner} = useSelector(({auth}) => auth.user);

    function hasPermissionToCreateEditRemove() {
        return currentRole !== ROLE_VIEW_ONLY
    }

    function isService() {
        return currentRole === ROLE_SERVICE
    }

    function isPartner() {
        return !!partner
    }

    return {
        hasPermissionToCreateEditRemove: hasPermissionToCreateEditRemove(),
        isPartner: isPartner(),
        isService: isService()
    }
}