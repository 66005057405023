import React from 'react';
import {Button, CardContent, InputAdornment, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import EmailIcon from "@material-ui/icons/Email";
import EnhancedTextField from "../../common-components/EnhancedTextField";
import {sendRequestForNewPassword, setInNewPasswordRequestForm} from "../../auth/store/LoginSlice";
import EnhancedTranslate from "../../common-components/EnhancedTranslate";
import newLogo from "../../../assets/fullCwatchLogo.jpg";
import GoBackToLoginButton from "../GoBackToLoginButton";
import LoginFooter from "../LoginFooter";

export default function NewPasswordRequestForm() {
    const dispatch = useDispatch();

    const {newPasswordRequestForm} = useSelector((state) => state.login)
    const i18n = useSelector(({i18n}) => i18n);

    const {email} = newPasswordRequestForm

    return (
        <CardContent className="flex flex-col items-center justify-center p-32 h-full">
            <GoBackToLoginButton/>
            <img className="w-256 m-32" src={newLogo} alt="logo"/>
            <Typography variant="h6" className="mt-16 mb-32">
                <EnhancedTranslate value={'New Password'}/>
            </Typography>
            <div className="flex flex-col justify-center w-full">
                <EnhancedTextField
                    name={"email"}
                    value={email}
                    label={"Email"}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <EmailIcon className="text-20" color="action"/>
                        </InputAdornment>
                    }}
                    onChange={(event) => {
                        dispatch(setInNewPasswordRequestForm({
                            key: "email",
                            value: event.target.value
                        }))
                    }}
                />

                <Button
                    type="button"
                    variant="contained"
                    onClick={() => dispatch(sendRequestForNewPassword(email, i18n.locale))}
                    color="secondary"
                    className="w-full mx-auto mt-16 mb-24"
                >
                    <EnhancedTranslate value={'Set new password'}/>

                </Button>

                <ToastContainer/>
            </div>
            <div className={"flex-1"}/>
            <LoginFooter/>
        </CardContent>
    );
}
