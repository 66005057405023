import {createSlice} from '@reduxjs/toolkit';
import history from '@history';
import axios from "axios";
import {setUserData} from "./actions";
import {
    ERROR_INVALID_EMAIL,
    ERROR_INVALID_TOKEN,
    ERROR_INVITATION_EXPIRED,
    ERROR_LINK_ALREADY_USED,
    ERROR_PERMISSION_ALREADY_GRANTED,
    ERROR_TERMS_OF_SERVICE_NOT_ACCEPTED,
    VIEW_COMPLETE_REGISTRATION_OLD_USER,
    VIEW_LOGIN
} from "../../login/constants";
import {RESET_STORE} from "../../store/actions/reduxActions";
import {toast} from "react-toastify";
import {I18n} from "react-redux-i18n";
import jwtService from "../../services/jwtService";
import {UNRECOGNIZED_ERROR} from "../../../lib/constants";
import {showMessage} from "../../store/actions";

export const initialState = {
    success: false,
    token: null,
    isAccountCompleted: null,
    isAccountCompletedLoading: false,
    selectedView: VIEW_LOGIN,
    loginError: null,
    loginForm: {
        email: "",
        password: ""
    },
    completeRegistrationForm: {
        name: "",
        email: "",
        surname: "",
        phoneNum: "",
        password: "",
        passwordConfirmation: "",
        language: "",
        termsOfServiceAccepted: false,
        marketingAgreement: false,
    },
    newPasswordRequestForm: {
        email: "",
        error: null
    },
    newPassword: {
        linkDetailsLoading: false,
        linkDetails: {},
        linkDetailsError: null,
        form: {
            email: "",
            password: "",
            passwordConfirmation: "",
            token: "",
        }
    },
    invitationLinkDetails: {
        invitations: []
    },
    invitationLinkDetailsError: null,
    invitationLinkDetailsLoading: false,
    informationAboutTermsOfServiceDialogOpen: false,
};

export const fetchInvitationLinkDetails = (token) => async (dispatch) => {
    dispatch(setInvitationLinkDetailsLoading(true))
    axios.get(`/api/dashboard-users/invitation-link-details`, {
        params: {
            token
        }
    }).then((response) => {
        dispatch(setInvitationLinkDetails(response.data))
    }).catch((error) => {
        if ([
            ERROR_INVITATION_EXPIRED,
            ERROR_PERMISSION_ALREADY_GRANTED
        ].includes(error?.response?.data?.error))
            dispatch(setInvitationLinkDetailsError(error.response.data.error))
        else
            dispatch(setInvitationLinkDetailsError(UNRECOGNIZED_ERROR))
    }).finally(() => {
        dispatch(setInvitationLinkDetailsLoading(false))
    })
};

export const updateInvitations = (token, accepted, declined) => async (dispatch) => {
    axios.post(`/api/dashboard-users/invitations`, {
        token,
        acceptedFacilitiesIds: accepted,
        declinedFacilitiesIds: declined,
    }).then((response) => {
        dispatch({
            type: RESET_STORE
        })
        dispatch(showMessage({
            message: I18n.t("Invitations updated, you can now log in"),
            variant: 'success'
        }));
        history.push({pathname: '/login'});
    })
};

export const fetchIsAccountCompleted = (token) => async (dispatch) => {
    dispatch(setIsAccountCompletedLoading(true))
    axios.get(`/api/dashboard-users/is-account-completed`, {
        params: {
            token
        }
    }).then((response) => {
        dispatch(setIsAccountCompleted(response.data))
    }).catch((error) => {
        if ([
            ERROR_INVALID_TOKEN,
        ].includes(error?.response?.data?.error))
            dispatch(setInvitationLinkDetailsError(error.response.data.error))
        else
            dispatch(setInvitationLinkDetailsError(UNRECOGNIZED_ERROR))
    }).finally(() => {
        dispatch(setIsAccountCompletedLoading(false))
    })
};

export const fetchNewPasswordLinkDetails = (token) => async (dispatch) => {
    dispatch(setNewPasswordLinkDetailsLoading(true))
    axios.get(`/api/dashboard-users/new-password-link-details`, {
        params: {
            token
        }
    }).then((response) => {
        dispatch(setNewPasswordLinkDetails(response.data))
    }).catch((error) => {
        if ([
            ERROR_INVALID_TOKEN,
            ERROR_LINK_ALREADY_USED,
        ].includes(error?.response?.data?.error)) {
            dispatch(setNewPasswordLinkDetailsError(error.response.data.error))
        } else
            dispatch(setNewPasswordLinkDetailsError(UNRECOGNIZED_ERROR))
    }).finally(() => {
        dispatch(setNewPasswordLinkDetailsLoading(false))
    })
};

export const sendRequestForNewPassword = (email, fallbackLanguage) => async (dispatch) => {
    axios.post('/api/dashboard-users/forgot-password', {email, fallbackLanguage})
        .then(() => {
            toast(I18n.t(`Email sent, please check your inbox`))
        }).catch(error => {
        if ([
            ERROR_INVALID_EMAIL,
            ERROR_TERMS_OF_SERVICE_NOT_ACCEPTED,
        ].includes(error?.response?.data?.error)) {
            toast(I18n.t(`newPasswordRequestFormError_${error.response.data.error}`))
        } else
            toast(I18n.t(UNRECOGNIZED_ERROR))
    });
};

export const setNewPassword = (email, password, token) => async (dispatch) => {
    axios.post('/api/dashboard-users/set-new-password', {
        email,
        token,
        password
    }).then((response) => {
        toast(I18n.t("Password set"))
    })
};

export const login = (email, password) => async (dispatch) => {
    jwtService.login(email, password)
        .then(data => {
                dispatch(setUserData(data));
                dispatch(setLoginSuccess());
            }
        )
        .catch(error => {
            if (error === ERROR_TERMS_OF_SERVICE_NOT_ACCEPTED) {
                dispatch(setInCompleteRegistrationForm({
                    key: "email",
                    value: email
                }))
                dispatch(setInCompleteRegistrationForm({
                    key: "password",
                    value: password
                }))
                dispatch(setSelectedView(VIEW_COMPLETE_REGISTRATION_OLD_USER))
            } else {
                dispatch(setLoginError(error));
            }
        });
};

export const completeRegistration = (form) => async (dispatch) => {
    axios.post(`/api/dashboard-users/complete-registration`, form)
        .then((response) => {
            dispatch({
                type: RESET_STORE
            })
            dispatch(login(form.email, form.password))
            history.push({pathname: '/login'});
        })
};

const devicesSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setInLoginForm: (state, action) => {
            state.loginForm[action.payload.key] = action.payload.value
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setIsAccountCompleted: (state, action) => {
            state.isAccountCompleted = action.payload
        },
        setIsAccountCompletedLoading: (state, action) => {
            state.isAccountCompletedLoading = action.payload
        },
        setLoginError: (state, action) => {
            state.loginError = action.payload
        },
        setInCompleteRegistrationForm: (state, action) => {
            state.completeRegistrationForm[action.payload.key] = action.payload.value
        },
        setInNewPasswordForm: (state, action) => {
            state.newPassword.form[action.payload.key] = action.payload.value
        },
        setInNewPasswordRequestForm: (state, action) => {
            state.newPasswordRequestForm[action.payload.key] = action.payload.value
        },
        setInvitationLinkDetailsLoading: (state, action) => {
            state.invitationLinkDetailsLoading = action.payload
        },
        setInvitationLinkDetails: (state, action) => {
            state.invitationLinkDetails = action.payload
        },
        setNewPasswordLinkDetailsLoading: (state, action) => {
            state.newPassword.linkDetailsLoading = action.payload
        },
        setNewPasswordLinkDetails: (state, action) => {
            state.newPassword.linkDetails = action.payload
        },
        setInvitationLinkDetailsError: (state, action) => {
            state.invitationLinkDetailsError = action.payload
        },
        setNewPasswordLinkDetailsError: (state, action) => {
            state.newPassword.linkDetailsError = action.payload
        },
        setNewPasswordRequestFormError: (state, action) => {
            state.newPasswordRequestForm.error = action.payload
        },
        setSelectedView: (state, action) => {
            state.selectedView = action.payload
        },
        setInformationAboutTermsOfServiceDialogOpen: (state, action) => {
            state.informationAboutTermsOfServiceDialogOpen = action.payload
        },
        setLoginSuccess: (state, action) => {
            state.success = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, (state) => {
            return {...initialState};
        });
    }
});
export const {
    setInLoginForm,
    setToken,
    setInCompleteRegistrationForm,
    setIsAccountCompleted,
    setIsAccountCompletedLoading,
    setInNewPasswordForm,
    setNewPasswordLinkDetailsLoading,
    setInvitationLinkDetailsLoading,
    setInvitationLinkDetails,
    setNewPasswordRequestFormError,
    setNewPasswordLinkDetails,
    setInvitationLinkDetailsError,
    setNewPasswordLinkDetailsError,
    setLoginError,
    setInformationAboutTermsOfServiceDialogOpen,
    setInNewPasswordRequestForm,
    setSelectedView,
    setLoginSuccess,
} = devicesSlice.actions;
export default devicesSlice.reducer;