import React, {useEffect, useState} from 'react';
import {setSelectedOjectName} from "../../../lib/commonFunctions";
import * as Actions from 'app/store/actions';
import {setErrorFalse} from 'app/store/actions';
import {useDispatch, useSelector} from "react-redux";
import {updateCurrentRole} from "../../auth/store/actions";
import {withRouter} from "react-router-dom";
import {useHistory} from "react-router";
import {TextField, Tooltip} from "@material-ui/core";
import axios from "axios";
import {getCurrentSelectedObid} from "../../../lib/getObid";
import MenuItem from "@material-ui/core/MenuItem";
import * as Sentry from "@sentry/react";
import Select from "react-select";
import {useRoles} from "../../custom-hooks/useRoles";
import useChangeFacility from "../../custom-hooks/useChangeFacilityEffect";
import multidashboardService from "../../services/multidashboardService";
import {getSettings} from "../../main/apps/settings/store/actions";

function UserMenu() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {isService, isPartner} = useRoles()

    const user = useSelector(({auth}) => auth.user);
    const objects = useSelector(({common}) => common.common.objects);
    const settings = useSelector(({settings}) => settings.settings);
    const obid = useSelector(({common}) => common.common.obid);

    const [selectedObjectId, setSelectedObjectId] = useState(getCurrentSelectedObid());

    function getObjectNamesSelect() {
        let rv = [];

        if (isService || isPartner) {
            rv.push({label: "PARTNER", value: "0"})
        }
        for (let object of objects) {
            if (!object.activeForUser)
                continue
            rv.push({label: object.name, value: object.id, isDisabled: object.status === 'creating' || object.status === null});
        }
        return rv;
    }

    function onChangeObject(obid) {
        dispatch(setErrorFalse());
        if (obid === "0") {
            history.push("/partner");
            multidashboardService.goToMainDashboard();
            return
        }

        const selectedObid = String(obid);
        if (String(getCurrentSelectedObid()) !== selectedObid) {
            const object = objects.find(x => String(x.id) === String(obid));
            Sentry.setTag("obid", selectedObid);
            dispatch(Actions.setObid(selectedObid, object['multidashboardPath']));
            setSelectedObjectId(selectedObid);
            setSelectedOjectName(object['name'])
        }
    }

    useChangeFacility(() => {
        if (user.currentRole && settings)
            dispatch(Actions.applyPermissionsToNavigation(settings));
    }, [user.currentRole, settings]);

    useEffect(() => {
        if (obid && Object.keys(user).length > 0 && user.roles
        ) {
            dispatch(getSettings(obid))
            dispatch(updateCurrentRole(obid));
            setSelectedObjectId(obid);
        }
    }, [obid, user]);

    if (objects.length === 0 || !selectedObjectId)
        return null;

    function sendTestTask() {
        axios.post("/api/tasks/send", {
            obid: getCurrentSelectedObid(),
            filterType: "all",
            primaryFilter: [],
            secondaryFilter: [],
            question: "Test Question",
            title: "Test Title"
        })
    }

    function redirectToDevicesAssignmentHistory() {
        history.push('/devicesAssignmentHistory');
    }

    function redirectToMigrateObjectBetweenEnvironments() {
        history.push('/migrateObjectsBetweenEnvironments');
    }

    const objectsOptions = getObjectNamesSelect()

    return (
        <React.Fragment>
            {isService &&
                <TextField className="ml-16" style={{width: 200}} select label="SERVICE MENU">
                    <MenuItem value={0} onClick={sendTestTask}>
                        SEND TEST TASK TO ALL CWATCH USERS
                    </MenuItem>
                    <MenuItem value={1} onClick={redirectToDevicesAssignmentHistory}>
                        DEVICES ASSIGNMENT HISTORY
                    </MenuItem>
                    <MenuItem value={2} onClick={redirectToMigrateObjectBetweenEnvironments}>
                        MIGRATE OBJECT BETWEEN ENVIRONMENTS
                    </MenuItem>
                </TextField>
            }
            <div/>
            <Tooltip title={selectedObjectId}>
                <div style={{alignSelf: 'center', width: '300px'}}>
                    <Select
                        options={objectsOptions}
                        menuShouldScrollIntoView
                        value={objectsOptions.find(option => option.value === selectedObjectId)}
                        onChange={(e) => onChangeObject(e.value)} isSearchable={true}
                    />
                </div>
            </Tooltip>
            <div/>
        </React.Fragment>
    );
}

export default withRouter(React.memo(UserMenu));
