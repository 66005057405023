import React, {useEffect, useState} from 'react';
import "../../../../../../styles/global-styles.scss";
import {CircularProgress} from '@material-ui/core';
import {FuseScrollbars, FuseUtils} from '@fuse';
import {withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import ReactTable from "react-table6";
import Tooltip from "@material-ui/core/Tooltip";
import * as Actions from "app/main/apps/devices/store/actions/devices.actions.js";
import DeviceAlarmDialog from "app/main/apps/devices/devices/Dialogs/DeviceAlarmDialog";
import {I18n} from "react-redux-i18n";
import {PAGE_SIZE, SETTING_MIN_BAT_PCT_TO_BE_FULLY_READY, SETTING_PRIVACY_RESTRICTIONS, SETTING_TYPE_BOOLEAN, SETTING_TYPE_STRING} from "../../../../../../lib/constants";
import {useIntervalTimeSelect} from "../../../../../../utils/hooks/useIntervalTimeSelect";
import batteryChargingIcon from "../../../../../../assets/new-icons/battery-charging.svg";
import batteryLowIcon from "../../../../../../assets/new-icons/battery-low.svg";
import batteryEmptyIcon from "../../../../../../assets/new-icons/battery-empty.svg";
import batteryHalfIcon from "../../../../../../assets/new-icons/battery-half.svg";
import batteryMostlyGoodIcon from "../../../../../../assets/new-icons/battery-quater.svg";
import batterFullIcon from "../../../../../../assets/new-icons/battery-full.svg";
import cellularEmptyErasedIcon from "../../../../../../assets/new-icons/cellular-empty.erased.svg";
import cellularEmptyIcon from "../../../../../../assets/new-icons/cellular-empty.svg";
import cellularLowIcon from "../../../../../../assets/new-icons/cellular-low.svg";
import cellularHalfIcon from "../../../../../../assets/new-icons/cellular-half.svg";
import cellularQuaterIcon from "../../../../../../assets/new-icons/cellular-quater.svg";
import cellularFullIcon from "../../../../../../assets/new-icons/cellular-full.svg";
import wifiEmptyErasedIcon from "../../../../../../assets/new-icons/wifi-empty.erased.svg";
import wifiEmptyIcon from "../../../../../../assets/new-icons/wifi-empty.svg";
import wifiLowIcon from "../../../../../../assets/new-icons/wifi-low.svg";
import wifiHalfIcon from "../../../../../../assets/new-icons/wifi-half.svg";
import wifiMostlyGoodIcon from "../../../../../../assets/new-icons/wifi-quater.svg";
import wifiFullIcon from "../../../../../../assets/new-icons/wifi-full.svg";
import {getTimestampFromScreenshotFname, HumanizedTimeElapsedFromEpoch, isOnline} from "../../../../../../lib/commonFunctions";
import IconButton from "@material-ui/core/IconButton";
import {getPageRowForSelection} from "../../../../../../utils/funcs/get-page-row-for-selection";
import RestartAppDialog from "../Dialogs/AppRestartDialog";
import {
    DEVICE_TYPE_BUTTON,
    DEVICE_TYPE_TABLET,
    DEVICE_TYPE_UNRECOGNIZED,
    DEVICE_TYPE_WATCH,
    DEVICE_TYPE_WEBCHAT,
    filterByType,
    getDeviceType,
    isButton,
    isNumber,
    isWatch,
    isWebChat
} from "../../../../../shared/functions/device-type";
import AddNewDevicesDialog from "../Dialogs/AddNewDevicesDialog";
import RemoveDeviceFromObjectDialog from "../Dialogs/RemoveDeviceFromObjectDialog";
import DeviceScreenshotDialog from "../Dialogs/DeviceScreenshotDialog";
import {useSetting} from "../../../../../custom-hooks/useSetting";
import AddNewDevicesReportDialog from "../Dialogs/AddNewDevicesReportDialog";
import ButtonSettingsDialog from "../Dialogs/ButtonSettingsDialog";
import {useRoles} from "../../../../../custom-hooks/useRoles";
import EnhancedTranslate from "../../../../../common-components/EnhancedTranslate";
import TranslationTooltip from "../../../../../common-components/TranslationTooltip";
import {AddDevicesFromPackageModal} from "../../../addDevicesFromPackage/AddDevicesFromPackageModal";
import _ from '@lodash';
import RestartDeviceDialog from "../Dialogs/DeviceRestartDialog";
import DeviceLocationDialog from "../Dialogs/Location/DeviceLocationDialog";
import Badge from "@material-ui/core/Badge";
import {openDeviceLocationDialog, openDeviceLogsDialog} from "../../store/DevicesSlice";
import GoogleScriptsWrapper from "../../../../../common-components/GoogleScriptsWrapper";
import RoomIcon from '@material-ui/icons/Room';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SearchIcon from '@material-ui/icons/Search';
import PhotoIcon from '@material-ui/icons/Photo';
import DeleteIcon from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/History';
import WatchIcon from "@material-ui/icons/Watch";
import ComputerIcon from '@material-ui/icons/Computer';
import TabletIcon from '@material-ui/icons/Tablet';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BugReportIcon from '@material-ui/icons/BugReport';
import DownloadLogsDialog from "../Dialogs/DownloadLogs/DownloadLogsDialog";
import {useReactTableTranslations} from "../../../../../custom-hooks/useReactTableTranslations";
import AssignedUserChip from "./AssignedUserChip";
import * as ForSelectActions from "../../../../../store/actions";
import useChangeFacility from "../../../../../custom-hooks/useChangeFacilityEffect";

function LastSeen({lastSeenTime}) {
    return <div className={"text-center"}>
        <p>{I18n.t('Last seen')}</p>
        <p>{HumanizedTimeElapsedFromEpoch(lastSeenTime / 10000)}</p>
    </div>
}

function LastLogged({fullName}) {
    return <div className={"text-center"}>
        <p>{I18n.t('Logged')}</p>
        <p>{fullName}</p>
    </div>
}

function ButtonSerialName({serial, name}) {
    return <div className={"text-center"}>
        <p>{serial}</p>
        <p>{name}</p>
    </div>
}

function isButtonNotConfigured(device) {
    if (!device || !device.buttonDetails)
        return true
    return Object.keys(device.buttonDetails).length === 0
}

export function getDescStatus(device, minBatPctToBeFullyReady = 80) {
    if (_.isEmpty(device.deviceShadowReported)) {
        return I18n.t("No connection")
    }
    if (isButton(device)) {
        if (isOnline(device)) {
            if (isButtonNotConfigured(device))
                return I18n.t("Configuration required")
            else
                return I18n.t('buttonClicked', {count: device.buttonDetails.clicksCount});
        } else {
            if (device.lastSeenTime)
                return (
                    <LastSeen lastSeenTime={device.lastSeenTime}/>
                )
            else {
                return <div>-</div>
            }
        }
    } else {
        try {
            if (isOnline(device)) {
                if (-1 !== device.deviceShadowReported?.uId)
                    return (
                        <LastLogged fullName={device.fullName}/>
                    );
                if (device.deviceShadowReported?.onAc === 1) {
                    if (device.deviceShadowReported?.battery?.level < minBatPctToBeFullyReady)
                        return I18n.t("Charging is taking place");
                    else
                        return I18n.t("Charged");
                }
                return I18n.t("Unlogged");
            } else if (device.lastSeenTime)
                return (
                    <LastSeen lastSeenTime={device.lastSeenTime}/>
                )
            else
                return <div>-</div>
        } catch (e) {
            console.error("DevicesTable.getDescStatus(), exc:", e);
        }
    }
}

export function getDescStatusJustText(device, minBatPctToBeFullyReady = 80) {
    if (isButton(device)) {
        if (isOnline(device)) {
            return I18n.t('buttonClicked', {count: device.buttonClickedCount});
        } else {
            return I18n.t("Last seen") + " " + HumanizedTimeElapsedFromEpoch(device.lastSeenTime / 10000)
        }
    } else {
        try {
            if (isOnline(device)) {
                if (-1 !== device.deviceShadowReported?.uId)
                    return I18n.t('Logged') + " " + device.function
                if (device.deviceShadowReported?.onAc === 1) {
                    if (device.deviceShadowReported?.battery?.level < minBatPctToBeFullyReady)
                        return I18n.t("Charging is taking place");
                    else
                        return I18n.t("Charged");
                }
                return I18n.t("Unlogged");
            } else
                return I18n.t("Last seen") + " " + HumanizedTimeElapsedFromEpoch(device.lastSeenTime / 10000)
        } catch (e) {
            console.error("DevicesTable.getDescStatusJustText(), exc:", e);
        }
    }
}

function DevicesTable(props) {
    const {hasPermissionToCreateEditRemove, isPartner, isService} = useRoles();
    const dispatch = useDispatch();
    const pageSizeChangedByUser = React.useRef(false);
    const reactTableTranslations = useReactTableTranslations()

    const devices = useSelector(({devices}) => devices.devices.data);
    const devicesPanelMode = useSelector(({devices}) => devices.devices.devicesPanelMode);
    const loading = useSelector(({devices}) => devices.devices.loading);
    const searchText = useSelector(({devices}) => devices.devices.searchText);
    const devicesTypeToShow = useSelector(({devices}) => devices.devices.devicesTypeToShow);
    const latestVersion = useSelector(({devices}) => devices.devices.latestVersion);
    const buttonLatestVersion = useSelector(({devices}) => devices.devices.buttonLatestVersion);
    const selectedDeviceSerial = useSelector(({devices}) => devices.devices.selectedDeviceSerial);
    const deviceScreenShotDialogOpen = useSelector(({devices}) => devices.devices.deviceScreenshotDialogOpen);
    const latestAvailableScreenshot = useSelector(({devices}) => devices.devices.latestAvailableScreenshot);
    const lastGetScreenshotRequestTimestamp = useSelector(({devices}) => devices.devices.lastGetScreenshotRequestTimestamp)

    const FILTER_TYPE_ONLINE = 1;
    const FILTER_TYPE_OFFLINE = 2;

    const [minBatPctToBeFullyReady,] = useSetting(SETTING_MIN_BAT_PCT_TO_BE_FULLY_READY, SETTING_TYPE_STRING, "80")
    const [privacyRestrictions] = useSetting(SETTING_PRIVACY_RESTRICTIONS, SETTING_TYPE_BOOLEAN)

    const [filteredData, setFilteredData] = useState(null);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    useChangeFacility(() => {
        dispatch(ForSelectActions.getUsers());
    })

    useEffect(() => {
        function filterOnlineOffline(devices, type) {
            return type === FILTER_TYPE_ONLINE ? devices.filter(isOnline) : devices.filter(isOffline)
        }

        function isOffline(device) {
            return !isOnline(device);
        }

        function getFilteredArray(entities, searchText) {
            const arr = Object.keys(entities).map((id) => entities[id]);
            return FuseUtils.filterDevicesByString(arr, searchText);
        }

        if (devices) {
            let filteredData = {...devices}

            for (let key in filteredData) {
                if (!filteredData[key]['shortSerial'])
                    filteredData[key]['shortSerial'] = shortSerial(filteredData[key])
            }

            filteredData = getFilteredArray(devices, searchText);

            if (devicesPanelMode === FILTER_TYPE_ONLINE || devicesPanelMode === FILTER_TYPE_OFFLINE)
                filteredData = filterOnlineOffline(filteredData, devicesPanelMode);

            filteredData = filterByType(filteredData, devicesTypeToShow);

            setFilteredData(filteredData);
        }
    }, [devices, searchText, devicesPanelMode, devicesTypeToShow]);

    useEffect(() => {
        if (pageSizeChangedByUser.current) {
            return;
        }
        if (filteredData != null) {
            if (filteredData.length > 0 && filteredData.length <= 25) {
                setPageSize(filteredData.length);
            } else if (filteredData.length > 25) {
                setPageSize(25);
            }
        }
    }, [filteredData]);

    useIntervalTimeSelect(() => {
        dispatch(Actions.getDevices(false));
    }, 60, 'sec');

    useIntervalTimeSelect(() => {
        if (selectedDeviceSerial && deviceScreenShotDialogOpen) {
            if (lastGetScreenshotRequestTimestamp > getTimestampFromScreenshotFname(latestAvailableScreenshot))
                dispatch(Actions.getLatestAvailableScreenshot(selectedDeviceSerial, lastGetScreenshotRequestTimestamp));
        }
    }, 1, 'sec');

    if (loading || filteredData === null) {
        return (
            <div className="flex flex-1 items-center justify-center h-full">
                <CircularProgress/>
            </div>
        );
    }

    const setBatteryIcon = (batteryLevel) => {
        if (batteryLevel >= 80)
            return batterFullIcon;
        if (batteryLevel > 65)
            return batteryMostlyGoodIcon;
        if (batteryLevel > 30)
            return batteryHalfIcon;
        if (batteryLevel > 5)
            return batteryLowIcon;
        return batteryEmptyIcon;
    };

    const setCellularIcon = dBm => {
        if (dBm < -116)
            return cellularEmptyIcon;
        if (dBm < -111)
            return cellularLowIcon;
        if (dBm < -105)
            return cellularHalfIcon;
        if (dBm < -100)
            return cellularQuaterIcon;
        return cellularFullIcon;
    };

    const setWifiIcon = dBm => {
        if (dBm < -88)
            return wifiEmptyIcon;
        if (dBm < -83)
            return wifiLowIcon;
        if (dBm < -76)
            return wifiHalfIcon;
        if (dBm < -60)
            return wifiMostlyGoodIcon;
        return wifiFullIcon;
    };

    function shortSerial(device) {
        const serial = device.serial;
        if (serial) {
            if (isButton(device))
                return serial
            if (serial.length > 8) {
                if (isNumber(serial)) {
                    const short = serial.slice(-6);
                    return short.substring(0, 3) + '-' + short.substring(3);
                } else {
                    return '*' + serial.slice(-8);
                }
            }
        }
        return serial;
    }

    function isWatchVersionUpToDate(version) { // false=no, true=yes, 2=no applicable
        if (!version || !latestVersion)
            return 2
        return version === latestVersion;
    }

    function isButtonVersionUpToDate(version) { // false=no, true=yes, 2=no applicable
        if (!version || !buttonLatestVersion)
            return 2
        return version === buttonLatestVersion;
    }

    function showAppVersion(device) {
        const build = device.deviceShadowReported?.build
        const appVersion = device.appVersion

        switch (getDeviceType(device)) {
            case DEVICE_TYPE_WATCH:
                switch (isWatchVersionUpToDate(build)) {
                    case true:
                        return <div>{appVersion}&nbsp;<span style={{color: '#07be03', fontSize: '25px'}}>✓</span></div>;
                    case false:
                        return <div>{appVersion}&nbsp;<span style={{color: 'red', fontSize: '25px'}}>X</span></div>;
                    default:
                        return appVersion;
                }
            case DEVICE_TYPE_BUTTON:
                switch (isButtonVersionUpToDate(build)) {
                    case true:
                        return <div>{appVersion}&nbsp;<span style={{color: '#07be03', fontSize: '25px'}}>✓</span></div>;
                    case false:
                        return <div>{appVersion}&nbsp;<span style={{color: 'red', fontSize: '25px'}}>X</span></div>;
                    default:
                        return appVersion;
                }
            default:
                return appVersion;
        }
    }

    const getTrProps = (state, rowInfo) => {
        if (rowInfo) {
            if (!isOnline(rowInfo.original)) {
                return {
                    style: {
                        opacity: 0.4
                    }
                }
            }
        }
        return {};
    }

    function isScreenshotAvailable(device) {
        return (isOnline(device) && isWatch(device)) || isButton(device)
    }

    function getDeviceIcon(device) {
        try {
            switch (getDeviceType(device)) {
                case DEVICE_TYPE_WATCH:
                    return <WatchIcon/>
                case DEVICE_TYPE_WEBCHAT:
                    return <ComputerIcon/>
                case DEVICE_TYPE_TABLET:
                    return <TabletIcon/>
                case DEVICE_TYPE_BUTTON:
                    return <RadioButtonCheckedIcon/>;
                case DEVICE_TYPE_UNRECOGNIZED:
                    return <HelpOutlineIcon/>;
                default:
                    return <HelpOutlineIcon/>;
            }
        } catch (e) {
            console.error("DevicesTable.getDeviceIcon(), exc:", e);
        }
    }

    return (
        <React.Fragment>
            <div className="w-full flex flex-col">
                <FuseScrollbars className="flex-grow overflow-x-auto">
                    <ReactTable
                        className="-striped -highlight h-full firefox-height-react-table live-panel-users-table box-shadow-main slide-in-fwd-center"
                        page={page}
                        onPageChange={(state) => setPage(state)}
                        onPageSizeChange={(state) => {
                            pageSizeChangedByUser.current = true
                            setPage(0);
                            setPageSize(state);
                        }}
                        pageSize={pageSize}
                        pages={Math.ceil(filteredData.length / pageSize)}
                        defaultPageSize={pageSize}
                        pageSizeOptions={getPageRowForSelection(filteredData)}
                        data={filteredData}
                        {...reactTableTranslations}
                        getTrProps={getTrProps}
                        columns={[
                            {
                                Header: <EnhancedTranslate value={"Online"}/>,
                                id: 'online',
                                width: 70,
                                key: device => device.serial,
                                accessor: device => isOnline(device) ? 1 : 0,
                                Cell: row => {
                                    const textColor = isOnline(row.original) ? "#07be03" : "#acacac";
                                    if (isOnline(row.original))
                                        return (<Tooltip
                                            title={HumanizedTimeElapsedFromEpoch(row.original.lastSeenTime / 10000)}><span
                                            style={{color: textColor, fontSize: '35px'}}>&bull;</span></Tooltip>)
                                    else
                                        return (<span style={{color: textColor, fontSize: '35px'}}>&bull;</span>)
                                }
                            },
                            {
                                Header: <EnhancedTranslate value={"Type"}/>,
                                id: 'type',
                                width: 70,
                                accessor: device => getDeviceType(device),
                                Cell: row => getDeviceIcon(row.original)
                            },
                            {
                                Header: <EnhancedTranslate value={"Serial"}/>,
                                id: 'serial',
                                width: 170,
                                accessor: device => {
                                    const isDeviceButton = isButton(device)
                                    return (
                                        <Tooltip title={device.serial}>
                                            <span
                                                onClick={() => {
                                                    navigator.clipboard.writeText(device.serial)
                                                }}
                                                style={{whiteSpace: 'break-spaces'}}
                                            >
                                                    <div>
                                                        {!isDeviceButton && device.shortSerial}
                                                    </div>
                                                {isDeviceButton && <ButtonSerialName serial={device.serial} name={device.buttonDetails?.name}/>}
                                            </span>
                                        </Tooltip>
                                    )
                                }
                            },
                            {
                                Header: <EnhancedTranslate value={"Assigned_singular"}/>,
                                id: 'assigned',
                                width: 170,
                                sortable: false,
                                Cell: row => {
                                    const device = row.original;

                                    if (isWatch(device))
                                        return (
                                            <AssignedUserChip
                                                serial={device.serial}
                                                userId={device.assignedUser}
                                            />
                                        )
                                    return "-"
                                }
                            },
                            {
                                Header: <EnhancedTranslate value={"Battery"}/>,
                                id: 'battery',
                                width: 125,
                                accessor: device => device.deviceShadowReported?.battery?.level,
                                Cell: row => {
                                    const device = row.original;
                                    if (device.deviceShadowReported?.battery?.mV === undefined)
                                        return (<></>);
                                    if (device.deviceShadowReported?.battery?.mV === 0)
                                        return (
                                            <>
                                                <EnhancedTranslate value={"None"}/>
                                                {1 === device.deviceShadowReported?.onAc &&
                                                    <TranslationTooltip title={'Connected to electricity'}>
                                                        <img alt="" className="ml-8" src={batteryChargingIcon}/>
                                                    </TranslationTooltip>
                                                }
                                            </>
                                        );
                                    const batDetails =
                                        <span>{Math.round(device.deviceShadowReported?.battery?.mV / 10) / 100} V<br/>{device.deviceShadowReported?.battery?.temp / 10} °C</span>;
                                    if (1 === device.deviceShadowReported?.onAc)
                                        return (<Tooltip title={batDetails}>
                                            <div className="flex items-center">&nbsp;<img
                                                src={setBatteryIcon(device.deviceShadowReported.battery?.level)}
                                                alt="battery-level"/>&nbsp;<img alt="" src={batteryChargingIcon}/>&nbsp;<span
                                                style={{
                                                    color: 'grey',
                                                    fontSize: '11px'
                                                }}>{device.deviceShadowReported?.battery.level}% </span></div>
                                        </Tooltip>);
                                    else
                                        return (<Tooltip title={batDetails}>
                                            <div className="flex items-center">&nbsp;<img
                                                src={setBatteryIcon(device.deviceShadowReported.battery?.level)}
                                                alt="battery-level"/>&nbsp;<img alt="" src={batteryChargingIcon}
                                                                                style={{visibility: 'hidden'}}/>&nbsp;
                                                <span style={{
                                                    color: 'grey',
                                                    fontSize: '11px'
                                                }}>{device.deviceShadowReported?.battery.level}% </span></div>
                                        </Tooltip>);
                                }
                            },
                            {
                                Header: <EnhancedTranslate value={"WiFi"}/>,
                                id: 'wifi',
                                width: 70,
                                accessor: device => {
                                    const shadow = device.deviceShadowReported;
                                    if (shadow?.wifi?.ssid === undefined)
                                        return (<></>);
                                    if ("n/a" === shadow?.wifi?.ssid)
                                        return <img alt="" src={wifiEmptyErasedIcon}/>

                                    return (
                                        <Tooltip title={
                                            <span>{shadow?.wifi.dBm} dBm<br/>bssid: {shadow?.wifi?.bssid}<br/>mac: {shadow?.wifi?.mac}<br/>ip: {shadow?.wifi?.ip}</span>}
                                        >
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                <img src={setWifiIcon(device.deviceShadowReported?.wifi.dBm)} alt="wifi"/>
                                            </div>
                                        </Tooltip>
                                    )
                                }
                            },
                            {
                                Header: <EnhancedTranslate value={"Cellular network"}/>,
                                id: 'range',
                                width: 100,
                                accessor: device => device.deviceShadowReported?.cell?.cid,
                                Cell: row => {
                                    if (undefined === row.original.deviceShadowReported?.cell?.cid)
                                        return (<></>);
                                    if (-1 === row.original.deviceShadowReported?.cell?.cid)
                                        return <img src={cellularEmptyErasedIcon} alt="cellular"/>

                                    const turnedOnTimes = row.original.deviceShadowReported?.cell?.turnedOnTimes;

                                    return (
                                        <Tooltip title={
                                            <span>{row.original.deviceShadowReported?.cell?.dBm} dBm<br/>cid: {row.original.deviceShadowReported?.cell?.cid}<br/>reconnects: {null != turnedOnTimes ? turnedOnTimes : "n/a"}</span>}>
                                            <div style={{display: 'flex', justifyContect: 'center'}}>
                                                <img src={setCellularIcon(row.original.deviceShadowReported?.cell?.dBm)}
                                                     alt="cellular"/>
                                                <div style={{
                                                    marginTop: '5px',
                                                    marginLeft: '5px',
                                                    fontSize: '11px'
                                                }}>{(row.original.deviceShadowReported?.cell?.gen != null && row.original.deviceShadowReported?.cell?.gen !== 0) ? `${row.original.deviceShadowReported?.cell?.gen}G` : ''}</div>
                                            </div>
                                        </Tooltip>
                                    )
                                },
                                getHeaderProps: () => {
                                    return {
                                        style: {
                                            whiteSpace: 'unset',
                                        }
                                    }
                                },
                            },
                            {
                                Header: <EnhancedTranslate value={"Status"}/>,
                                id: 'status',
                                accessor: device => {
                                    const lastPairedTime = <>
                                        <span>{null != device.lastPairedTime ? HumanizedTimeElapsedFromEpoch(device.lastPairedTime) : '-'}</span>
                                    </>;
                                    return (
                                        <Tooltip
                                            title={<><EnhancedTranslate value={'Paired'}/>: {lastPairedTime}</>}
                                        >
                                            <span>{getDescStatus(device, minBatPctToBeFullyReady)}</span>
                                        </Tooltip>
                                    )
                                },
                            },
                            {
                                Header: <EnhancedTranslate value={"App version"}/>,
                                id: 'buildDate',
                                accessor: device => {
                                    const build = device.deviceShadowReported?.build || ""
                                    return (<Tooltip title={build}>
                                        <div onClick={() => {
                                            navigator.clipboard.writeText(build)
                                        }}
                                        >
                                            {showAppVersion(device)}
                                        </div>
                                    </Tooltip>)
                                },
                                width: 180,
                                getHeaderProps: () => {
                                    return {
                                        style: {
                                            whiteSpace: 'unset',
                                        }
                                    }
                                },
                                style: {whiteSpace: 'unset'}
                            },
                            {
                                Header: <EnhancedTranslate value={"Actions"}/>,
                                id: 'actions',
                                width: 450,
                                sortable: false,
                                accessor: device =>
                                    <div className="flex items-center">

                                        {!isWebChat(device) && (isPartner || isService) &&
                                            <TranslationTooltip title={'Remove device from object'}>
                                                <IconButton
                                                    disabled={!hasPermissionToCreateEditRemove}
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        dispatch(Actions.setSelectedDeviceSerial(device.serial));
                                                        dispatch(Actions.openRemoveDeviceFromObjectDialog());
                                                    }}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </TranslationTooltip>}

                                        {
                                            (isService && isOnline(device) && (isButton(device) || isWatch(device))) &&
                                            <TranslationTooltip title={'Download logs'}>
                                                <IconButton
                                                    disabled={!hasPermissionToCreateEditRemove}
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        dispatch(openDeviceLogsDialog(device.serial));
                                                    }}
                                                >
                                                    <BugReportIcon/>
                                                </IconButton>
                                            </TranslationTooltip>
                                        }

                                        {!isWebChat(device) && isOnline(device) &&
                                            <TranslationTooltip title={isButton(device) ? "Restart device" : "Restart app"}>
                                                <IconButton
                                                    disabled={!hasPermissionToCreateEditRemove}
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        dispatch(Actions.setSelectedDeviceSerial(device.serial));

                                                        if (isButton(device))
                                                            dispatch(Actions.openRestartDeviceDialog());
                                                        else
                                                            dispatch(Actions.openRestartAppDialog());
                                                    }}
                                                >
                                                    &#x21bb;
                                                </IconButton>
                                            </TranslationTooltip>}

                                        {isScreenshotAvailable(device) &&
                                            <TranslationTooltip title={'Screenshot'}>
                                                <IconButton
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        dispatch(Actions.getScreenshot(device.serial));
                                                        dispatch(Actions.clearLatestAvailableScreenshot());
                                                        dispatch(Actions.setSelectedDeviceSerial(device.serial));
                                                        dispatch(Actions.setSelectedDeviceType(getDeviceType(device)));
                                                        dispatch(Actions.setScreenshotImageLoadingTrue());
                                                        dispatch(Actions.getLatestAvailableScreenshot(device.serial, Math.floor(Date.now() / 1000)));
                                                        dispatch(Actions.openScreenshotDialog())
                                                    }}
                                                >
                                                    <PhotoIcon/>
                                                </IconButton>
                                            </TranslationTooltip>}
                                        {isService && <TranslationTooltip title={'Show shadow'}>
                                            <IconButton
                                                disabled={!hasPermissionToCreateEditRemove}
                                                onClick={(ev) => {
                                                    ev.stopPropagation();
                                                    props.history.push(`/devices/shadow/${device.serial}`);
                                                }}
                                            >
                                                <SearchIcon/>
                                            </IconButton>
                                        </TranslationTooltip>
                                        }
                                        {isButton(device) &&
                                            <>
                                                {isService && <TranslationTooltip title={'Click simulation'}>
                                                    <IconButton
                                                        disabled={!hasPermissionToCreateEditRemove}
                                                        onClick={(ev) => {
                                                            ev.stopPropagation();
                                                            dispatch(Actions.simulateButtonClick(device.serial));
                                                        }}
                                                    >
                                                        <ArrowRightIcon/>
                                                    </IconButton>
                                                </TranslationTooltip>
                                                }
                                                <TranslationTooltip title={'Settings'}>
                                                    <IconButton
                                                        disabled={!hasPermissionToCreateEditRemove}
                                                        onClick={(ev) => {
                                                            ev.stopPropagation();
                                                            dispatch(Actions.openButtonSettingsDialog(device.serial));
                                                        }}
                                                    >
                                                        {isButtonNotConfigured(device) &&
                                                            <Badge
                                                                badgeContent="!"
                                                                color="error"
                                                                style={{transform: 'translate(29px, -17px)', scale: "68% "}}
                                                            />
                                                        }
                                                        <SettingsIcon/>
                                                    </IconButton>
                                                </TranslationTooltip>
                                            </>
                                        }
                                        {!isWebChat(device) && !privacyRestrictions &&
                                            <TranslationTooltip title='Location'>
                                                <IconButton
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        dispatch(openDeviceLocationDialog(device))
                                                    }}
                                                >
                                                    <RoomIcon/>
                                                </IconButton>
                                            </TranslationTooltip>
                                        }
                                        {!privacyRestrictions &&
                                            <TranslationTooltip title='Logs'>
                                                <IconButton
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        props.history.push(`/devices/history/${device.serial}/${getDeviceType(device)}`);
                                                    }}
                                                >
                                                    <HistoryIcon/>
                                                </IconButton>
                                            </TranslationTooltip>
                                        }
                                    </div>,
                            }
                        ]}
                        minRows={0}
                    />
                </FuseScrollbars>
            </div>
            <DeviceAlarmDialog/>
            <RestartAppDialog/>
            <RestartDeviceDialog/>
            <AddNewDevicesDialog/>
            <RemoveDeviceFromObjectDialog/>
            <DeviceScreenshotDialog/>
            <AddNewDevicesReportDialog/>
            <ButtonSettingsDialog/>
            <AddDevicesFromPackageModal/>
            <DownloadLogsDialog/>
            <GoogleScriptsWrapper>
                <DeviceLocationDialog/>
            </GoogleScriptsWrapper>
        </React.Fragment>
    );
}

export default withRouter(DevicesTable);
