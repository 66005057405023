import React from 'react';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {RESET_STORE} from "../store/actions/reduxActions";


export default function GoBackToLoginButton() {
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <div
            className={"self-start cursor-pointer"}
            onClick={() => {
                history.push("/login");
                dispatch({
                    type: RESET_STORE
                })
            }}
        >
            <KeyboardBackspaceIcon color={"primary"} fontSize={"12"}/>
        </div>
    );
}
