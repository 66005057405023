import React, {useEffect, useMemo} from 'react';
import {AppBar, Button, CircularProgress, Dialog, DialogContent, makeStyles, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import EnhancedTranslate from "../../../../../../common-components/EnhancedTranslate";
import {formatTimestamp, HumanizedTimeElapsedFromEpoch, isOnline, TransitionComponent} from "../../../../../../../lib/commonFunctions";
import {closeDeviceLocationDialog, fetchDeviceLocation, fetchLastLoggedUserOnDevice} from "../../../store/DevicesSlice";
import {COLOR_CHARCOAL_5, COLOR_GREY_60} from "../../../../../../../lib/colors";
import clsx from "clsx";
import {Circle} from "react-google-maps";
import {Map} from "../../../../partner/Map";
import LastKnowLocation from "./LastKnowLocation";
import {isWatch} from "../../../../../../shared/functions/device-type";
import * as Actions from "../../../store/actions/devices.actions";

export const useStyles = makeStyles(() => ({
    detailsContainer: {
        width: "300px",
        background: COLOR_CHARCOAL_5,
        padding: "8px 12px",
        borderRadius: "3px"
    },
    detailsLabelText: {
        color: COLOR_GREY_60,
    },
    dialogPaper: {
        minWidth: "900px",
        height: "675px",
        borderRadius: "10px"
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    },
    playAlarmButton: {
        marginTop: "32px",
        alignSelf: "center"
    },
    mapContainer: {
        height: "500px",
        width: "500px",
        marginLeft: "32px",
        boxShadow: "5px 5px 6px -6px rgba(66, 68, 90, 1)"
    },
    errorContainer: {
        display: "flex",
        paddingTop: "40px",
        textAlign: "center",
    },
}));

export default function DeviceLocationDialog() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const TWO_WEEKS_IN_SECONDS = 1209600

    const {open, device, loading, location, error, lastLoggedUser, lastPingTimestamp, lastLoggedUserLoading} = useSelector((state) => state.devicesSlice.devicesLocationDialog)
    const {serial} = device

    useEffect(() => {
        if (open) {
            if (!isOnline(device))
                dispatch(fetchDeviceLocation(serial))
            if (isWatch(device))
                dispatch(fetchLastLoggedUserOnDevice(serial))
        }
    }, [open])

    function closeComposeDialog() {
        dispatch(closeDeviceLocationDialog());
    }

    function getPositions() {
        if (!location)
            return []
        return [{
            lat: location.location.lat,
            lng: location.location.lng,
        }]
    }

    function enoughTimeElapsed(timestamp) {
        return (parseInt(Date.now() / 1000) - parseInt(timestamp / 10000)) > TWO_WEEKS_IN_SECONDS
    }

    const positions = useMemo(() => getPositions(), [location]);

    if (Object.keys(device).length === 0)
        return null

    return (
        <Dialog
            classes={{paper: classes.dialogPaper}}
            open={open}
            TransitionComponent={TransitionComponent}
            onClose={closeComposeDialog}
        >
            <AppBar position="static" elevation={1}>
                <div className="p-24">
                    <div className="flex">
                        <Typography color="inherit" className="ml-8 typography-h6">
                            <EnhancedTranslate value={'Device location'}/>
                        </Typography>
                    </div>
                </div>
            </AppBar>
            <DialogContent className={"p-24"}>
                {loading && <div className={classes.loadingContainer}>
                    <CircularProgress color={"secondary"}/>
                </div>
                }

                {!loading &&
                    <div>
                        <div className={"flex justify-between"}>
                            <div className={"flex flex-col"}>

                                <div className={clsx(classes.detailsContainer)}>
                                    <Typography className={clsx(classes.detailsLabelText, "typography-caption")}>
                                        <EnhancedTranslate value={"Serial number"}/>
                                    </Typography>
                                    {serial}
                                </div>

                                {isWatch(device) &&
                                    <div className={clsx(classes.detailsContainer, "mt-16")}>
                                        {lastLoggedUserLoading &&
                                            <CircularProgress color={"secondary"} size={24}/>
                                        }
                                        {!lastLoggedUserLoading &&
                                            <>
                                                <Typography className={clsx(classes.detailsLabelText, "typography-caption")}>
                                                    <EnhancedTranslate value={"Recently logged in"}/>
                                                </Typography>
                                                {(lastLoggedUser && lastLoggedUser.fullname) && <div>
                                                    {lastLoggedUser.fullname}, {HumanizedTimeElapsedFromEpoch(lastLoggedUser.timestamp / 10000)}
                                                </div>
                                                }
                                                {(!lastLoggedUser || !lastLoggedUser.fullname) && <EnhancedTranslate value={"None"}/>}
                                            </>
                                        }
                                    </div>
                                }
                                <div className={clsx(classes.detailsContainer, 'mt-16')}>
                                    <Typography className={clsx(classes.detailsLabelText, "typography-caption")}>
                                        <EnhancedTranslate value={"Last known location"}/>
                                    </Typography>
                                    {location && !error &&
                                        <LastKnowLocation/>
                                    }
                                    {!(location && !error) &&
                                        <EnhancedTranslate value={"None"}/>
                                    }
                                </div>
                                {location && !error && enoughTimeElapsed(lastPingTimestamp) &&
                                    <div className={clsx(classes.detailsContainer, 'mt-16')}>
                                        <Typography className={clsx(classes.detailsLabelText, "typography-caption")}>
                                            <EnhancedTranslate value={"Time of last known location"}/>
                                        </Typography>
                                        {formatTimestamp(lastPingTimestamp, true)}
                                    </div>
                                }
                                {isOnline(device) &&
                                    <Button
                                        onClick={() => {
                                            dispatch(Actions.setSelectedDeviceSerial(device.serial));
                                            dispatch(Actions.openDeviceAlarmDialog());
                                        }}
                                        type={"button"}
                                        color={"secondary"}
                                        variant={"contained"}
                                        className={classes.playAlarmButton}
                                    >
                                        <EnhancedTranslate value={"Play alarm"}/>
                                    </Button>
                                }
                            </div>

                            <div className={"w-full self-center"}>
                                {!isOnline(device) && <>
                                    {location && !error &&
                                        <div className={classes.mapContainer}>
                                            <Map
                                                positions={positions}
                                                containerElement={<div style={{height: `100%`, width: "100%"}}/>}
                                                mapElement={<div style={{height: `100%`, width: "100%"}}/>}
                                            >
                                                <Circle
                                                    defaultCenter={positions[0]}
                                                    radius={350}
                                                    options={{
                                                        strokeColor: "#FF0000",
                                                        strokeOpacity: 0.8,
                                                        strokeWeight: 2,
                                                        fillColor: "#FF0000",
                                                        fillOpacity: 0.35,
                                                        center: positions[0],
                                                    }}
                                                />
                                            </Map>
                                        </div>
                                    }
                                    {error &&
                                        <div className={"m-32 text-center"}>
                                            <EnhancedTranslate value={error}/>
                                        </div>
                                    }
                                </>
                                }
                                {isOnline(device) && <>
                                    <div className={"text-center"}>
                                        <EnhancedTranslate value={"Location unavailable"}/>
                                    </div>
                                </>
                                }

                            </div>

                        </div>
                    </div>
                }
            </DialogContent>
        </Dialog>
    );
}
