import React from 'react';
import "../styles/global-styles.scss";
import {FuseAuthorization, FuseLayout, FuseTheme} from '@fuse';
import Provider from 'react-redux/es/components/Provider';
import {BrowserRouter} from 'react-router-dom';
import jssExtend from 'jss-extend';
import {Auth} from './auth';
import store from './store';
import AppContext from './AppContext';
import routes from './fuse-configs/routesConfig';
import {create} from 'jss';
import {createGenerateClassName, jssPreset, StylesProvider} from '@material-ui/styles';
import {useReactTableKeyboardKeys} from "./custom-hooks/useReactTableKeyboardKeys";
import axios from "axios";
import {ENDPOINT_REQUEST_DEFAULT_TIMEOUT_MILISECONDS} from "../lib/constants";
import "../lib/prototypes";
import * as Sentry from "@sentry/react";
import {setIsApiDown, showMessage} from "./store/actions";
import {I18n} from "react-redux-i18n";
import ApiStatusWrapper from "./shared/ApiStatusRedirector";
import multidashboardService from "./services/multidashboardService";
import TranslationsWrapper from "./shared/TranslationsWrapper";

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
    insertionPoint: document.getElementById('jss-insertion-point'),
});

const generateClassName = createGenerateClassName();

axios.defaults.timeout = ENDPOINT_REQUEST_DEFAULT_TIMEOUT_MILISECONDS
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(undefined, (err) => {
    const {config, message} = err;

    if (message.includes("Network Error")) {
        store.dispatch(showMessage({
            message: I18n.t("Lost internet connection during request"),
            autoHideDuration: 6000,
            variant: 'error'
        }))
        return Promise.reject(err);
    }

    if (config.url.includes("assets/"))
        return Promise.reject(err);

    if (err.response && err.response.status === 502) {
        store.dispatch(setIsApiDown(true))
        return Promise.reject(err);
    }

    if (err.response && (err.response.status === 404 || err.response.status === 422)) {
        return Promise.reject(err);
    }

    // retry requests when timeout that we specified above happens
    if (message.includes("timeout of " + ENDPOINT_REQUEST_DEFAULT_TIMEOUT_MILISECONDS + "ms exceeded")) {
        Sentry.captureException(new Error(`[BACKEND ENDPOINT TIMEOUT] url: ${config.url} exceeded time limit of ${ENDPOINT_REQUEST_DEFAULT_TIMEOUT_MILISECONDS}ms`));
        store.dispatch(showMessage({
            message: I18n.t("Server error - request timeout"),
            autoHideDuration: 6000,
            variant: 'error'
        }))
        return Promise.reject(err);
    }

    if (err.response.status === 400) {
        return Promise.reject(err);
    }

    // general/unhandled api error - show toast and report to sentry
    else {
        Sentry.captureException(new Error(`[BACKEND ERROR] url: ${config.url}`));
        store.dispatch(showMessage({
            message: I18n.t("Server error - failed to retrieve data"),
            autoHideDuration: 6000,
            variant: 'error'
        }))
        if (err.response.status !== 500)
            return Promise.reject(err);
    }
    return Promise.reject(err);
});

function App() {
    useReactTableKeyboardKeys()

    return (
        <AppContext.Provider
            value={{
                routes,
            }}
        >
            <StylesProvider jss={jss} generateClassName={generateClassName}>
                <Provider store={store}>
                    <Auth>
                        <TranslationsWrapper>
                            <ApiStatusWrapper>
                                <BrowserRouter basename={multidashboardService.currentEnv}>
                                    <FuseAuthorization>
                                        <FuseTheme>
                                            <FuseLayout/>
                                        </FuseTheme>
                                    </FuseAuthorization>
                                </BrowserRouter>
                            </ApiStatusWrapper>
                        </TranslationsWrapper>
                    </Auth>
                </Provider>
            </StylesProvider>
        </AppContext.Provider>
    );
}

export default App;
