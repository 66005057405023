import React from 'react';
import {Dialog, DialogActions} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {I18n} from "react-redux-i18n";
import * as DeviceActions from "app/main/apps/devices/store/actions";
import * as PartnerActions from "app/main/apps/partner/store/actions";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import EnhancedTranslate from "../../../../../common-components/EnhancedTranslate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AddNewDevicesReportDialog() {
    const dispatch = useDispatch();
    const addingNewDevicesAddedSerials = useSelector(({devices}) => devices.devices.addingNewDevicesAddedSerials);
    const addingNewDevicesFailedSerials = useSelector(({devices}) => devices.devices.addingNewDevicesFailedSerials);

    const addingNewDevicesAddedSerialsPartner = useSelector(({partnerReducer}) => partnerReducer.partnerReducer.pairDevicesAddedSerials);
    const addingNewDevicesFailedSerialsPartner = useSelector(({partnerReducer}) => partnerReducer.partnerReducer.pairDevicesFailedSerials);

    function closeComposeDialog() {
        isDeviceTab() ? dispatch(DeviceActions.closeAddingNewDeviceBySerialRaportDialog()) : dispatch(PartnerActions.closePairDeviceBySerialReportDialog())
    }

    function isDeviceTab() {
        return addingNewDevicesFailedSerials.length > 0 || addingNewDevicesAddedSerials.length > 0
    }

    function isOpen() {
        return addingNewDevicesFailedSerials.length > 0 || addingNewDevicesAddedSerials.length > 0 || addingNewDevicesAddedSerialsPartner.length > 0 || addingNewDevicesFailedSerialsPartner.length > 0
    }

    let addedSerials, failedSerials;
    if (isDeviceTab()) {
        addedSerials = addingNewDevicesAddedSerials;
        failedSerials = addingNewDevicesFailedSerials;
    } else {
        addedSerials = addingNewDevicesAddedSerialsPartner;
        failedSerials = addingNewDevicesFailedSerialsPartner;
    }

    const failedSerialsSerialNotFound = failedSerials.filter(x => x.reason === 'SERIAL NOT FOUND')
    const failedSerialsAlreadyAssigned = failedSerials.filter(x => x.reason === 'ALREADY ASSIGNED')

    return (
        <Dialog
            open={isOpen()}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeComposeDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title"><EnhancedTranslate value={'Add devices dialog report'}/></DialogTitle>
            <DialogContent style={{background: 'white', color: 'rgba(0, 0, 0, 0.87)'}}>
                <DialogContentText style={{background: 'white', color: 'rgba(0, 0, 0, 0.87)'}}>
                    {addedSerials.length > 0 ? I18n.t('Added serials') + ": " : ""}

                    {addedSerials.length > 0 ?
                        <p style={{color: 'rgb(0, 200, 0)'}}>
                            {addedSerials.map((item) =>
                                <li key={item}>{item}</li>
                            )}
                        </p>
                        : ""}
                    <br/><br/>

                    {failedSerialsSerialNotFound.length > 0 ? I18n.t('Serials not found') + ":" : ""}
                    {failedSerialsSerialNotFound.length > 0 ?
                        <p style={{color: 'rgb(200, 0, 0)'}}>
                            {failedSerialsSerialNotFound.map((item) =>
                                <li key={item.serial}>{item.serial}</li>
                            )}
                        </p>
                        : ""}

                    {failedSerialsAlreadyAssigned.length > 0 ? I18n.t('Serials currently assigned') + ":" : ""}
                    {failedSerialsAlreadyAssigned.length > 0 ?
                        <p style={{color: 'rgb(200, 0, 0)'}}>
                            {failedSerialsAlreadyAssigned.map((item) =>
                                <li key={item.serial}>{item.serial} - <EnhancedTranslate value={'Assigned to'}/>
                                    &nbsp;
                                    <b>{item.objectSerialIsAssignedTo ? item.objectSerialIsAssignedTo : I18n.t('other object')}</b>
                                </li>
                            )}
                        </p>
                        : ""}

                </DialogContentText>
            </DialogContent>
            <DialogActions>

                <Button onClick={closeComposeDialog} color="primary">
                    <EnhancedTranslate value={'OK'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddNewDevicesReportDialog;
