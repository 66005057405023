import React from 'react';
import Button from "@material-ui/core/Button";
import EnhancedTranslate from "../../../common-components/EnhancedTranslate";
import {DialogActions} from "@material-ui/core";


export default function ButtonSettingsDialogActions({onSubmit, canBeSubmitted, closeDialog}) {
    return (
        <DialogActions style={{padding: 0, paddingTop: 8}}>
            <Button
                color="secondary"
                variant="outlined"
                onClick={closeDialog}
            >
                <EnhancedTranslate value={'Cancel'}/>
            </Button>
            <Button
                style={{marginLeft: 24}}
                variant="contained"
                color="secondary"
                disabled={!canBeSubmitted}
                onClick={() => {
                    onSubmit()
                    closeDialog()
                }}
            >
                <EnhancedTranslate value={'Save'}/>
            </Button>
        </DialogActions>
    )
}
