import {PersonnelAppConfig} from "./personnel/PersonnelAppConfig";
import {SummaryAppConfig} from "./summary/SummaryAppConfig";
import {DevicesAppConfig} from "./devices/DevicesAppConfig";
import {SettingsAppConfig} from "./settings/SettingsAppConfig";
import {PredefinedMessagesAppConfig} from "./communication/predefinedMessages/PredefinedMessagesAppConfig";
import {ChannelsAppConfig} from "./channels/ChannelsAppConfig";
import {CommunicationConfig} from "./reports/Communication/CommunicationConfig";
import {CwatchReportsConfig} from "./reports/CwatchReports/CwatchReportsConfig";
import {DashboardAccessAppConfig} from "./dashboardAccess/DashboardAccessAppConfig";
import {ButtonsReportsAppConfig} from "./reports/Buttons/ButtonsReportsAppConfig";
import {PersonnelOnlineAppConfig} from "./personnelOnline/PersonnelOnlineAppConfig";
import {PartnerAppConfig} from "./partner/PartnerAppConfig";
import {WorkRegisterReportsConfig} from "./reports/WorkRegister/WorkRegisterReportsConfig"
import {DevicesAssignmentHistoryAppConfig} from "./devicesAssignmentHistory/DevicesAssignmentHistoryAppConfig"
import {AddDevicesFromPackageRedirectorAppConfig} from "./addDevicesFromPackage/AddDevicesFromPackageRedirectorAppConfig";
import {MigrateObjectsBetweenEnvironmentsAppConfig} from "./migrateObjectBetweenEnvironments/MigrateObjectsBetweenEnvironmentsAppConfig";
import {RmaAppConfig} from "./rma/RmaAppConfig";

export const appsConfigs = [
    ButtonsReportsAppConfig,
    DashboardAccessAppConfig,
    PartnerAppConfig,
    PersonnelOnlineAppConfig,
    PersonnelAppConfig,
    SummaryAppConfig,
    DevicesAppConfig,
    SettingsAppConfig,
    PredefinedMessagesAppConfig,
    ChannelsAppConfig,
    CommunicationConfig,
    CwatchReportsConfig,
    WorkRegisterReportsConfig,
    DevicesAssignmentHistoryAppConfig,
    AddDevicesFromPackageRedirectorAppConfig,
    MigrateObjectsBetweenEnvironmentsAppConfig,
    RmaAppConfig
];
