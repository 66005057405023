import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {mapDashboardLanguageToGoogleApiLanguage} from "../../lib/languages";

export default function GoogleScriptsWrapper({children}) {
    const i18n = useSelector(({i18n}) => i18n);

    useEffect(() => {
        loadGoogleApiScript(i18n.locale)
            .catch(function () {
                console.error(`FAILED TO LOAD GOOGLE API SCRIPTS`);
            })
        return () => {
            removeGoogleApiScripts()
        }
    }, []);


    function removeGoogleApiScripts() {
        const googleApiScript = document.getElementById('google-api-script')
        if (googleApiScript)
            googleApiScript.remove()

        const scripts = document.getElementsByTagName("script");
        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src.match(/google/)) {
                scripts[i].remove()
            }
        }
    }

    function loadGoogleApiScript(language) {
        return new Promise(function (resolve, reject) {
            //load new script with lang
            const [head] = document.getElementsByTagName('head');
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCyBTHkyV4Wm1NWCcfe5J2up20kCA-945I&libraries=places&language=${mapDashboardLanguageToGoogleApiLanguage[language]}`;
            script.id = `google-api-script`;
            script.addEventListener('load', function () {
                resolve();
            });
            script.addEventListener('error', function (e) {
                reject(e);
            });
            head.appendChild(script);
        })
    }

    return (
        <>
            {children}
        </>
    )
}
