import * as Actions from '../actions/channels.actions';
import _ from '@lodash';
import {RESET_STORE} from "../../../../../store/actions/reduxActions";

const initialState = {
    entities: null,
    searchText: '',
    selectedContactIds: [],
    routeParams: {},
    connectedUsers: [],
    selectedChannel: null,
    selectedConnectedUsers: [],
    contactDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    confirmationDialog: {
        props: {
            open: false
        },
    },
    connectedUserDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    loading: false,
    loadingChannels: false,
    subscriberStatusFilter: 1 // 1:all, 2:online, 3:offline
};

// eslint-disable-next-line complexity
const channelsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CHANNELS: {
            let entities = action.payload.map((i) => ({
                ...i,
                description: (i.description === null || i.description === undefined) ? "" : i.description,
                autoAddMode: (i.autoAddMode === null || i.autoAddMode === undefined) ? 0 : i.autoAddMode
            }));
            entities = _.keyBy(entities, 'id');
            return {
                ...state,
                entities: entities,
                routeParams: action.routeParams,
                loading: false,
            };
        }
        case Actions.SET_LOADING_CHANNELS_DIALOG: {
            return {
                ...state
            };
        }
        case Actions.SET_LOADING_TRUE: {
            return {
                ...state,
                loading: true,
            };
        }
        case Actions.SET_LOADING_FALSE: {
            return {
                ...state,
                loading: false,
            };
        }
        case Actions.REMOVE_CHANNEL: {
            let newEntities = {...state.entities};
            delete newEntities[action.channelId];
            return {
                ...state,
                entities: newEntities
            };
        }
        case Actions.REMOVE_CHANNELS: {
            let newEntities = {...state.entities};
            for (let id of action.channelIds) {
                delete newEntities[id];
            }
            return {
                ...state,
                entities: newEntities
            };
        }
        case Actions.UPDATE_CHANNEL: {
            let newEntities = {...state.entities};

            newEntities[action.channel.id] = {...action.channel};

            return {
                ...state,
                entities: newEntities,
                loadingChannels: false,
                lastAddedChannel: action.channel.id,
                usersPerJobTitle: action.usersPerJobTitle
            };
        }
        case Actions.CHANGE_ORDER: {
            let newEntities = _.cloneDeep(state.entities);
            let channelId = Object.values(newEntities).find(x => x.orderNumber === action.order1) ? Object.values(newEntities).find(x => x.orderNumber === action.order1).id : null;

            if (channelId == null) {
                return {
                    ...state,
                }
            }

            if (action.order1 < action.order2) {
                for (let entity in newEntities) {
                    if (newEntities[entity].orderNumber > action.order1 && newEntities[entity].orderNumber <= action.order2) {
                        newEntities[entity]['orderNumber'] = newEntities[entity]['orderNumber'] - 1;
                    }
                }
            }

            if (action.order1 > action.order2) {
                for (let entity in newEntities) {
                    if (newEntities[entity].orderNumber < action.order1 && newEntities[entity].orderNumber >= action.order2) {
                        newEntities[entity]['orderNumber'] = newEntities[entity]['orderNumber'] + 1;
                    }
                }
            }

            newEntities[channelId]['orderNumber'] = action.order2;

            return {
                ...state,
                entities: newEntities
            };
        }
        case Actions.GET_SELECTED_CHANNEL_INFO: {
            return {
                ...state,
                selectedChannel: action.payload
            };
        }
        case Actions.SET_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.GET_CONNECTED_USERS: {
            return {
                ...state,
                connectedUsers: _.keyBy(action.payload, 'id'),
            };
        }
        case Actions.TOGGLE_IN_SELECTED_CONTACTS: {

            const contactId = action.contactId;

            let selectedContactIds = [...state.selectedContactIds];

            if (selectedContactIds.find(id => id === contactId) !== undefined) {
                selectedContactIds = selectedContactIds.filter(id => id !== contactId);
            } else {
                selectedContactIds = [...selectedContactIds, contactId];
            }

            return {
                ...state,
                selectedContactIds: selectedContactIds
            };
        }
        case Actions.TOGGLE_HIDDEN: {
            const channelId = action.channelId;
            let entities = {...state.entities};

            entities[channelId]['hidden'] = action.hidden;

            return {
                ...state,
                entities: entities
            };
        }
        case Actions.SELECT_ALL_CHANNELS: {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedContactIds = arr.map(contact => contact.id);

            return {
                ...state,
                selectedContactIds: selectedContactIds
            };
        }
        case Actions.DESELECT_ALL_CHANNELS: {
            return {
                ...state,
                selectedContactIds: []
            };
        }
        case Actions.OPEN_NEW_CHANNEL_DIALOG: {
            return {
                ...state,
                contactDialog: {
                    type: 'new',
                    props: {
                        open: true
                    },
                    data: null
                }
            };
        }
        case Actions.OPEN_NEW_CONNECTED_USER_DIALOG: {
            return {
                ...state,
                connectedUserDialog: {
                    type: 'new',
                    props: {
                        open: true
                    },
                    data: null
                }
            };
        }
        case Actions.CLOSE_NEW_CHANNEL_DIALOG: {
            return {
                ...state,
                contactDialog: {
                    type: 'new',
                    props: {
                        open: false
                    },
                    data: null,
                }
            };
        }
        case Actions.CLOSE_NEW_CONNECTED_USER_DIALOG: {
            return {
                ...state,
                connectedUserDialog: {
                    type: 'new',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case Actions.OPEN_EDIT_CHANNEL_DIALOG: {
            return {
                ...state,
                contactDialog: {
                    type: 'edit',
                    props: {
                        open: true
                    },
                    data: action.data
                }
            };
        }
        case Actions.OPEN_EDIT_CONNECTED_USER_DIALOG: {
            return {
                ...state,
                connectedUserDialog: {
                    type: 'edit',
                    props: {
                        open: true
                    },
                    data: action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_CHANNEL_DIALOG: {
            return {
                ...state,
                contactDialog: {
                    type: 'edit',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case Actions.CLOSE_EDIT_CONNECTED_USER_DIALOG: {
            return {
                ...state,
                connectedUserDialog: {
                    type: 'edit',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case Actions.ADD_CHANNEL: {
            if (action.payload !== undefined && !action.payload.id !== undefined) {
                let newEntities = {
                    ...state.entities,
                    [action.payload.id]: action.payload,
                };
                return {
                    ...state,
                    entities: {...newEntities},
                    loadingChannels: false,
                    usersPerJobTitle: action.payload.usersPerJobTitle,
                    lastAddedChannel: action.payload.id,
                }
            } else
                return {...state};
        }
        case RESET_STORE:
            return initialState
        default:
            return state;
    }
};

export default channelsReducer;
