import React, {Component} from 'react';
import {FuseSplashScreen} from '@fuse';
import {connect} from 'react-redux';
import * as userActions from 'app/auth/store/actions';
import {bindActionCreators} from 'redux';
import * as Actions from 'app/store/actions';
import * as CommonActions from '../store/actions/common/common.actions';
import jwtService from 'app/services/jwtService';


class Auth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            waitAuthCheck: true
        };
    }

    componentDidMount() {
        return Promise.all([
            this.jwtCheck()
        ]).then(() => {
            this.setState({waitAuthCheck: false})
        })
    }

    jwtCheck() {
        return new Promise(resolve => {
            jwtService.on('onAutoLogin', () => {

                /**
                 * Sign in and retrieve user data from Api
                 */
                jwtService.signInWithToken()
                    .then(data => {
                        this.props.setUserData(data);
                        resolve();
                    })
                    .catch(error => {
                        this.props.showMessage({message: error});

                        resolve();
                    })
            });

            jwtService.on('onAutoLogout', (message) => {
                if (message) {
                    this.props.showMessage({message});
                }

                this.props.logout();

                resolve();
            });

            jwtService.on('onAutoLogoutFromMailLink', (message) => {
                if (message) {
                    this.props.showMessage({message});
                }

                this.props.logout(false);

                resolve();
            });

            jwtService.on('onNoAccessToken', () => {
                resolve();
            });

            jwtService.init();

            return Promise.resolve();
        })
    }

    render() {
        return this.state.waitAuthCheck ? <FuseSplashScreen/> : <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            logout: userActions.logoutUser,
            setUserData: userActions.setUserData,
            showMessage: Actions.showMessage,
            hideMessage: Actions.hideMessage,
            setObjects: CommonActions.setObjects
        },
        dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
