import {createSlice} from '@reduxjs/toolkit';
import {PARTNER_FACILITIES_TAB_TABLE, PARTNER_TAB_FACILITIES} from "../constants";
import {STATUS_INITIAL} from "../../../../../lib/constants";
import axios from "axios";
import {getEntities} from "./actions";
import {setObjects} from "../../../../store/actions";
import {RESET_STORE} from "../../../../store/actions/reduxActions";


export const initialState = {
    selectedTab: PARTNER_TAB_FACILITIES,
    selectedFacilitiesTab: PARTNER_FACILITIES_TAB_TABLE,
    createFacilityDialogOpen: false,
    disableFacilityDialog: {
        open: false,
        facilityId: null,
        facilityName: "",
        hasDevicesAssigned: null,
        hasDevicesAssignedLoading: false,
    },
    qrCodeDialog: {
        open: false,
        businessContactLoading: false,
        businessContact: {},
        businessContactExceptionsLoading: false,
        businessContactExceptions: [],
    },
    facilityNamesById: [],
    facilityNamesByIdLoading: false,
    newFacilityForm: {
        address: {},
        language: null,
        suggestedLanguagesLoading: false,
        phoneNumber: null,
        type: null,
        managers: [""],
        facilityCreationStatus: STATUS_INITIAL,
        isAddressEdited: false,
        isPhoneNumberEdited: false,
        isNameEdited: false,
        isExternalIdEdited: false,
        isLanguageEdited: false,
        isAddressIncomplete: null,
        customGooglePlace: undefined,
        facilityCreationId: null,
    },
    managersDialog: {
        open: false,
        facilityId: null,
        managersLoading: false,
        managersInvitationsLoading: false,
        managers: [],
        managersInvitations: [],
    }
};


export const fetchSuggestedLanguagesForCountry = (country, zipCode) => async (dispatch) => {
    dispatch(setInNewFacilityForm({
        key: "suggestedLanguagesLoading",
        value: true
    }))
    axios.get(`/api/suggested-language`, {
        params: {
            country: country,
            zipCode: zipCode
        }
    }).then((response) => {
        dispatch(setInNewFacilityForm({
            key: "language",
            value: response.data.mainLanguage
        }))
    }).catch((err) => {
        if (err.response.status === 400) {
            // do nothing
        }
    }).finally(() => {
        dispatch(setInNewFacilityForm({
            key: "suggestedLanguagesLoading",
            value: false
        }))
    })
};

export const fetchHasDevicesAssigned = (facilityId) => async (dispatch) => {
    dispatch(setHasFacilitiesAssignedLoading(true))
    axios.get(`/api/facilities/${facilityId}/has-devices-assigned`).then((response) => {
        dispatch(setHasFacilitiesAssigned(response.data))
    }).finally(() => {
        dispatch(setHasFacilitiesAssignedLoading(false))
    })
};

export const fetchBusinessContact = () => async (dispatch) => {
    dispatch(setBusinessContactLoading(true))
    axios.get(`/api/partners/business-contact`).then((response) => {
        dispatch(setBusinessContact(response.data))
    }).finally(() => {
        dispatch(setBusinessContactLoading(false))

    })
};

export const fetchBusinessContactExceptions = () => async (dispatch) => {
    dispatch(setBusinessContactExceptionsLoading(true))
    axios.get(`/api/partners/business-contact-exceptions`).then((response) => {
        dispatch(setBusinessContactExceptions(response.data))
    }).finally(() => {
        dispatch(setBusinessContactExceptionsLoading(false))

    })
};

export const fetchPartnerFacilityNamesById = () => async (dispatch) => {
    dispatch(setPartnerFacilityNamesByIdLoading(true))
    axios.get(`/api/partners/facility-names-by-id`).then((response) => {
        dispatch(setPartnerFacilityNamesById(response.data))
    }).finally(() => {
        dispatch(setPartnerFacilityNamesByIdLoading(false))

    })
};

export const submitBusinessContact = (businessContact) => async (dispatch) => {
    axios.post(`/api/partners/business-contact`, businessContact)
};

export const submitBusinessContactExceptions = (businessContactExceptions) => async (dispatch) => {
    axios.post(`/api/partners/business-contact-exceptions`, {
        exceptions: businessContactExceptions
    })
};

export const disableFacility = (facilityId, userId) => async (dispatch) => {
    axios.post(`/api/facilities/${facilityId}/disable`)
        .then(() => {
            dispatch(getEntities())
            dispatch(setObjects(userId))
        })
};

export const fetchFacilityManagers = (facilityId, loading = true) => async (dispatch) => {
    if (loading)
        dispatch(setManagersLoading(true))
    axios.get(`/api/facilities/${facilityId}/dashboard-users`)
        .then((response) => {
            dispatch(setManagers(response.data))
        })
        .finally(() => {
            dispatch(setManagersLoading(false))
        })
};

export const fetchFacilityManagersInvitations = (facilityId, loading = true) => async (dispatch) => {
    if (loading)
        dispatch(setManagersInvitationsLoading(true))
    axios.get(`/api/facilities/${facilityId}/dashboard-users-invitations`)
        .then((response) => {
            dispatch(setManagersInvitations(response.data))
        })
        .finally(() => {
            dispatch(setManagersInvitationsLoading(false))
        })
};


const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        selectTab: (state, action) => {
            state.selectedTab = action.payload
        },
        openManagersDialog: (state, action) => {
            state.managersDialog = {
                ...initialState.managersDialog,
                open: true,
                facilityId: action.payload
            }
        },
        closeManagersDialog: (state, action) => {
            state.managersDialog = initialState.managersDialog
        },
        openCreateFacilityDialog: (state, action) => {
            state.newFacilityForm.type = action.payload
            state.createFacilityDialogOpen = true
        },
        closeCreateFacilityDialog: (state) => {
            state.createFacilityDialogOpen = false
            state.newFacilityForm = initialState.newFacilityForm
        },
        setInNewFacilityForm: (state, action) => {
            state.newFacilityForm[action.payload.key] = action.payload.value
        },
        setManagersLoading: (state, action) => {
            state.managersDialog.managersLoading = action.payload
        },
        setManagersInvitationsLoading: (state, action) => {
            state.managersDialog.managersInvitationsLoading = action.payload
        },
        setManagers: (state, action) => {
            state.managersDialog.managers = action.payload
        },
        setManagersInvitations: (state, action) => {
            state.managersDialog.managersInvitations = action.payload
        },
        openQrCodeDialog: (state, action) => {
            state.qrCodeDialog.open = true
        },
        closeQrCodeDialog: (state, action) => {
            state.qrCodeDialog.open = false
        },
        setBusinessContact: (state, action) => {
            state.qrCodeDialog.businessContact = action.payload
        },
        setBusinessContactExceptions: (state, action) => {
            state.qrCodeDialog.businessContactExceptions = action.payload
        },
        setBusinessContactValue: (state, action) => {
            state.qrCodeDialog.businessContact[action.payload.key] = action.payload.value
        },
        setManagerInNewFacilityForm: (state, action) => {
            const {index, value} = action.payload;
            if (index >= 0 && index < state.newFacilityForm.managers.length) {
                state.newFacilityForm.managers[index] = value;
            }
        },
        resetNewFacilityForm: (state) => {
            state.newFacilityForm = {
                ...initialState.newFacilityForm,
                type: state.newFacilityForm.type
            }
        },
        selectFacilitiesTab: (state, action) => {
            state.selectedFacilitiesTab = action.payload
        },
        setBusinessContactLoading: (state, action) => {
            state.qrCodeDialog.businessContactLoading = action.payload
        },
        setBusinessContactExceptionsLoading: (state, action) => {
            state.qrCodeDialog.businessContactExceptionsLoading = action.payload
        },
        setPartnerFacilityNamesById: (state, action) => {
            state.facilityNamesById = action.payload
        },
        setPartnerFacilityNamesByIdLoading: (state, action) => {
            state.facilityNamesByIdLoading = action.payload
        },
        setFacilityCreationStatus: (state, action) => {
            state.newFacilityForm.facilityCreationStatus = action.payload
        },
        setDisableFacilityDialog: (state, action) => {
            state.disableFacilityDialog = action.payload
        },
        setHasFacilitiesAssigned: (state, action) => {
            state.disableFacilityDialog.hasDevicesAssigned = action.payload
        },
        setHasFacilitiesAssignedLoading: (state, action) => {
            state.disableFacilityDialog.hasDevicesAssignedLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, (state) => {
            return {...initialState};
        });
    }
});
export const {
    selectTab,
    selectFacilitiesTab,
    setInNewFacilityForm,
    setFacilityCreationStatus,
    openCreateFacilityDialog,
    closeCreateFacilityDialog,
    resetNewFacilityForm,
    setDisableFacilityDialog,
    setHasFacilitiesAssigned,
    setHasFacilitiesAssignedLoading,
    setBusinessContactLoading,
    setBusinessContactExceptionsLoading,
    setManagerInNewFacilityForm,
    openManagersDialog,
    closeManagersDialog,
    setManagersLoading,
    setManagersInvitationsLoading,
    setManagers,
    setManagersInvitations,
    setBusinessContactExceptions,
    setPartnerFacilityNamesById,
    setPartnerFacilityNamesByIdLoading,
    setBusinessContactValue,
    setBusinessContact,
    openQrCodeDialog,
    closeQrCodeDialog,
} = partnerSlice.actions;
export default partnerSlice.reducer;