import * as Actions from '../actions';
import _ from '@lodash';
import {RESET_STORE} from "../../../../../store/actions/reduxActions";

const initialState = {
    entities: null,
    searchText: "",
    addNewObjectLoading: false,
    addManagerDialogOpen: false,
    mobileNetworkOperatorsDialogOpen: false,
    mobileNetworkOperatorsDialogSelectedObid: null,
    mobileNetworkOperatorsDialogSelectedObjectCountry: null,
    mobileNetworkOperatorsDialogSelectedObjectSimCards: [],
    mobileNetworkOperatorsDialogSelectedObjectPlmn: "",
    managersSelectedObid: null,
    pairDevicesOpenDialog: false,
    pairDevicesSelectedObid: null,
    currentlyUsedOperators: null,
    pairDevicesAddedSerials: [],
    pairDevicesFailedSerials: [],
    googleApiLoading: true,
    objectDetailsDrawerObid: null,
    selectedPartnerId: null,
    editFacilityDialog: {
        props: {
            open: false
        },
        facilityType: null,
        data: null
    },
};

const initialObjectState = {
    additionalData: {
        msgsToday: 0,
        msgsAverage: 0,
        msgsTotal: 0,
        profiles: 0,
        profilesLogged: 0,
        deviceTypesOnline: [0, 0, 0, 0, 0],
        deviceAssigned: 0,
        wifiCellularWatchesCount: [0, 0],
        pctOfGoodDbmOnCellFromLast3Days: 0,
        managers: [],
        underrun12h: 0,
        underrun14d: 0,
        streaming: "0",
    }, managers: [],
}

// eslint-disable-next-line complexity
const partnerReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ENTITIES: {
            let entities = [...action.payload];
            for (let entity in entities) {
                if (entities[entity].status !== 'createdAndCached') {
                    entities[entity] = {
                        ...entities[entity],
                        ...initialObjectState,
                        status: "creating",
                    }
                }
            }
            return {
                ...state,
                entities: _.keyBy(entities, 'id'),
            };
        }
        case Actions.ADD_ENTITY: {
            let newState = {...state.entities};
            let newObject = {...initialObjectState};

            newObject['id'] = action.id;
            newObject['intervalId'] = action.intervalId;
            newObject['status'] = 'creating';
            newObject['additionalData'] = {
                ...initialObjectState["additionalData"],
            };

            newState[action.id] = {
                ...newObject,
                ...action.newEntity
            };

            return {
                ...state,
                addNewObjectLoading: true,
                entities: newState,
            };
        }
        case Actions.SET_SELECTED_PARTNER_ID: {
            return {
                ...state,
                selectedPartnerId: action.payload
            };
        }
        case Actions.OPEN_OBJECT_DETAILS_DRAWER: {
            return {
                ...state,
                objectDetailsDrawerOpen: true,
                objectDetailsDrawerObid: action.obid,
            };
        }
        case Actions.CLOSE_OBJECT_DETAILS_DRAWER: {
            return {
                ...state,
                objectDetailsDrawerOpen: false,
                objectDetailsDrawerObid: null,
            };
        }
        case Actions.SET_GOOGLE_API_LOADING: {
            return {
                ...state,
                googleApiLoading: action.payload,
            }
        }
        case Actions.PAIR_DEVICES_OPEN_DIALOG: {
            return {
                ...state,
                pairDevicesOpenDialog: true,
                pairDevicesSelectedObid: action.obid
            };
        }
        case Actions.PAIR_DEVICES_CLOSE_DIALOG: {
            return {
                ...state,
                pairDevicesOpenDialog: false,
                pairDevicesSelectedObid: null
            };
        }
        case Actions.EDIT_PLMN_OF_OBJECT: {
            let newState = {...state.entities};

            newState[action.obid] = {
                ...newState[action.obid],
                additionalData: {
                    ...newState[action.obid].additionalData,
                    plmn: action.plmn
                }
            };

            return {
                ...state,
                entities: newState
            };
        }
        case Actions.OPEN_MOBILE_NETWORK_OPERATORS_DIALOG: {
            return {
                ...state,
                mobileNetworkOperatorsDialogOpen: true,
                mobileNetworkOperatorsDialogSelectedObid: action.obid,
                mobileNetworkOperatorsDialogSelectedObjectCountry: action.country,
                mobileNetworkOperatorsDialogSelectedObjectSimCards: action.simCards,
                mobileNetworkOperatorsDialogSelectedObjectPlmn: action.plmn,
            };
        }
        case Actions.CLOSE_MOBILE_NETWORK_OPERATORS_DIALOG: {
            return {
                ...state,
                mobileNetworkOperatorsDialogOpen: false,
            };
        }
        case Actions.PAIR_DEVICES_BY_SERIALS_REPORT: {
            return {
                ...state,
                pairDevicesOpenDialog: false,
                pairDevicesAddedSerials: action.payload?.addedSerials,
                pairDevicesFailedSerials: action.payload?.failedSerials
            }
        }
        case Actions.CLOSE_PAIR_DEVICE_BY_SERIAL_REPORT_DIALOG: {
            return {
                ...state,
                pairDevicesAddedSerials: [],
                pairDevicesFailedSerials: []
            };
        }
        case Actions.UPDATE_ENTITY: {
            let newState = {...state.entities};

            newState[action.entity.id] = {
                ...action.entity,
                additionalData: {
                    ...newState[action.entity.id].additionalData,
                    plmn: action.entity.plmn
                }
            };

            return {
                ...state,
                entities: newState
            };
        }
        case Actions.IS_CREATED: {
            let newState = {...state.entities};

            clearInterval(state.entities[action.payload].intervalId);

            newState[action.payload] = {
                ...state.entities[action.payload],
                status: 'createdAndCached'
            };

            return {
                ...state,
                addNewObjectLoading: false,
                entities: newState
            };
        }
        case Actions.GET_CURRENTLY_USED_OPERATORS: {
            return {
                ...state,
                currentlyUsedOperators: action.payload,
            }
        }
        case Actions.OPEN_ADD_MANAGERS_DIALOG: {
            return {
                ...state,
                addManagerDialogOpen: true,
            }
        }
        case Actions.CLOSE_ADD_MANAGERS_DIALOG: {
            return {
                ...state,
                addManagerDialogOpen: false,
            }
        }
        case Actions.SET_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.OPEN_EDIT_FACILITY_DIALOG: {
            return {
                ...state,
                editFacilityDialog: {
                    type: 'edit',
                    props: {
                        open: true
                    },
                    data: action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_FACILITY_DIALOG: {
            return {
                ...state,
                editFacilityDialog: {
                    type: 'edit',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case RESET_STORE:
            return initialState
        default:
            return state;
    }
};

export default partnerReducer;
