import React from 'react';

export const PartnerAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/partner',
            component: React.lazy(() => import('app/main/apps/partner/PartnerApp'))
        }
    ]
};
