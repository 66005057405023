import axios from 'axios';
import {getCurrentSelectedObid} from "../../../../../../../lib/getObid";

export const GET_CONTACTS = '[PREDEFINED MESSAGES] GET CONTACTS';
export const SET_SEARCH_TEXT = '[PREDEFINED MESSAGES] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_CONTACTS = '[PREDEFINED MESSAGES] TOGGLE IN SELECTED CONTACTS';
export const SELECT_ALL_CONTACTS = '[PREDEFINED MESSAGES] SELECT ALL CONTACTS';
export const DESELECT_ALL_CONTACTS = '[PREDEFINED MESSAGES] DESELECT ALL CONTACTS';
export const OPEN_NEW_CONTACT_DIALOG = '[PREDEFINED MESSAGES] OPEN NEW CONTACT DIALOG';
export const CLOSE_NEW_CONTACT_DIALOG = '[PREDEFINED MESSAGES] CLOSE NEW CONTACT DIALOG';
export const OPEN_EDIT_CONTACT_DIALOG = '[PREDEFINED MESSAGES] OPEN EDIT CONTACT DIALOG';
export const CLOSE_EDIT_CONTACT_DIALOG = '[PREDEFINED MESSAGES] CLOSE EDIT CONTACT DIALOG';
export const ADD_CONTACT = '[PREDEFINED MESSAGES] ADD CONTACT';
export const UPDATE_CONTACT = '[PREDEFINED MESSAGES] UPDATE CONTACT';
export const REMOVE_CONTACT = '[PREDEFINED MESSAGES] REMOVE CONTACT';
export const TOGGLE_DISABLED = '[PREDEFINED MESSAGES] TOGGLE DISABLED';
export const REMOVE_MESSAGES = '[PREDEFINED MESSAGES] REMOVE MESSAGES';
export const SET_LOADING_TRUE = '[PREDEFINED MESSAGES] SET LOADING TRUE';
export const GET_TRANSLATIONS_FOR_PREDEFINED_MESSAGE = '[PREDEFINED MESSAGES] GET TRANSLATIONS FOR PREDEFINED MESSAGE';
export const UPDATE_TRANSLATION = '[PREDEFINED MESSAGES] UPDATE TRANSLATION';
export const GET_JOB_TITLES_FOR_PREDEFINED_MESSAGE = '[PREDEFINED MESSAGES] GET JOB TITLES FOR PREDEFINED MESSAGE';
export const REMOVE_JOB_TITLE = '[PREDEFINED MESSAGES] REMOVE JOB TITLE';
export const ADD_JOB_TITLE = '[PREDEFINED MESSAGES] ADD JOB TITLE';
export const CHANGE_ORDER = '[PREDEFINED MESSAGES] CHANGE ORDER';
export const DISABLE_MULTIPLE = '[PREDEFINED MESSAGES] DISABLE MULTIPLE';
export const ENABLE_MULTIPLE = '[PREDEFINED MESSAGES] ENABLE MULTIPLE';

export function getContacts(loading = true) {
    const request = axios.get('/api/predefinedMsgs/list', {
        params: {
            selectedObid: getCurrentSelectedObid()
        }
    });

    return (dispatch) => {
        if (loading)
            dispatch(setLoadingTrue());
        request.then((response) => {
                return dispatch({
                    type: GET_CONTACTS,
                    payload: response.data
                })
            }
        );
    }
}

export function removeJobTitle(jobTitleId, predefinedMessageId) {
    return (dispatch) => {
        const request = axios.post('/api/predefinedMsgs/removeJobTitle', {
            selectedObid: getCurrentSelectedObid(),
            jobTitleId: [jobTitleId],
            predefinedMessageId: parseInt(predefinedMessageId)
        });

        return request.then(() =>
            Promise.all([
                dispatch({
                    type: REMOVE_JOB_TITLE,
                    jobTitleId,
                    predefinedMessageId
                })
            ])
        )
    };
}

export function addJobTitle(jobTitleId, predefinedMessageId) {
    return (dispatch) => {
        const request = axios.post('/api/predefinedMsgs/addJobTitle', {
            selectedObid: getCurrentSelectedObid(),
            jobTitleId: jobTitleId,
            predefinedMessageId: parseInt(predefinedMessageId)
        });

        return request.then(() =>
            Promise.all([
                dispatch({
                    type: ADD_JOB_TITLE,
                    jobTitleId,
                    predefinedMessageId
                })
            ])
        );
    };
}

export function setLoadingTrue() {
    return {
        type: SET_LOADING_TRUE,
    }
}

export function changeOrder(sms1Id, order1, sms2Id, order2) {
    axios.post('/api/predefinedMsgs/editOrder', {
        [sms1Id]: order2,
        selectedObid: getCurrentSelectedObid()
    });
    return {
        type: CHANGE_ORDER,
        order1,
        order2
    }
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedContacts(contactId) {
    return {
        type: TOGGLE_IN_SELECTED_CONTACTS,
        contactId
    }
}

export function selectAllContacts() {
    return {
        type: SELECT_ALL_CONTACTS
    }
}

export function deSelectAllContacts() {
    return {
        type: DESELECT_ALL_CONTACTS
    }
}

export function openNewContactDialog() {
    return {
        type: OPEN_NEW_CONTACT_DIALOG
    }
}

export function closeNewContactDialog() {
    return {
        type: CLOSE_NEW_CONTACT_DIALOG
    }
}

export function openEditContactDialog(data) {
    return {
        type: OPEN_EDIT_CONTACT_DIALOG,
        data
    }
}

export function closeEditContactDialog() {
    return {
        type: CLOSE_EDIT_CONTACT_DIALOG
    }
}

export function addContact(newSms) {
    return (dispatch,) => {

        const request = axios.post('/api/predefinedMsgs/add', {
            selectedObid: getCurrentSelectedObid(),
            ...newSms
        });

        return request.then(() =>
            Promise.all([
                dispatch({
                    type: ADD_CONTACT
                })
            ]).then(() => dispatch(getContacts(false)))
        );
    };
}

export function updateContact(sms, active) {
    return (dispatch) => {
        sms['id'] = parseInt(sms['id']);
        delete sms['jobTitles'];
        const request = axios.post('/api/predefinedMsgs/edit', {
            selectedObid: getCurrentSelectedObid(),
            ...sms, active
        });

        return request.then(() =>
            Promise.all([
                dispatch({
                    type: UPDATE_CONTACT,
                    sms
                })
            ])
        );
    };
}

export function removeContact(smsId) {
    return (dispatch) => {
        axios.post('/api/predefinedMsgs/delete', {
            selectedObid: getCurrentSelectedObid(),
            messagesToDelete: [smsId]
        });

        dispatch({
            type: REMOVE_CONTACT,
            smsId: smsId
        })
    };
}

export function removeMessages(messagesids) {
    return (dispatch) => {
        axios.post('/api/predefinedMsgs/delete', {
            selectedObid: getCurrentSelectedObid(),
            messagesToDelete: messagesids
        });

        dispatch({
            type: REMOVE_MESSAGES,
            messagesids
        });
    };
}

export function enableMultiple(smsIds) {
    return (dispatch) => {

        let arr = smsIds.map(x => {
            return {id: x, state: 1}
        });
        axios.post('/api/predefinedMsgs/toggleDisable', {
            obid: getCurrentSelectedObid(),
            table: [...arr]
        });

        dispatch({
            type: ENABLE_MULTIPLE,
            smsIds
        });
        dispatch({
            type: DESELECT_ALL_CONTACTS
        });
    };
}

export function disableMultiple(smsIds) {
    return (dispatch) => {
        let arr = smsIds.map(x => {
            return {id: x, state: 0}
        });
        axios.post('/api/predefinedMsgs/toggleDisable', {
            obid: getCurrentSelectedObid(),
            table: [...arr]
        });

        dispatch({
            type: DISABLE_MULTIPLE,
            smsIds
        });
        dispatch({
            type: DESELECT_ALL_CONTACTS
        });
    };
}

export function toggleDisabled(smsId, value) {
    return (dispatch) => {

        axios.post('/api/predefinedMsgs/toggleDisable', {
            obid: getCurrentSelectedObid(),
            table: [{id: smsId, state: value ? 1 : 0}]
        });

        dispatch({
            type: TOGGLE_DISABLED,
            smsId,
            state: value ? 1 : 0
        });
    };
}
