import React from 'react';

export const PersonnelOnlineAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/usersOnline',
            component: React.lazy(() => import('app/main/apps/personnelOnline/PersonnelOnlineApp'))
        }
    ]
};