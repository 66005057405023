export const RMA_STATUS_CREATED = 'created'
export const RMA_STATUS_FOR_AUTHORIZATION = 'forAuthorization'
export const RMA_STATUS_AUTHORIZED = 'authorized'
export const RMA_STATUS_CANCELED = 'canceled'
export const RMA_STATUS_INTEGRATION_PROBLEM = 'integrationProblem'
export const RMA_STATUS_WAIT_FOR_PACKAGING = 'waitForPackaging'
export const RMA_STATUS_PRODUCT_NOT_AVAILABLE = 'productNotAvailable'
export const RMA_STATUS_PACKAGING = 'packaging'
export const RMA_STATUS_WAIT_FOR_STORE = 'waitingForStore'
export const RMA_STATUS_WAITING_FOR_COURIER = 'waitingForCourier'
export const RMA_STATUS_PICKED_BY_COURIER = 'pickedByCourier'
export const RMA_STATUS_IN_TRANSIT = 'inTransit'
export const RMA_STATUS_OUT_FOR_DELIVERY = 'outForDelivery'
export const RMA_STATUS_COMPLETED = 'completed'
export const RMA_STATUS_CANCELED_OR_REJECTED_BY_FACILITY = 'canceledOrRejectedByFacility'
export const RMA_STATUS_UPS_DELIVERY_PROBLEM = 'UPSDeliveryProblem'
export const RMA_STATUS_EXCEPTION = 'EXCEPTION'
export const RMA_STATUS_PACKAGE_QUANTITY_MISMATCHED = 'PACKAGE_QUANTITY_MISMATCHED'
export const RMA_STATUS_PACKAGE_RETURNED_GRACEFULLY = 'PACKAGE_RETURNED_GRACEFULLY'

export const ERROR_GOOGLE_PLACE_ID_INVALID = "ERROR_GOOGLE_PLACE_ID_INVALID"
export const ERROR_GOOGLE_PLACE_ID_LACKING = "ERROR_GOOGLE_PLACE_ID_LACKING"
export const ERROR_GOOGLE_PLACE_ID_REQUIRED = "ERROR_GOOGLE_PLACE_ID_REQUIRED"
export const ERROR_COUNTRY_NOT_SUPPORTED = "ERROR_COUNTRY_NOT_SUPPORTED"
export const ERROR_ZIP_CODE_NOT_SUPPORTED = "ERROR_ZIP_CODE_NOT_SUPPORTED"
export const ERROR_STORE_LACKING_PACKAGES = "ERROR_STORE_LACKING_PACKAGES"

export const MAXIMUM_LENGTH_ADDITIONAL_INFO_AND_ADDRESS_NAME = 35

// Material UI dialogs have by default zIndex = 1300
// We would like to display some dialogs on top of another ones,
// so we change their zIndex to 1301
export const ELEVATED_DIALOG_Z_INDEX = 1301
export const MAXIMUM_EMAIL_LENGTH = 50

export const RMA_VIEW_PARTNER_MODE = 'partner'
export const RMA_VIEW_MANAGER_MODE = 'manager'

export const CANCELABLE_STATUSES = [
    RMA_STATUS_FOR_AUTHORIZATION,
    RMA_STATUS_AUTHORIZED,
    RMA_STATUS_WAIT_FOR_PACKAGING,
    RMA_STATUS_PRODUCT_NOT_AVAILABLE,
    RMA_STATUS_PACKAGING,
    RMA_STATUS_WAIT_FOR_STORE,
    RMA_STATUS_WAITING_FOR_COURIER,
]

export const AUTHORIZABLE_STATUSES = [
    RMA_STATUS_FOR_AUTHORIZATION,
]

// These statutes are "before" picking up by courier. We would like to hide ETA before that situation if exceeded.
// After picking up we display it always.
export const hideExceededEtaStatuses = [
    RMA_STATUS_CREATED,
    RMA_STATUS_FOR_AUTHORIZATION,
    RMA_STATUS_AUTHORIZED,
    RMA_STATUS_INTEGRATION_PROBLEM,
    RMA_STATUS_CANCELED,
    RMA_STATUS_WAIT_FOR_PACKAGING,
    RMA_STATUS_PRODUCT_NOT_AVAILABLE,
    RMA_STATUS_PACKAGING,
    RMA_STATUS_WAIT_FOR_STORE
]