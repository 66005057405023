import React, {cloneElement} from 'react';
import {ListItem, ListItemText} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import * as Actions from 'app/store/actions';
import FuseNavBadge from './../FuseNavBadge';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 48,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-text-primary': {
                color: 'inherit'
            },
            '& .list-item-icon': {
                color: 'inherit'
            }
        },
        '& .list-item-icon': {},
        '& .list-item-text': {
            padding: '0 0 0 16px'
        },
        color: theme.palette.text.primary,
        textDecoration: 'none!important',
        '&.dense': {
            padding: '8px 12px 8px 12px',
            minHeight: 40,
            '& .list-item-text': {
                padding: '0 0 0 8px'
            }
        }
    }
}));

function FuseNavHorizontalLink(props) {
    const dispatch = useDispatch();

    const classes = useStyles(props);
    const {item, dense} = props;

    return (
        <ListItem
            button
            component="a"
            href={item.url}
            target={item.target ? item.target : "_blank"}
            className={clsx("list-item", classes.root, dense && "dense")}
            onClick={() => dispatch(Actions.navbarCloseMobile())}
        >
            {item.icon && (
                <>
                    {cloneElement(item.icon, {className: 'list-item-icon text-16 flex-shrink-0', color: 'action'})}
                </>
            )}
            <ListItemText className="list-item-text" primary={item.title}
                          classes={{primary: 'text-14 list-item-text-primary'}}/>
            {item.badge && (
                <FuseNavBadge className="ml-8" badge={item.badge}/>
            )}
        </ListItem>
    );
}

FuseNavHorizontalLink.propTypes = {
    item: PropTypes.shape(
        {
            id: PropTypes.string.isRequired,
            title: PropTypes.string,
            icon: PropTypes.string,
            url: PropTypes.string,
            target: PropTypes.string
        })
};

FuseNavHorizontalLink.defaultProps = {};

const NavHorizontalLink = withRouter(React.memo(FuseNavHorizontalLink));

export default NavHorizontalLink;
