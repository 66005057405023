import React from 'react';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import * as Actions from "app/main/apps/devices/store/actions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import EnhancedTranslate from "../../../../../common-components/EnhancedTranslate";
import {TransitionComponent} from "../../../../../../lib/commonFunctions";

export default function RestartDeviceDialog() {
    const dispatch = useDispatch();
    const open = useSelector(({devices}) => devices.devices.restartDeviceDialogOpen);
    const selectedDeviceSerial = useSelector(({devices}) => devices.devices.selectedDeviceSerial);

    function closeComposeDialog() {
        dispatch(Actions.closeRestartDeviceDialog());
    }

    function restart() {
        dispatch(Actions.restartDevice(selectedDeviceSerial));
        closeComposeDialog()
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={TransitionComponent}
            keepMounted
            onClose={closeComposeDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                <EnhancedTranslate value={'Restart device dialog title'}/>{selectedDeviceSerial}?</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeComposeDialog} color="primary">
                    <EnhancedTranslate value={'No'}/>
                </Button>
                <Button onClick={restart} color="primary">
                    <EnhancedTranslate value={'Yes'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}