import React from 'react';

export const DevicesAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: '/devices/history/:serial/:deviceType',
            component: React.lazy(() => import('./archivedPings/Product'))
        },
        {
            path: '/devices/shadow/:serial',
            component: React.lazy(() => import('./devices/Shadow'))
        },
        {
            path: '/devices',
            component: React.lazy(() => import('app/main/apps/devices/devices/Devices'))
        }
    ]
};
