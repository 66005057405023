import {useEffect} from "react";
import keycode from "keycode";

export function useReactTableKeyboardKeys() {
    useEffect(() => {
        function handleDocumentKeyDown(event) {
            if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable)
                return

            if (keycode(event) === 'page up') {
                let scroll = document.getElementsByClassName("rt-table")[0];
                if (scroll)
                    scroll.scrollTop -= 600
            } else if (keycode(event) === 'page down') {
                let scroll = document.getElementsByClassName("rt-table")[0];
                if (scroll)
                    scroll.scrollTop += 600
            } else if (keycode(event) === "right") {
                let nextPageContainer = document.getElementsByClassName("-next")[0];
                if (nextPageContainer)
                    nextPageContainer.firstChild.click()
            } else if (keycode(event) === "left") {
                let previousPageContainer = document.getElementsByClassName("-previous")[0];
                if (previousPageContainer)
                    previousPageContainer.firstChild.click()
            }
        }

        document.addEventListener("keydown", handleDocumentKeyDown);
        return (() => {
            document.removeEventListener('keydown', handleDocumentKeyDown);
        });
    }, []);

    return null;
}